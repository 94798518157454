import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { ProductPriceServiceService } from 'src/app/services/product-price-service.service';

@Component({
  selector: 'app-lss-legacy-system-service',
  templateUrl: './lss-legacy-system-service.component.html',
  styleUrls: ['./lss-legacy-system-service.component.scss']
})
export class LssLegacySystemServiceComponent implements OnInit {
  @Input()
  public parentForm!: FormGroup;

  
  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  selectedLssPackage!: string;

  constructor(private loadingSpinner: SiNewtonLoadingService, private productPriceService: ProductPriceServiceService) { }

  ngOnInit(): void {
  }

  async updateListPricesOfLssPackages(){

    this.loadingSpinner.startLoading();

    let componentsCommaSeperated = '';

    for (let i = 7; i <= 9; i++){
        componentsCommaSeperated += this.parentForm.get('legacySystemServiceForm')?.get('lssPackageV' + i + 'Mlfb')?.value + ',';
        componentsCommaSeperated += this.parentForm.get('legacySystemServiceForm')?.get('lssPackageV' + i + 'ExtensionMlfb')?.value + ',';
    }

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(componentsCommaSeperated.replace(/.$/, '')) as any;

    for (let i = 7; i <= 9; i++){

      const mlfb = this.parentForm.get('legacySystemServiceForm')?.get('lssPackageV' + i + 'Mlfb')?.value;
      const curListPriceForMlfb = this.parentForm.get('legacySystemServiceForm')?.get('lssPackageV' + i + 'LPrice')?.value;

      
      const extensionMlfb = this.parentForm.get('legacySystemServiceForm')?.get('lssPackageV' + i + 'ExtensionMlfb')?.value;
      const curListPriceForExtensionMlfb = this.parentForm.get('legacySystemServiceForm')?.get('lssPackageV' + i + 'ExtensionLPrice')?.value;

      if (priceMflbList[mlfb] !== undefined && priceMflbList[mlfb] !== curListPriceForMlfb){
        const curFormControl = this.parentForm.get('legacySystemServiceForm')!.get('lssPackageV' + i + 'LPrice')!;

        if(priceMflbList[mlfb] != 0){

          curFormControl.setValue(priceMflbList[mlfb]);
          curFormControl.markAsDirty();
          curFormControl.markAsUntouched();
        } else {
          //curFormControl.setErrors({ priceNotFound: true });
        }
      }

      if (priceMflbList[extensionMlfb] !== undefined && priceMflbList[extensionMlfb] !== curListPriceForExtensionMlfb){
        const curFormControl = this.parentForm.get('legacySystemServiceForm')!.get('lssPackageV' + i + 'ExtensionLPrice')!;
        if(priceMflbList[extensionMlfb] != 0){
          
          curFormControl.setValue(priceMflbList[extensionMlfb]);
          curFormControl.markAsDirty();
          curFormControl.markAsUntouched();
        } else {
          //curFormControl.setErrors({ priceNotFound: true });
        }
      }

    }

    this.loadingSpinner.stopLoading();
  }

}
