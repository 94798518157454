<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="generalConfiguration">
    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">General</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('general-configuration')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    
      
    <div *ngIf="isThisSectionVisible">
      <div class="row">
        <div class="col-lg-2, col-md-3, col-sm-4">
          <si-newton-form-group label="No. of plant sections" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="numOfPlantSections" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>

