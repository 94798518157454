<si-newton-section>

  <app-navigation [currentSectionForm]=defineServicesForm></app-navigation>

  <form [formGroup]="defineServicesForm" class="col-md-12" style="align-items: center;">
    <app-one-time-services id="first-element" [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-one-time-services>
    <app-information-service [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-information-service>
    <app-contract-management [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-contract-management>
    <app-product-service-and-support [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-product-service-and-support>
    <app-on-call-service-control-center [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-on-call-service-control-center>
    <app-remote-service-modules [parentForm]="defineServicesForm" (readData)="readData()" (submitValues)="submit()" (openHelpModal)="openHelpModal($event)"></app-remote-service-modules>
    <app-preventive-maintenance [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-preventive-maintenance>
    <app-corrective-maintenance [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-corrective-maintenance>
    <app-system-upgrades [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-system-upgrades>
    <app-sivaas-system-services [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-sivaas-system-services>
    <app-sw-update [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-sw-update>
    <app-ivm [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-ivm>
    <app-mvdi [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-mvdi>
    <app-managed-system-services [parentForm]="defineServicesForm" (readData)="readData()" (submitValues)="submit()" (openHelpModal)="openHelpModal($event)"></app-managed-system-services>
    <app-define-individual-services [parentForm]="defineServicesForm" (openHelpModal)="openHelpModal($event)"></app-define-individual-services>
    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
    [disabled]="!(defineServicesForm.valid)">Submit</button>
    <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
    (click)="scrollToTop()">Go to top</button>
    <button *ngIf="!(defineServicesForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
    (click)="logInvalidControls(defineServicesForm)">
      Log Invalid Values
    </button>
  </form>
</si-newton-section>
