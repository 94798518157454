import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConstRestData } from 'src/app/const-data/rest';
import { IndividualService } from 'src/app/data-models/IndividualService';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { IndivdualServiceComponent } from '../../modals/indivdual-service/indivdual-service.component';

@Component({
  selector: 'app-define-individual-services',
  templateUrl: './define-individual-services.component.html',
  styleUrls: ['./define-individual-services.component.scss']
})
export class DefineIndividualServicesComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();



  REST_URLS = ConstRestData.restUrls;

  modalRef!: BsModalRef;

  constructor(private modalService: BsModalService, private dataTransferService: DataTransferService,
    private formDataService: FormDataService) { }

  async writeDataOfParentFormWithoutRefreshingData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.parentForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));
  }

  public async editSelectedIndividualService(serviceNumber: any){

    await this.writeDataOfParentFormWithoutRefreshingData();

    const serviceNumberAsString = 'Service' + serviceNumber;

    const dataForInitialState: IndividualService = {};


    dataForInitialState.disService = this.parentForm.get('individualServices')?.get('disServiceService' + serviceNumber)?.value;
    dataForInitialState.yearOfStartFix = this.parentForm.get('individualServices')?.get('yearOfStartFix')?.value;

    const subForm = this.parentForm.get('individualServices') as FormGroup;

    // eslint-disable-next-line guard-for-in
    for (const field in subForm.controls){

      if (field.includes(serviceNumberAsString)){

        const varName = field.toString().replace(serviceNumberAsString, '');

        (dataForInitialState as any)[varName] = subForm.get(field)?.value;
      }

    }

    const initialState = {
      serviceNumberAsString,
      currentIndividualService: dataForInitialState
    };

    this.modalRef = this.modalService.show(IndivdualServiceComponent, { initialState, backdrop: 'static' });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(async (res: any) => {
      const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.parentForm);

      const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;
      this.formDataService.fillDataIntoDialog(this.parentForm, formData);

    });
  }
}
