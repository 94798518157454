<div class="row" style="margin-bottom: 10px; margin-top: -40px;">
  <div class="col-md-12 d-flex justify-content-end">
    <button 
      type="button" 
      class="btn btn-link p-0 text-decoration-none question-mark-btn" 
      data-bs-toggle="modal" 
      data-bs-target="#helpModal" 
      aria-label="Help"
      (click)="openHelpModal('pcs-7-sus')" 
    >
      <i class="bi bi-question-circle help-text-button"></i>
    </button>
  </div>
</div>

  <form [formGroup]="softwareUpdateServiceForm" class="col-md-12" style="align-items: center;">
    <app-sus-type id="first-element" [parentForm]="softwareUpdateServiceForm" (openHelpModal)="openHelpModal($event)"></app-sus-type>
    <app-pcs-software-update-service [parentForm]="softwareUpdateServiceForm" (openHelpModal)="openHelpModal($event)" [selectedSusType]="softwareUpdateServiceForm.get('susType')?.get('susTypeRb')?.value"></app-pcs-software-update-service>
    <app-upgrade-packages [parentForm]="softwareUpdateServiceForm" (openHelpModal)="openHelpModal($event)"></app-upgrade-packages>
    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
    [disabled]="!(softwareUpdateServiceForm.valid)">Submit</button>
    <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
    (click)="scrollToTop()">Go to top</button>
  </form>
