import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConstRestData } from 'src/app/const-data/rest';
import { Licences } from 'src/app/data-models/Licences';
import { OpenHelpDialogModalComponent } from 'src/app/modals/open-help-dialog-modal/open-help-dialog-modal.component';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ProductPriceServiceService } from 'src/app/services/product-price-service.service';

@Component({
  selector: 'app-software-update-service',
  templateUrl: './software-update-service.component.html',
  styleUrls: ['./software-update-service.component.scss']
})
export class SoftwareUpdateServiceComponent implements OnInit {

 
  REST_URLS = ConstRestData.restUrls;
  softwareUpdateServiceForm: FormGroup;
  allSusPackages: Licences[] = [];
  numberOfSusPackages = 0;


  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService, private toastService: CustomToastService, private modalService: BsModalService,
    private productPriceService: ProductPriceServiceService, private loadingSpinner: SiNewtonLoadingService) {

    this.softwareUpdateServiceForm = this.formBuilder.group({
      susType: this.formBuilder.group({
        susTypeRb: [null, Validators.required]
      }),
      pcsSoftwareUpdateServices: this.formBuilder.group({
        currentYear: [null],
        currentYearLPrice: [null],
        currentYeargGaPrice: [null],
        yearOfLcsStart: [null],
        yearOfLcsStartLPrice: [null],
        yearOfLcsStartGaPrice: [null]
      }),
      selectedLicenses: this.formBuilder.group({}),
      upgradePackages: this.formBuilder.group({
        upgradeLpriceTotalcurrentYear: [null, Validators.required],
        gGaPriceTotalCurrentYear: [null],
        lPriceTotalNextYear: [null],
        gGaPriceTotalNextYear: [null]
      })
    });

    if (parseInt(sessionStorage.getItem('calculationVersion')!.toString(), 10) >= 2){
      this.numberOfSusPackages = 17;
    } else {
      this.numberOfSusPackages = 14;
    }

    for (let i = 1; i <= this.numberOfSusPackages; i++){

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('susName' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('mlfbStandard' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('mlfbLCS' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('StandardPrice' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('LCSPrice' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('numberOfPackages' + i,
          new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('lPriceTotal' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices') as FormGroup)!.
        addControl('gGaPriceTotal' + i, new FormControl(null));
    }

    for (let i = 1; i <= 57; i++){

      (this.softwareUpdateServiceForm.get('selectedLicenses') as FormGroup)!.
        addControl('susPackageMLFB' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('selectedLicenses') as FormGroup)!.
        addControl('susPackageMLFB_OSD' + i, new FormControl(null));

      (this.softwareUpdateServiceForm.get('selectedLicenses') as FormGroup)!.
        addControl('susPackageDescription' + i, new FormControl(null));
      (this.softwareUpdateServiceForm.get('selectedLicenses') as FormGroup)!.
        addControl('susPackageQuantity' + i,
          new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));
    }
  }

  ngOnInit() {
    this.readData();
  }

  getAllRelevantMlfb(): string{
    let listOfMlfbsCommaSeparated = '';

    if (this.softwareUpdateServiceForm.get('susType')?.get('susTypeRb')!.value === 'Standard SUS'){
      console.log('Standard SUS');
      for (let i = 1; i <= this.numberOfSusPackages; i++){
        listOfMlfbsCommaSeparated +=
          this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')?.get('mlfbStandard' + i)!.value + ',';
      }
    } else {
      for (let i = 1; i <= this.numberOfSusPackages; i++){
        listOfMlfbsCommaSeparated +=
          this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')?.get('mlfbLCS' + i)!.value.replace(' / No LCS SUS', '') + ',';
      }
    }

    return listOfMlfbsCommaSeparated;
  }

  async updatePricesInForm(currentPrices: any){
    if (this.softwareUpdateServiceForm.get('susType')?.get('susTypeRb')!.value === 'Standard SUS'){
      for (let i = 1; i <= this.numberOfSusPackages; i++){

        const mlfb = this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')?.get('mlfbStandard' + i)!.value;
        this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')!.get('StandardPrice' + i)!.setValue(currentPrices[mlfb]);
        this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')!.get('StandardPrice' + i)!.markAsDirty();
      }
    } else {
      for (let i = 1; i <= this.numberOfSusPackages; i++){

        const mlfb =
          this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')?.get('mlfbLCS' + i)!.value.replace(' / No LCS SUS', '');
        this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')!.get('LCSPrice' + i)!.setValue(currentPrices[mlfb]);
        this.softwareUpdateServiceForm.get('pcsSoftwareUpdateServices')!.get('LCSPrice' + i)!.markAsDirty();
      }
    }
  }

  async updatePricesOfPackages(){
    const listOfMlfbsCommaSeparated = this.getAllRelevantMlfb();

    const currentPrices = await this.productPriceService.getCurrentPricesByMlfbList(listOfMlfbsCommaSeparated.replace(/.$/, '')) as any;

    await this.updatePricesInForm(currentPrices);
  }

  async submit(){

    this.loadingSpinner.startLoading();
    try {

      await this.updatePricesOfPackages();
      await this.writeData();
    } catch (err: any){
      this.toastService.showErrorToast(err.message || 'Unknown Error occured');
    } finally {
      this.loadingSpinner.stopLoading();
    }
  }

  async readData() {
    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.softwareUpdateServiceForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.softwareUpdateServiceForm, formData);

    for (let i = 1; i <= 57; i++){

      this.allSusPackages.push({
        'mlfb': this.softwareUpdateServiceForm.get('selectedLicenses')?.get('susPackageMLFB' + i)?.value,
        'mlfbOsd': this.softwareUpdateServiceForm.get('selectedLicenses')?.get('susPackageMLFB_OSD' + i)?.value,
        'description': this.softwareUpdateServiceForm.get('selectedLicenses')?.get('susPackageDescription' + i)?.value,
        'quantity': this.softwareUpdateServiceForm.get('selectedLicenses')?.get('susPackageQuantity' + i)?.value
      });
    }
  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.softwareUpdateServiceForm);

    console.log(jsonData);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  scrollToTop(){
    const element = document.getElementById('first-element');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  openHelpModal(section: string) {
    this.modalService.show(OpenHelpDialogModalComponent, { initialState: { sectionName: section } });
  }



}
