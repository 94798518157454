<si-newton-section>

  <app-navigation [currentSectionForm]=generalInformationForm></app-navigation>

  <form [formGroup]="generalInformationForm" class="col-md-12" style="align-items: center; top: 10px;">
    <app-general-project-parameter [parentForm]="generalInformationForm" (openHelpModal)="openHelpModal($event)"></app-general-project-parameter>
    <app-contractual-parameters [parentForm]="generalInformationForm" (openHelpModal)="openHelpModal($event)" (submitValues)="submit()"></app-contractual-parameters>
    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 20px;" (click)="submit()"
    [disabled]="!(generalInformationForm.valid)">Submit</button>
    <button *ngIf="!(generalInformationForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
    (click)="logInvalidControls(generalInformationForm)">
      Log Invalid Values
    </button>
  </form>
</si-newton-section>

<!--PCSS neo Start / PCS 7 STrt als Dropdown-->
