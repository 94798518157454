import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConstRestData } from 'src/app/const-data/rest';
import { IndividualService } from 'src/app/data-models/IndividualService';
import { OpenHelpDialogModalComponent } from 'src/app/modals/open-help-dialog-modal/open-help-dialog-modal.component';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-indivdual-service',
  templateUrl: './indivdual-service.component.html',
  styleUrls: ['./indivdual-service.component.scss']
})
export class IndivdualServiceComponent implements OnInit {

  REST_URLS = ConstRestData.restUrls;

  currentIndividualService!: IndividualService;
  serviceNumberAsString!: string;

  individualServiceForm!: FormGroup;
  individualServiceFormMain!: FormGroup;

  constructor(public modalRef: BsModalRef, private formBuilder: FormBuilder, private formDataService: FormDataService,
    private dataTransferService: DataTransferService, private modalService: BsModalService) {

    this.createForm();
  }

  ngOnInit(){

    this.addFormControls();
  }

  createForm(){
    this.individualServiceForm = this.formBuilder.group({
      main: this.formBuilder.group({})
    });

    this.individualServiceFormMain = this.individualServiceForm.get('main') as FormGroup;
  }

  addFormControls(){
    // eslint-disable-next-line guard-for-in
    for (const key in this.currentIndividualService){

      console.log(key);
      const valueOfCurrentKey = (this.currentIndividualService as any)[key];
      console.log(valueOfCurrentKey);
      if (key.includes('amountApplicationYear')){
        this.individualServiceFormMain.
          addControl(key + this.serviceNumberAsString,
            new FormControl(valueOfCurrentKey,
              Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));
      } else if (key.includes('yearOfStartFix')) {
        this.individualServiceFormMain.addControl(key,
          new FormControl(valueOfCurrentKey));
      } else {
        this.individualServiceFormMain.addControl(key + this.serviceNumberAsString,
          new FormControl(valueOfCurrentKey, Validators.required));
      }
    }

    // eslint-disable-next-line guard-for-in
    for (const control in this.individualServiceFormMain.controls){
      console.log(control);
    }
  }

  async checkNotValid(){

    // eslint-disable-next-line guard-for-in
    for (const field in this.individualServiceFormMain.controls) {

      console.log(field);
      console.log(this.individualServiceFormMain.get(field)!.valid);

    }

  }

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  async update(){
    await this.submitValues();
    await this.readData();
  }

  async submitAndClose(){

    await this.submitValues();
    this.modalRef.hide();
  }

  async submitValues(){
    const jsonData = await this.formDataService.getDataFromDialog(this.individualServiceForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.individualServiceForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    console.log(formData);
    this.formDataService.fillDataIntoDialog(this.individualServiceForm, formData);
  }

  openHelpModal(section: string) {
    this.modalService.show(OpenHelpDialogModalComponent, { initialState: { sectionName: section } });
  }

}
