import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-service-parameters',
  templateUrl: './service-parameters.component.html',
  styleUrls: ['./service-parameters.component.scss']
})
export class ServiceParametersComponent {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  constructor() { }

  
  openHelpDialog() {
    this.openHelpModal.emit('service-parameters');
  }

}
