/* eslint-disable guard-for-in */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ConstRestData } from 'src/app/const-data/rest';
import { ContractualParametersComponent } from './sub-sections/contractual-parameters/contractual-parameters.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OpenHelpDialogModalComponent } from 'src/app/modals/open-help-dialog-modal/open-help-dialog-modal.component';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {

  REST_URLS = ConstRestData.restUrls;
  generalInformationForm: FormGroup;
  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;
  @ViewChild(ContractualParametersComponent) contractualParametersSection!: ContractualParametersComponent;

  versionOfCalculation: any;

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService, private modalService: BsModalService) {
    this.generalInformationForm = this.formBuilder.group({
      generalProjectParameter: this.formBuilder.group({
        customerName: [null, Validators.required],
        projectName: [null, Validators.required],
        loaRequirements: [null, Validators.required],
        country: [null, Validators.required],
        location: [null, Validators.required],
        productionOf: [null, Validators.required],
        plantType: [null, Validators.required],
        projectType: [null, Validators.required],
        lcsOfferType: [null, Validators.required],
        currentPCS7version: [null, Validators.required],
        swApplicationBasedOn: [null, Validators.required],
        siemensBURegion: [null, Validators.required],
        yearOfLCSCalculation: [null, Validators.required],
        yearOfStart: [null, Validators.required]
      }),
      contractualParameters: this.formBuilder.group({
        startWithPcs7OrPcsNeo: [null, Validators.required],
        lcsContractDuration: [null, Validators.required],
        yearOfStartFix: [null, Validators.required],
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        pcsNeoEvolution: [0],
        pcs7UpgradeYear1: [0], pcs7UpgradeYear2: [0], pcs7UpgradeYear3: [0],
        pcs7SwUpdateYear1: [0], pcs7SwUpdateYear2: [0], pcs7SwUpdateYear3: [0],
        pcs7SwUpdateYear4: [0], pcs7SwUpdateYear5: [0], pcs7SwUpdateYear6: [0],
        pcs7SwUpdateYear7: [0], pcs7SwUpdateYear8: [0], pcs7SwUpdateYear9: [0],
        pcs7SwUpdateYear10: [0], pcs7SwUpdateYear11: [0], pcs7SwUpdateYear12: [0],
        pcs7SwUpdateYear13: [0], pcs7SwUpdateYear14: [0], pcs7SwUpdateYear15: [0],
        pcs7SwUpdateYear16: [0], pcs7SwUpdateYear17: [0], pcs7SwUpdateYear18: [0],
        pcs7SwUpdateYear19: [0], pcs7SwUpdateYear20: [0], pcs7SwUpdateYear21: [0],
        pcs7SwUpdateYear22: [0], pcs7SwUpdateYear23: [0], pcs7SwUpdateYear24: [0],
        pcs7SwUpdateYear25: [0],
        pcsNeoHwUpgradeYear1: [0], pcsNeoHwUpgradeYear2: [0], pcsNeoHwUpgradeYear3: [0],
        pcsNeoSwUpgradeYear1: [0], pcsNeoSwUpgradeYear2: [0], pcsNeoSwUpgradeYear3: [0],
        pcsNeoSwUpdateYear1: [0], pcsNeoSwUpdateYear2: [0], pcsNeoSwUpdateYear3: [0],
        pcsNeoSwUpdateYear4: [0], pcsNeoSwUpdateYear5: [0], pcsNeoSwUpdateYear6: [0],
        pcsNeoSwUpdateYear7: [0], pcsNeoSwUpdateYear8: [0], pcsNeoSwUpdateYear9: [0],
        pcsNeoSwUpdateYear10: [0], pcsNeoSwUpdateYear11: [0], pcsNeoSwUpdateYear12: [0],
        pcsNeoSwUpdateYear13: [0], pcsNeoSwUpdateYear14: [0], pcsNeoSwUpdateYear15: [0],
        pcsNeoSwUpdateYear16: [0], pcsNeoSwUpdateYear17: [0], pcsNeoSwUpdateYear18: [0],
        pcsNeoSwUpdateYear19: [0], pcsNeoSwUpdateYear20: [0], pcsNeoSwUpdateYear21: [0],
        pcsNeoSwUpdateYear22: [0], pcsNeoSwUpdateYear23: [0], pcsNeoSwUpdateYear24: [0],
        pcsNeoSwUpdateYear25: [0]
      })
    });
  }

  ngOnInit() {

    this.versionOfCalculation = sessionStorage.getItem('calculationVersion');

    this.readData();
  }

  async submit(){
    await this.writeData();

  }

  async logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.generalInformationForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    if (formData.customerName === 'Dreamworks' || formData.projectName === 'Null_Project'){

      const metaData = await this.dataTransferService.getMetaData();

      formData.customerName = (metaData as any).customerName;
      formData.projectName = (metaData as any).projectName;

    }

    if (formData.pcsNeoEvolution){
      console.log(formData.pcsNeoEvolution)
      formData.pcsNeoEvolution = Number(formData.pcsNeoEvolution);
      console.log(formData.pcsNeoEvolution)
    }

    await this.formDataService.fillDataIntoDialog(this.generalInformationForm, formData);

    await this.contractualParametersSection.updateContractTime();
    this.contractualParametersSection.fillSelectedYears('pcs7Upgrade', 3);
    this.contractualParametersSection.fillSelectedYears('pcsNeoHwUpgrade', 3);
    this.contractualParametersSection.fillSelectedYears('pcsNeoSwUpgrade', 3);
    this.contractualParametersSection.fillSelectedYears('pcs7SwUpdate', 25);
    this.contractualParametersSection.fillSelectedYears('pcsNeoSwUpdate', 25);
  }

  // async identifyFirstThreeHwUpgradeYears() {
  //   const contractualParameters = this.generalInformationForm.get('contractualParameters')?.value;
  //   const pcsNeoUpgradeYears = [];
  //   const pcs7UpgradeYears = [];

  //   for (let i = 1; i <= 25; i++) {
  //     const pcsNeoValue = contractualParameters[`pcsNeoHwUpgradeYear${i}`];
  //     const pcs7Value = contractualParameters[`pcs7UpgradeYear${i}`];

  //     if (pcsNeoValue !== null && pcsNeoValue !== '0' && pcsNeoValue !== 0 && pcsNeoValue !== undefined) {
  //       pcsNeoUpgradeYears.push(parseInt(pcsNeoValue, 10));
  //     }

  //     if (pcs7Value !== null && pcs7Value !== '0' && pcs7Value !== 0 && pcs7Value !== undefined) {
  //       pcs7UpgradeYears.push(parseInt(pcs7Value, 10));
  //     }
  //   }

  //   let pcsNeoEvolution = parseInt(contractualParameters.pcsNeoEvolution, 10);
  //   if (isNaN(pcsNeoEvolution)) {
  //     pcsNeoEvolution = 0;
  //   }

  //   const allYears = [...pcsNeoUpgradeYears, ...pcs7UpgradeYears];

  //   if (pcsNeoEvolution !== null && pcsNeoEvolution !== 0) {
  //     allYears.push(pcsNeoEvolution);
  //   }

  //   const firstThreeHwUpgradeYears = allYears.sort((a, b) => a - b).slice(0, 3);

  //   console.log(allYears);
  //   console.log(firstThreeHwUpgradeYears);

  //   const controlNames = ['firstUpgradeOverall', 'secondUpgradeOverall', 'thirdUpgradeOverall'];

  //   for (let i = 0; i < firstThreeHwUpgradeYears.length; i++) {
  //     this.generalInformationForm.get('contractualParameters')?.get(controlNames[i])?.setValue(firstThreeHwUpgradeYears[i]);
  //     this.generalInformationForm.get('contractualParameters')?.get(controlNames[i])?.markAsDirty();
  //   }

  //   for (let i = firstThreeHwUpgradeYears.length; i < 3; i++) {
  //     this.generalInformationForm.get('contractualParameters')?.get(controlNames[i])?.setValue(0);
  //     this.generalInformationForm.get('contractualParameters')?.get(controlNames[i])?.markAsDirty();
  //   }
  // }


  async writeData() {

    // await this.identifyFirstThreeHwUpgradeYears();

    const jsonData = await this.formDataService.getDataFromDialog(this.generalInformationForm);

    if (jsonData.customerName || jsonData.projectName){
      const newCustomerName = this.generalInformationForm?.controls?.generalProjectParameter?.get('customerName')?.value;
      const newProjectName = this.generalInformationForm?.controls?.generalProjectParameter?.get('projectName')?.value;

      await this.dataTransferService.updateMetaData(newCustomerName, newProjectName);
    }

    jsonData.customerName = this.generalInformationForm.get('generalProjectParameter')?.get('customerName')?.value;
    jsonData.projectName = this.generalInformationForm.get('generalProjectParameter')?.get('projectName')?.value;


    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));


    await this.readData();

  }

  openHelpModal(section: string) {
    this.modalService.show(OpenHelpDialogModalComponent, { initialState: { sectionName: section } });
  }
}
