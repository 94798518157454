<form [formGroup]="parentForm">
  <div formGroupName='productServiceAndSupport'>

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Product Service & Support</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('product-service-and-support')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">

        <div class="col">
          <div class="form-check">
            <input class="form-check-input" formControlName="checkBoxOnlineSupport"
              type="checkbox">
            <label class="form-check-label">
              Online Support
            </label>
          </div>
        </div>

        <div class="col">
          <div class="form-check">
            <input class="form-check-input" formControlName="checkBoxIndustryServiceCard" type="checkbox">
            <label class="form-check-label">
              Industry Service Card
            </label>
          </div>
        </div>

        <div class="col"></div>
        <div class="col"></div>

      </div>

      <div [hidden]="+(parentForm.get('productServiceAndSupport')?.get('checkBoxIndustryServiceCard')?.value) !== 1">

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Standard</b>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" formControlName="cBSISC36"
                type="checkbox">
              <label class="form-check-label">
                SISC with renewal every 36 months
              </label>
            </div>
          </div>
        </div>

        <div class="row" [hidden]="+(parentForm.get('productServiceAndSupport')?.get('cBSISC36')?.value) !== 1">
          <div class="col-md-2">
            <div class="form-check">
              <input class="form-check form-check-inline" formControlName="rB36prioPrem"
                    type="radio"
                    [(ngModel)]="rB36prioPrem"
                    value="Priority">
              <label class="form-check-label">
                Priority
              </label>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-check">
              <input class="form-check form-check-inline" formControlName="rB36prioPrem"
                    type="radio"
                    [(ngModel)]="rB36prioPrem"
                    value="Premium">
              <label class="form-check-label">
                Premium
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <si-newton-form-group label="5/10 Cases" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dD36Cases')"
                [valueProvider]="getDropdownValue"
                formControlName="dD36Cases"></si-newton-dropdown>
            </si-newton-form-group>

          </div>

          <div class="col-md-4">
            <si-newton-form-group label="Quantity Cards" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dD36Qty')"
                [valueProvider]="getDropdownValue"
                formControlName="dD36Qty"></si-newton-dropdown>
            </si-newton-form-group>

          </div>
        </div>

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Internal Option 1</b>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" formControlName="cBSISCperYear"
                type="checkbox">
              <label class="form-check-label">
                SISC per Year
              </label>
            </div>
          </div>
        </div>

        <div class="row" [hidden]="+(parentForm.get('productServiceAndSupport')?.get('cBSISCperYear')?.value) !== 1">
          <div class="col-md-2">
            <div class="form-check">
              <input class="form-check form-check-inline" formControlName="rBperYearPrioPrem"
                    type="radio"
                    [(ngModel)]="rBperYearPrioPrem"
                    value="Priority">
              <label class="form-check-label">
                Priority
              </label>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-check">
              <input class="form-check form-check-inline" formControlName="rBperYearPrioPrem"
                    type="radio"
                    [(ngModel)]="rBperYearPrioPrem"
                    value="Premium">
              <label class="form-check-label">
                Premium
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <si-newton-form-group label="5/10 Cases for Year 1" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dDperYearCases')"
                [valueProvider]="getDropdownValue"
                formControlName="dDperYearCases"></si-newton-dropdown>
            </si-newton-form-group>

          </div>

          <div class="col-md-4">
            <si-newton-form-group label="Quantity Cards for Year 1" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dDperYearQty')"
                [valueProvider]="getDropdownValue"
                formControlName="dDperYearQty"></si-newton-dropdown>
            </si-newton-form-group>

          </div>
        </div>

        <div class="row" [hidden]="+(parentForm.get('productServiceAndSupport')?.get('cBSISCperYear')?.value) !== 1">
          <div class="col-md-4"></div>

          <div class="col-md-4">
            <si-newton-form-group label="5/10 Cases for Year 2-15" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dDperYearCases15')"
                [valueProvider]="getDropdownValue"
                formControlName="dDperYearCases15"></si-newton-dropdown>
            </si-newton-form-group>

          </div>

          <div class="col-md-4">
            <si-newton-form-group label="Quantity Cards for Year 2-15" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dDperYearQty15')"
                [valueProvider]="getDropdownValue"
                formControlName="dDperYearQty15"></si-newton-dropdown>
            </si-newton-form-group>

          </div>
        </div>


        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Internal Option 2</b>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" formControlName="cBSISCatUpgrades"
                type="checkbox">
              <label class="form-check-label">
                SISC at years with HW & SW Upgrades
              </label>
            </div>
          </div>
        </div>

        <div class="row" [hidden]="+(parentForm.get('productServiceAndSupport')?.get('cBSISCatUpgrades')?.value) !== 1">
          <div class="col-md-2">
            <div class="form-check">
              <input class="form-check form-check-inline" formControlName="rBSISCyearsWithUpgrades"
                    type="radio"
                    [(ngModel)]="rBSISCyearsWithUpgrades"
                    value="Priority">
              <label class="form-check-label">
                Priority
              </label>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-check">
              <input class="form-check form-check-inline" formControlName="rBSISCyearsWithUpgrades"
                    type="radio"
                    [(ngModel)]="rBSISCyearsWithUpgrades"
                    value="Premium">
              <label class="form-check-label">
                Premium
              </label>
            </div>
          </div>

          <div class="col-md-4">
            <si-newton-form-group label="5/10 Cases" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dDyearsWithUpdatesCases')"
                [valueProvider]="getDropdownValue"
                formControlName="dDyearsWithUpdatesCases"></si-newton-dropdown>
            </si-newton-form-group>

          </div>

          <div class="col-md-4">
            <si-newton-form-group label="Quantity Cards" [errorResolverMap]="{required: 'Please select an option'}">
              <si-newton-dropdown
                [dropdownOptions]="getDropdownOptions('dDYearsWithUpdatesQty')"
                [valueProvider]="getDropdownValue"
                formControlName="dDYearsWithUpdatesQty"></si-newton-dropdown>
            </si-newton-form-group>

          </div>
        </div>
      </div>



    </div>


  </div>
</form>
