import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pricing-factors',
  templateUrl: './pricing-factors.component.html',
  styleUrls: ['./pricing-factors.component.scss']
})
export class PricingFactorsComponent {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  constructor() { }

  stringToNumber(value: string){
    return Number(value);
  }

  
  openHelpDialog() {
    this.openHelpModal.emit('pricing-factors');
  }

}
