/* eslint-disable guard-for-in */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { SwAddonsComponent } from './sub-sections/sw-addons/sw-addons.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OpenHelpDialogModalComponent } from 'src/app/modals/open-help-dialog-modal/open-help-dialog-modal.component';

@Component({
  selector: 'app-hw-sw-addons',
  templateUrl: './hw-sw-addons.component.html',
  styleUrls: ['./hw-sw-addons.component.scss']
})
export class HwSwAddonsComponent implements OnInit {
  hwAndSwAddonsForm!: FormGroup;

  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;
  @ViewChild(SwAddonsComponent) swAddonsForm!: SwAddonsComponent;


  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService, private modalService: BsModalService) {

    this.hwAndSwAddonsForm = this.formBuilder.group({
      swAddons: this.formBuilder.group({}),
      hwAddons: this.formBuilder.group({})
    });

    for (let i = 1; i <= 10; i++){

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsDesc' + i.toString(), new FormControl(null));

        (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsTabName' + i.toString(), new FormControl(null));


      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsQty' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsRepl' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsThird' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsMLFB' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsEffort' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsNoOfComponents' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsDescMeasure' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsMaintenance' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsComment' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsPCS7Related' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('swAddonsPCSneoRelated' + i.toString(), new FormControl(null));


      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsDesc' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsTabName' + i.toString(), new FormControl(null));


      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsQty' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsRepl' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsMLFB' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsEffort' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsDescMeasure' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsMaintenance' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsComment' + i.toString(), new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsPCS7Related' + i.toString(),
          new FormControl(null, Validators.compose([Validators.required, Validators.min(0)])));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('hwAddonsPCSneoRelated' + i.toString(), new FormControl(null));
    }

    //Add repeating FormControls for Physical System
    for (let i = 1; i <= 200; i++){

      
      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemMlfb' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemPrice' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemComponentType' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemTotalQuantity' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemDesc' + i, new FormControl(null));

      //PCS neo

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemNeoMlfb' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemNeoPrice' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemNeoComponentType' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemNeoQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemNeoTotalQuantity' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('swAddons') as FormGroup)!.
        addControl('physicalSystemNeoDesc' + i, new FormControl(null));

        (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemMlfb' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemPrice' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemComponentType' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemTotalQuantity' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemDesc' + i, new FormControl(null));

      //PCS neo

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemNeoMlfb' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemNeoPrice' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemNeoComponentType' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemNeoQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemNeoTotalQuantity' + i, new FormControl(null));

      (this.hwAndSwAddonsForm.get('hwAddons') as FormGroup)!.
        addControl('physicalSystemNeoDesc' + i, new FormControl(null));


    }
  }

  ngOnInit() {
    this.readData();
  }

  async submit(){
    await this.writeData();
  }

  async logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.hwAndSwAddonsForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    for(let i = 1; i <= 10; i++){
      formData['hwAddonsTabName' + i] = formData['hwAddonsDesc' + i];
      formData['swAddonsTabName' + i] = formData['swAddonsDesc' + i];

    }
    
    await this.formDataService.fillDataIntoDialog(this.hwAndSwAddonsForm, formData);

  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.hwAndSwAddonsForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  scrollToTop(){
    const element = document.getElementById('first-element');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  openHelpModal(section: string) {
    this.modalService.show(OpenHelpDialogModalComponent, { initialState: { sectionName: section } });
  }

}

