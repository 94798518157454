<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="physicalSystemBOM">
    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Phsyical System</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('physical-system')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>


    <div *ngIf="isThisSectionVisible">
      <tabset>
        <tab heading="PCS 7">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">PCS 7 BOM Import</b>
            </div>

          </div>

          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-3">
              <button type="button"
                class="btn btn-secondary"
                style="width: 100%;"
                (click)="downloadFile('bomImportTemplate')">
                Download BOM Template
              </button>
            </div>
            <div class="col-md-2">
              <label class="btn btn-primary" style="margin: auto;">
                Select BOM File
                <span class="newton-search"></span>
                <input #fileSelection type="file"
                  accept="application/msexcel"
                  (change)="storePCS7BomFile()">
              </label>
            </div>

            <div class="col-md-3">
              <button type="button"
              class="btn btn-success"
              style="width: 100%;"
              (click)="uploadBOM('PCS 7')"
              [disabled]="bomFile === undefined">
              Upload BOM
            </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">PCS 7 BOM Export</b>
            </div>

          </div>

          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-3">
              <button type="button"
                class="btn btn-secondary"
                style="width: 100%;"
                (click)="generateBomFile('PCS 7')">
                Generate PCS 7 BOM File
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">IPC Detection</b>
            </div>

          </div>

          <div class="row">
            <p class="info">
              Use a plant structure file for detecting IPC classes automatically </p>
          </div>

          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-2">
              <label class="btn btn-primary" style="margin: auto;">
                Select Plant Structure File
                <span class="newton-search"></span>
                <input #plantStructurefileSelectionPcs7 type="file"
                  accept="application/msexcel"
                  (change)="storePlantStructureFileForPCS7()">
              </label>
            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn-success" style="width: 100%;" (click)="detectIPCs('PCS 7')" [disabled]="plantStructureFile === undefined">
              Detect IPCs
            </button>
            </div>
          </div>

          <div class="row">
            <p class="info">
              An import of PCS 7 components by a BOM will overwrite the current PCS 7 Physical System</p>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Manual Addition of components</b>
            </div>

          </div>

          <div class="row">
            <div class="col">
              <si-newton-form-group label="Category" [errorResolverMap]="{required: 'Please select an option'}">
                <select formControlName="newComponentType" style="margin-bottom: 20px; position: relative; height: 50px">
                  <ng-container *ngFor="let item of getDropdownOptions('physicalSystemPCS7ComponentTypesDropdown')" >
                    <optgroup *ngIf="item.type === 'category'" label="{{item.value}}" class="category-style"></optgroup>

                    <option *ngIf="item.type === 'option'" [value]="item.value">
                      {{item.value}}
                    </option>
                  </ng-container>

                </select>

            </si-newton-form-group>
            </div>

            <div class="col">
              <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="newComponentMlfb" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col">
              <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="newComponentDescription" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col">
              <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                <input formControlName="newComponentQuantity" siNewton type="number" [min]="0">
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-md-9">

            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn-success" style="float: right;" [disabled]="!isNewComponentValid('PCS 7')" (click)="updatePhysicalSystem('PCS 7')"
              >Add Component</button>
            </div>
          </div>

          <div class="row">
            <div *ngIf="areNullValuesInPricesPresent === true">
              <p class="warnInfo">
                There are Components with 0 € as price. This could mean that we could'nt get the prices. Please adapt manually. </p>
            </div>

          </div>

          <div class="row">
            <div *ngIf="getNumOfUnclassifiedElements('PCS 7') > 0">
              <p class="warnInfo">
                There are unclassified components. These components does not have an effect to calculation. Please choose an type for these components.</p>
            </div>

          </div>

          <div class="row" style="margin-bottom: 5px;">
            <div class="col-md-4">
    
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-primary" (click)="togglePcs7Visibility()">Show / Hide PCS 7 System</button>
            </div>
            <div class="col-md-4">
    
            </div>
          </div>

          <div *ngIf="isPCS7SystemVisible">
            <div *ngIf="allPhysicalSystemComponents.length === 0">
              <b>No Elements in this section present</b>
            </div>
  
            <div *ngIf="allPhysicalSystemComponents && allPhysicalSystemComponents.length > 0">
              <b>{{allPhysicalSystemComponents.length}} Elements, {{getNumOfNotRelevantElements('PCS 7')}} not relevant,  {{getNumOfUnclassifiedElements('PCS 7')}} unclassified</b>
            </div>
  
            <si-newton-table [rows]="allPhysicalSystemComponentsFiltered"
              [loading]="allPhysicalSystemComponents.length === 0" [rowsPerPage]="rowsPerPcs7ProductPage">
  
                <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="1.2">
                  <ng-template siNewtonTableCell let-row="row">
                    <select formControlName="physicalSystemComponentType{{row.componentNumber}}" style="margin-bottom: 20px; position: relative; height: 50px">
                      <ng-container *ngFor="let item of getDropdownOptions('physicalSystemPCS7ComponentTypesDropdown')" >
                        <optgroup *ngIf="item.type === 'category'" label="{{item.value}}" class="category-style"></optgroup>
    
                        <option *ngIf="item.type === 'option'" [value]="item.value">
                          {{item.value}}
                        </option>
                      </ng-container>
    
                    </select>
  
                  </ng-template>
                </siNewtonTableColumn>
  
                <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                      <input formControlName="physicalSystemMlfb{{row.componentNumber}}" siNewton>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>
  
                <siNewtonTableColumn key="description" name="Description" [widthFactor]="1.4" [disableFilter]="true">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group>
                      <textarea formControlName="physicalSystemDesc{{row.componentNumber}}" siNewton></textarea>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>
  
                <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.4" [disableFilter]="true">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                      <input formControlName="physicalSystemQuantity{{row.componentNumber}}" type="number" [min]="0" siNewton>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>
  
                <siNewtonTableColumn key="listPrice" name="L Price" [widthFactor]="0.7" [disableFilter]="true">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                      <div class="input-group-prepend">
                          <span class="input-group-text">€</span>
                      </div>
                      <input formControlName="physicalSystemPrice{{row.componentNumber}}" type="number" [min]="0" step="any" siNewton>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>
  
                <siNewtonTableColumn [widthFactor]="0.2" [disableFilter]="true" [disableSort]="true">
                  <ng-template siNewtonTableCell let-value='value' let-row="row">
                    <button title="Delete Component" class="btn-danger" (click)="deleteComponent(row.componentNumber, 'PCS 7')">
                      <i class="bi bi-trash"></i>
                    </button>
                  </ng-template>
                </siNewtonTableColumn>
  
  
  
            </si-newton-table>
  
            <p>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcs7ProductPage==10) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(10, 'PCS 7')">10</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcs7ProductPage==25) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(25, 'PCS 7')">25</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcs7ProductPage==50) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(50, 'PCS 7')">50</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcs7ProductPage==100) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(100, 'PCS 7')">100</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcs7ProductPage==250) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(250, 'PCS 7')">250</button>
              rows / site</p>
    
           
  
          </div>
     
          <div class="row" style="margin-bottom: 10px;">
            <div class="col-md-9">

            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn-success"
            (click)="getPricesForAllPhysicalSystemComponents('PCS 7')" [disabled]="allPhysicalSystemComponents.length === 0" style="float: right">Update List Prices</button>
            </div>
          </div>
        </tab>
        <tab heading="PCS neo">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">PCS neo BOM Import</b>
            </div>

          </div>

          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-3">
              <button type="button"
                class="btn btn-secondary"
                style="width: 100%;"
                (click)="downloadFile('bomImportTemplate')">
                Download BOM Template
              </button>
            </div>
            <div class="col-md-2">
              <label class="btn btn-primary" style="margin: auto;">
                Select BOM File
                <span class="newton-search"></span>
                <input #neoFileSelection type="file"
                  accept="application/msexcel"
                  (change)="storePCSNeoBomFile()">
              </label>
            </div>

            <div class="col-md-3">
              <button type="button"
              class="btn btn-success"
              style="width: 100%;"
              (click)="uploadBOM('PCS neo')"
              [disabled]="bomFile === undefined">
              Upload BOM
            </button>
            </div>
          </div>


          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">PCS Neo BOM Export</b>
            </div>

          </div>

          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-3">
              <button type="button"
                class="btn btn-secondary"
                style="width: 100%;"
                (click)="generateBomFile('PCS neo')">
                Generate PCS Neo BOM File
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">IPC Detection</b>
            </div>

          </div>

          <div class="row">
            <p class="info">
              Use a plant structure file for detecting IPC classes automatically </p>
          </div>

          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-2">
              <label class="btn btn-primary" style="margin: auto;">
                Select Plant Structure File
                <span class="newton-search"></span>
                <input #plantStructurefileSelectionPcsNeo type="file"
                  accept="application/msexcel"
                  (change)="storePlantStructureFileForPCSNeo()">
              </label>
            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn-success" style="width: 100%;" (click)="detectIPCs('PCS neo')" [disabled]="plantStructureFile === undefined">
              Detect IPCs
            </button>
            </div>
          </div>

          <div class="row">
            <p class="info">
              An import of PCS neo components by a BOM will overwrite the current PCS neo Physical System</p>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Manual Addition of components</b>
            </div>

          </div>

          <div class="row">
            <div class="col">
              <si-newton-form-group label="Category" [errorResolverMap]="{required: 'Please select an option'}">
                  <select formControlName="newNeoComponentType" style="margin-bottom: 20px; position: relative; height: 50px">
                    <ng-container *ngFor="let item of getDropdownOptions('physicalSystemPcsNeoComponentTypesDropdown')" >
                      <optgroup *ngIf="item.type === 'category'" label="{{item.value}}" class="category-style"></optgroup>

                      <option *ngIf="item.type === 'option'" [value]="item.value">
                        {{item.value}}
                      </option>
                    </ng-container>

                  </select>

              </si-newton-form-group>
            </div>

            <div class="col">
              <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="newNeoComponentMlfb" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col">
              <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="newNeoComponentDescription" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col">
              <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                <input formControlName="newNeoComponentQuantity" siNewton type="number" [min]="0">
              </si-newton-form-group>
            </div>

            <!-- <div class="col">
              <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
                <input formControlName="newComponentListPrice" siNewton type="number" [min]="0" step="any">
              </si-newton-form-group>
            </div> -->
          </div>

          <div class="row">
            <div class="col-md-9">

            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn-success" style="float: right;" [disabled]="!isNewComponentValid('PCS neo')" (click)="updatePhysicalSystem('PCS neo')"
              >Add Component</button>
            </div>
          </div>



          <div class="row">
            <div *ngIf="areNullValuesInNeoPricesPresent === true">
              <p class="warnInfo">
                There are Components with 0 € as price. This could mean that we could'nt get the prices. Please adapt manually. </p>
            </div>

          </div>

          <div class="row">
            <div *ngIf="getNumOfUnclassifiedElements('PCS neo') > 0">
              <p class="warnInfo">
                There are unclassified components. These components does not have an effect to calculation. Please choose an type for these components.</p>
            </div>

          </div>

          <div class="row" style="margin-bottom: 5px;">
            <div class="col-md-4">
    
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-primary" (click)="togglePcsNeoVisibility()">Show / Hide PCS neo System</button>
            </div>
            <div class="col-md-4">
    
            </div>
          </div>

          <div *ngIf="isPCSneoSystemVisible">
            <div *ngIf="allPhysicalSystemNeoComponents.length === 0">
              <b>No Elements in this section present</b>
            </div>

            <div *ngIf="allPhysicalSystemNeoComponents && allPhysicalSystemNeoComponents.length > 0">
              <b>{{allPhysicalSystemNeoComponents.length}} Elements, {{getNumOfNotRelevantElements('PCS neo')}} not relevant,  {{getNumOfUnclassifiedElements('PCS neo')}} unclassified</b>
            </div>

            <si-newton-table [rows]="allPhysicalSystemNeoComponents"
              [loading]="allPhysicalSystemNeoComponents.length === 0" [rowsPerPage]="25">

              <ng-container style="background-color: lightcoral;">

                <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="1.2">
                  <ng-template siNewtonTableCell let-row="row">
                    <select formControlName="physicalSystemNeoComponentType{{row.componentNumber}}" style="margin-bottom: 20px; position: relative; height: 50px">

                      <ng-container *ngFor="let item of getDropdownOptions('physicalSystemPcsNeoComponentTypesDropdown')">
                        <optgroup *ngIf="item.type === 'category'" label="{{item.value}}" class="category-style"></optgroup>
  
                        <option *ngIf="item.type === 'option'" [value]="item.value">
                          {{item.value}}
                        </option>
                      </ng-container>

                    </select>

                  </ng-template>
                </siNewtonTableColumn>

                <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                      <input formControlName="physicalSystemNeoMlfb{{row.componentNumber}}" siNewton>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>

                <siNewtonTableColumn key="description" name="Description" [widthFactor]="1.4" [disableFilter]="true">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group>
                      <textarea formControlName="physicalSystemNeoDesc{{row.componentNumber}}" siNewton></textarea>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>

                <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.4" [disableFilter]="true">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                      <input formControlName="physicalSystemNeoQuantity{{row.componentNumber}}" type="number" [min]="0" siNewton>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>

                <siNewtonTableColumn key="listPrice" name="L Price" [widthFactor]="0.7" [disableFilter]="true">
                  <ng-template siNewtonTableCell let-row="row">
                    <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                      <div class="input-group-prepend">
                          <span class="input-group-text">€</span>
                      </div>
                      <input formControlName="physicalSystemNeoPrice{{row.componentNumber}}" type="number" [min]="0" step="any" siNewton>
                    </si-newton-form-group>
                  </ng-template>
                </siNewtonTableColumn>

                <siNewtonTableColumn [widthFactor]="0.2" [disableFilter]="true" [disableSort]="true">
                  <ng-template siNewtonTableCell let-value='value' let-row="row">
                    <button title="Delete Component" class="btn-danger" (click)="deleteComponent(row.componentNumber, 'PCS neo')">
                      <i class="bi bi-trash"></i>
                    </button>
                  </ng-template>
                </siNewtonTableColumn>
              </ng-container>
            </si-newton-table>

            <p>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcsNeoProductPage==10) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(10, 'PCS Neo')">10</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcsNeoProductPage==25) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(25, 'PCS Neo')">25</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcsNeoProductPage==50) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(50, 'PCS Neo')">50</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcsNeoProductPage==100) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(100, 'PCS Neo')">100</button>
              <button style="margin-right: 5px;" [ngStyle]="{'background-color': (rowsPerPcsNeoProductPage==250) ? '#005578' : '#ffffff'}" (click)="setRowSizeForProducts(250, 'PCS Neo')">250</button>
              rows / site</p>
     
          </div>
              

          <div class="row" style="margin-bottom: 10px;">
            <div class="col-md-9">

            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn-success"
            (click)="getPricesForAllPhysicalSystemComponents('PCS neo')" [disabled]="allPhysicalSystemNeoComponents.length === 0" style="float: right">Update List Prices</button>
            </div>
          </div>
        </tab>
      </tabset>

    </div>

  </div>
</form>
