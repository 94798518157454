<form [formGroup]="parentForm">
  <div formGroupName="preventiveMaintenance">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Preventive Maintenance</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('preventive-maintenance')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <div class="col">
          <div class="form-check">
            <input class="form-check form-check-inline" formControlName="prevMainSelection"
            type="radio" [(ngModel)]="prevMainSelection" value="Lump sum incl wearing parts">
            <label class="form-check-label">
              Lump sum incl wearing parts
            </label>
          </div>
        </div>
        <div class="col">
          <div class="form-check">
            <input class="form-check form-check-inline" formControlName="prevMainSelection"
            type="radio" [(ngModel)]="prevMainSelection" value="Based on time & material">
            <label class="form-check-label">
              Based on time & material
            </label>
          </div>
        </div>
        <div class="col">

        </div>


      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col" style="text-align: center; margin-bottom: 10px;">
          <button type="button" class="btn btn-success"
            (click)="openMaintenanceParameters()">Edit Maintenance Parameters</button>
        </div>

        <div class="col"></div>
      </div>


      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Overview for Prev. Maintenance PCS 7 / Neo</b>
        </div>
      </div>

      <div class="row">

        <div class="col-md-4">
          <si-newton-form-group label="Times per year" [errorResolverMap]="{required: 'Value is required', pattern: 'You have to use an integer'}">
            <input formControlName="prevMainPerYear" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-md-4">

        </div>
      </div>

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">PCS 7</b>
        </div>
      </div>
      <div class="row">
        <div class="col">

        </div>
        <div class="col">
          <si-newton-form-group label="Total effort per implementation" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">days</span>
            </div>
            <input formControlName="totalEffortPrevMaintenance" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Total Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="totalMaterialCost" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Total service cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="totalServiceCost" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>

      </div>


      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">PCS Neo</b>
        </div>
      </div>
      <div class="row">
        <div class="col">

        </div>
        <div class="col">
          <si-newton-form-group label="Total effort per implementation" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">days</span>
            </div>
            <input formControlName="totalEffortPrevMaintenancePcsNeo" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Total Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="totalMaterialCostPcsNeo" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Total service cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="totalServiceCostPcsNeo" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Overview for Prev. Maintenance Individual Tasks</b>
        </div>
      </div>
      <div class="row">
        <div class="col">

        </div>
        <div class="col">
          <si-newton-form-group label="Total effort per implementation" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">days</span>
            </div>
            <input formControlName="individualIuWTasksTotalEffort" type="number" step="any" [min]="0" siNewton readonly highlight>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Total Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="individualIuWTasksTotalMaterialCost" type="number" step="any" [min]="0" siNewton readOnly highlight>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Total service cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="individualIuWTasksTotalServiceCost" type="number" step="any" [min]="0" siNewton readOnly highlight>
          </si-newton-form-group>
        </div>

      </div>


    </div>

  </div>
</form>
