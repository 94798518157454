import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-exchange-factors',
  templateUrl: './exchange-factors.component.html',
  styleUrls: ['./exchange-factors.component.scss']
})
export class ExchangeFactorsComponent {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();

  constructor() { }

  
  openHelpDialog() {
    this.openHelpModal.emit('exchange-factors');
  }
}
