<form [formGroup]="parentForm">
    <div formGroupName="priceOverview">
  
      <div class="subSectionHeaderWrapper">
        <div class="d-flex justify-content-between align-items-center subSectionHeader">
          <h5 class="m-0">Price Overview</h5>
          
          <div class="ms-auto d-flex align-items-center">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none" 
              (click)="openHelpModal.emit('price-overview')" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
            >
              <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
            </button>
            
          </div>
        </div>
      </div>
  
  
      <div class="row">
        <div #ContainerRef class="col">
          <ngx-charts-bar-vertical-stacked
          [view]="[ContainerRef.offsetWidth, 400]"
          [scheme]="colorScheme"
          [results]="priceOverviewData"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showDataLabel]="true"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical-stacked>
  
        </div>
  
      </div>

      
    <div class="row" style="margin-top: 15px;">
      <div *ngIf="parentForm.get('priceOverview')?.get('resultTotalPriceEuro')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Price EUR">
          <input formControlName="resultTotalPriceEuro" readonly siNewton>
        </si-newton-form-group>
      </div>

      <div *ngIf="parentForm.get('priceOverview')?.get('resultTotalMarginEuro')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Margin EUR">
          <input formControlName="resultTotalMarginEuro" readonly siNewton>
        </si-newton-form-group>
      </div>
 
      
      <div *ngIf="parentForm.get('priceOverview')?.get('resultTotalPriceTargetCurrency')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Price Target Currency">
          <input formControlName="resultTotalPriceTargetCurrency" readonly siNewton>
        </si-newton-form-group>
      </div>

      <div *ngIf="parentForm.get('priceOverview')?.get('resultTotalMarginTargetCurrency')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Margin Target Currency">
          <input formControlName="resultTotalMarginTargetCurrency" readonly siNewton>
        </si-newton-form-group>
      </div>
    </div>
    </div>
  </form>
  
  