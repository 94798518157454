<si-newton-section>

  <app-navigation [currentSectionForm]=regionalFactorsForm></app-navigation>

  <form [formGroup]="regionalFactorsForm" class="col-md-12" style="align-items: center;">
    <app-save-regional-factors [parentForm]="regionalFactorsForm" (downloadData)="downloadData()" (readData)="readData()"></app-save-regional-factors>
    <app-cost-factors id="first-element" [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-cost-factors>
    <app-regional-discounts [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-regional-discounts>
    <app-risk-factors [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-risk-factors>
    <app-service-parameters [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-service-parameters>
    <app-cost-increase-rates [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-cost-increase-rates>
    <app-exchange-factors [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-exchange-factors>
    <app-pricing-factors [parentForm]="regionalFactorsForm" (openHelpModal)="openHelpModal($event)"></app-pricing-factors>
    <div>
      <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
      [disabled]="!(regionalFactorsForm.valid)">Submit</button>
      <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
    (click)="scrollToTop()">Go to top</button>
    <button *ngIf="!(regionalFactorsForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
    (click)="logInvalidControls(regionalFactorsForm)">
      Log Invalid Values
    </button>
    </div>

  </form>
</si-newton-section>
