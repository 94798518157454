<form [formGroup]="parentForm">
  <div formGroupName="costOverview">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Cost Overview</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('cost-overview')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
        </div>
      </div>
    </div>

    <div class="row">
      <div #ContainerRef class="col">
        <ngx-charts-bar-vertical-stacked
        [view]="[ContainerRef.offsetWidth, 400]"
        [scheme]="colorScheme"
        [results]="costOverviewData"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showDataLabel]="true"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical-stacked>

      </div>
    </div>



    <div class="row" style="margin-top: 15px;">
      <div *ngIf="parentForm.get('costOverview')?.get('resultTotalCostEuro')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Cost EUR">
          <input formControlName="resultTotalCostEuro" readonly siNewton>
        </si-newton-form-group>
      </div>

      <div *ngIf="parentForm.get('costOverview')?.get('resultTotalRiskEuro')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Risk EUR">
          <input formControlName="resultTotalRiskEuro" readonly siNewton>
        </si-newton-form-group>
      </div>
 

      <div *ngIf="parentForm.get('costOverview')?.get('resultTotalCostTargetCurrency')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Cost Target Currency">
          <input formControlName="resultTotalCostTargetCurrency" readonly siNewton>
        </si-newton-form-group>
      </div>

      <div *ngIf="parentForm.get('costOverview')?.get('resultTotalRiskTargetCurrency')?.value !== null" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Total Risk Target Currency">
          <input formControlName="resultTotalRiskTargetCurrency" readonly siNewton>
        </si-newton-form-group>
      </div>
    </div>

  </div>
</form>

