import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contract-management',
  templateUrl: './contract-management.component.html',
  styleUrls: ['./contract-management.component.scss']
})
export class ContractManagementComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();



  rBContractManagement!: string;
  constructor() { }
}

/*

 rBContractManagement: [null, Validators.required],
        basicEffortStandard: [null, Validators.required],
        basicEffortExtAvailability: [null, Validators.required],
        percentageOfTotalLcsCost: [null, Validators.required],
        costForAlternativeManagement: [null, Validators.required],
        textFieldCurrentCostPerYearContractManagement: [null]
*/
