<form [formGroup]="parentForm">
  <div formGroupName="onCallServiceControlCenter">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">On Call Service</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('on-call-service')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">

        <div class="col">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="checkBoxOnCallService" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              OnCall Service
            </label>
          </div>
        </div>

          <div class="col" [hidden]="+(parentForm.get('onCallServiceControlCenter')?.get('checkBoxOnCallService')?.value) !== 1">
            <si-newton-form-group label="Cost per year" [errorResolverMap]="{required: 'Value is required'}">
              <div class="input-group-prepend">
                <span class="input-group-text">€</span>
              </div>
              <input formControlName="costPerYearOnCallService" type="number" step="any" [min]="0" siNewton>
            </si-newton-form-group>
          </div>

          <div class="col" [hidden]="+(parentForm.get('onCallServiceControlCenter')?.get('checkBoxOnCallService')?.value) !== 1">
            <si-newton-form-group label="Implementation (one time)" [errorResolverMap]="{required: 'Value is required'}">
              <div class="input-group-prepend">
                <span class="input-group-text">€</span>
              </div>
              <input formControlName="implementationCostOnCallService" type="number" step="any" [min]="0" siNewton>
            </si-newton-form-group>
          </div>

          <div class="col">

          </div>

      </div>
    </div>


  </div>
</form>
