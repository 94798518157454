<form [formGroup]="parentForm">
  <div formGroupName="mvdiForm">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">MVDI</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('mvdi')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <b class="subSubSectionHeader">PCS 7 Parameter</b>
      </div>

      <div class="row">
        <div class="col-xl-2 col-md-4 col-xs-12">
          <b>Evaluation AS Driver for PCS 7</b>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiEvaluierungPcs7Mlfb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
        <si-newton-form-group label="Initial year"
        [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">              <si-newton-dropdown
          [dropdownOptions]="getDropdownOptions('mvdiEvaluierungPcs7InitYear')"
          [valueProvider]="getDropdownValue"
          formControlName="mvdiEvaluierungPcs7InitYear"></si-newton-dropdown>

      </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiEvaluierungPcs7GPrice" type="number" step="any" [min]="0" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="mvdiEvaluierungPcs7LcsDiscountCb" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                LCS Discount
              </label>
            </div>

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiEvaluierungPcs7GPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-2 col-md-4 col-xs-12">
          <b>Development (AS / OS) for PCS 7</b>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiDevelopmentPcs7Mlfb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">

        <si-newton-form-group label="Initial year"
        [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">              <si-newton-dropdown
          [dropdownOptions]="getDropdownOptions('mvdiDevelopmentPcs7InitYear')"
          [valueProvider]="getDropdownValue"
          formControlName="mvdiDevelopmentPcs7InitYear"></si-newton-dropdown>

      </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiDevelopmentPcs7GPrice" type="number" step="any" [min]="0" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiDevelopmentPcs7GPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-7 col-xs-12">
          <b>AS Block driver library + Service contract</b>
        </div>

        <div class="col-xl-3 col-md-5 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiAsServicevertragMlfb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="Initial year"
          [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">              <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('mvdiAsServicevertragInitYear')"
            [valueProvider]="getDropdownValue"
            formControlName="mvdiAsServicevertragInitYear"></si-newton-dropdown>

          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="1st ext."
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="mvdiAsServicevertragFirstExtenstion" type="number" [min]="0" readOnly highlight siNewton>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="2nd ext."
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="mvdiAsServicevertragSecondExtension" type="number" [min]="0" readOnly highlight siNewton>
        </si-newton-form-group>
        </div>

        <div class="col-xl-6 col-md-1">

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiAsServicevertragGPrice" type="number" step="any" [min]="0" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-3 col-xs-12">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="mvdiAsServicevertragLcsDiscountCb" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                LCS Discount
              </label>
            </div>

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiAsServicevertragGPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-7 col-xs-12">
          <b>OS Block driver library + Service contract</b>
        </div>

        <div class="col-xl-3 col-md-5 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiOsServicevertragMlfb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">

          <si-newton-form-group label="Initial year"
          [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">              <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('mvdiOsServicevertragInitYear')"
            [valueProvider]="getDropdownValue"
            formControlName="mvdiOsServicevertragInitYear"></si-newton-dropdown>

          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="1st ext."
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="mvdiOsServicevertragFirstExtension" type="number" [min]="0" readOnly highlight siNewton>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="2nd ext."
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="mvdiOsServicevertragSecondExtension" type="number" [min]="0" readOnly highlight siNewton>
        </si-newton-form-group>
        </div>

        <div class="col-xl-6 col-md-1">

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiOsServicevertragGPrice" type="number" step="any" [min]="0" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-3 col-xs-12">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="mvdiOsServicevertragLcsDiscountCb" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                LCS Discount
              </label>
            </div>

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiOsServicevertragGPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>

      <div class="row">

        <div class="col-xs-12 col-md-4">
          <p class="info">
            Please select if Service Contract Extensions are needed
          </p>
        </div>

        <div class="col-xs-12 col-md-4">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="mvdiFirstContractExtensionCb" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              1st. Service Contract Extension needed?
            </label>
          </div>
        </div>

        <div class="col-xs-12 col-md-4">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="mvdiSecondContractExtensionCb" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              2nd. Service Contract Extension needed?
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <b class="subSubSectionHeader">PCS Neo Parameter</b>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <si-newton-form-group label="Year of PCS neo Evolution" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="mvdiYearOfNeoEvolution" type="number" [min]="0" readonly siNewton>
          </si-newton-form-group>

        </div>
      </div>
      <div class="row">
        <div class="col-xl-2 col-md-4 col-xs-12">
          <b>Evaluation for PCS neo</b>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiEvaluierungPcsNeoMlfb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">

          <si-newton-form-group label="Initial year"
          [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
        <input formControlName="mvdiEvaluierungPcsNeoInitYear" type="number" [min]="0" readonly siNewton>
      </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiEvaluierungPcsNeoGPrice" type="number" [min]="0" step="any" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="mvdiEvaluierungPcsNeoLcsDiscountCb" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                LCS Discount
              </label>
            </div>

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiEvaluierungPcsNeoGPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>


      <div class="row">
        <div class="col-xl-2 col-md-4 col-xs-12">
          <b>Development AS</b>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiASsDevelopmentPcsNeoMflb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">

          <si-newton-form-group label="Initial year"
          [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
        <input formControlName="mvdiASsDevelopmentPcsNeoInitYear" type="number" [min]="0" readonly siNewton>
            </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiASsDevelopmentPcsNeoGPrice" type="number" step="any" [min]="0" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="mvdiASsDevelopmentPcsNeoLcsDiscountCb" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                LCS Discount
              </label>
            </div>

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiASsDevelopmentPcsNeoGPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-2 col-md-4 col-xs-12">
          <b>Development OS</b>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="MLFB">
            <input formControlName="mvdiOsDevelopmentPcsNeoMlfb" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="Initial year"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="mvdiOsDevelopmentPcsNeoInitYear" type="number" [min]="0" readonly siNewton>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price">
          <input formControlName="mvdiOsDevelopmentPcsNeoGPrice" type="number" step="any" [min]="0" readOnly siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="mvdiOsDevelopmentPcsNeoLcsDiscountCb" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                LCS Discount
              </label>
            </div>

        </div>

        <div class="col-xl-2 col-md-4 col-xs-12">
          <si-newton-form-group label="G price incl. risk">
          <input formControlName="mvdiOsDevelopmentPcsNeoGPriceInclRisk" type="number" step="any" [min]="0" readonly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <b class="subSubSectionHeader">Administrative Parameter</b>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <si-newton-form-group label="Service Contract Duration"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="mvdiServiceContractDuration" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">years</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <si-newton-form-group label="LCS Discount"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="mvdiLCSDiscount" type="number" step="any" [min]="0" [max]="100" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <si-newton-form-group label="Avg. price increase rate" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="mvdiPriceIncreaseRate" type="number" step="any" [min]="0" [max]="100" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

      </div>

      <div class="row">

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="mvdiMargin" type="number" step="any" [min]="0" [max]="100" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
          <si-newton-form-group label="Risk addition" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="mvdiRiskAddition" type="number" step="any" [min]="0" [max]="100" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

    </div>



  </div>
</form>
