<form [formGroup]="parentForm">
  <div>

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Result File</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('result-file')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-success col-md-3" (click)="generateResult()">
          Generate new Result File
        </button>
      </div>
    </div>
  </div>
</form>
