<div class="container">
  <form [formGroup]="parentForm">
    <div formGroupName="swAddons">
      <div class="subSectionHeaderWrapper">
        <div class="d-flex justify-content-between align-items-center subSectionHeader">
          <h5 class="m-0">Identified 3rd Party SW Adddons</h5>
          
          <div class="ms-auto d-flex align-items-center">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none" 
              (click)="openHelpModal.emit('identified-3rd-party-sw-addons')" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
            >
              <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
            </button>
            

          </div>
        </div>
      </div>
      <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Identified 3rd Party SW Addons for PCS 7</b>
          </div>
        </div>

        <div *ngIf="thirdPartySwAddonsFromPCS7Bom.length === 0">
          <b>No 3rd Party Addons for PCS 7 identified</b>
        </div>

        <si-newton-table *ngIf="thirdPartySwAddonsFromPCS7Bom && thirdPartySwAddonsFromPCS7Bom.length > 0" [rows]="thirdPartySwAddonsFromPCS7Bom"
              [rowsPerPage]="25">

              <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="description" name="Description" [widthFactor]="1.4" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  <textarea style="height: 100%; width: 100%">
                    {{row.description}}
                  </textarea>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.4" [disableFilter]="true">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="listPrice" name="L Price" [widthFactor]="0.7" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  {{row.listPrice}} €
              </ng-template>
              </siNewtonTableColumn>

        </si-newton-table>

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Identified 3rd Party SW Addons for PCS Neo</b>
          </div>
        </div>

        <div *ngIf="thirdPartySwAddonsFromPcsNeoBom.length === 0">
          <b>No 3rd Party SW Addons for PCS neo identified</b>
        </div>

        <si-newton-table  *ngIf="thirdPartySwAddonsFromPcsNeoBom && thirdPartySwAddonsFromPcsNeoBom.length > 0" [rows]="thirdPartySwAddonsFromPcsNeoBom"
            [rowsPerPage]="25">

              <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="description" name="Description" [widthFactor]="1.4" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  <textarea style="height: 100%; width: 100%">
                    {{row.description}}
                  </textarea>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.4" [disableFilter]="true">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="listPrice" name="L Price" [widthFactor]="0.7" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                    {{row.listPrice}} €
                </ng-template>
              </siNewtonTableColumn>

        </si-newton-table>

      </div>


      <div class="subSectionHeaderWrapper">
        <div class="d-flex justify-content-between align-items-center subSectionHeader">
          <h5 class="m-0">Calculation of 3rd Party SW Addons</h5>
          
          <div class="ms-auto d-flex align-items-center">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none" 
              (click)="openHelpModal.emit('calculation-of-3rd-party-sw-addons')" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
            >
              <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
            </button>
            
  
          </div>
        </div>
      </div>
      
      <tabset>
     
          <tab heading="{{getSwAddonTabName(x)}}" *ngFor="let _ of [].constructor(10), let x = index">
      
            <div class="customCard">
              <div class="row">

                <div class="col-sm-3 col-md-4 col-lg-2">
                  <b class="subSubSectionHeader">SW Addon {{x+1}}</b>
                </div>

                <div class="col-sm-9 col-md-6 col-lg-4">
                  <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsDesc{{x+1}}" siNewton>
                  </si-newton-form-group>
                </div>

                  <div class="col-md-2">
                  <div class="form-check">
                    <input id="cb-enabled" class="form-check-input" formControlName="swAddonsPCS7Related{{x+1}}" type="checkbox">
                    <label for="cb-enabled" class="form-check-label">
                      Use in PCS 7
                    </label>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-check">
                    <input id="cb-enabled" class="form-check-input" formControlName="swAddonsPCSneoRelated{{x+1}}" type="checkbox">
                    <label for="cb-enabled" class="form-check-label">
                      Use in PCS Neo
                    </label>
                  </div>
                </div>
              </div>


              <div class="row">

                <div class="col-sm-6 col-md-4 col-lg-2">
                  <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsQty{{x+1}}" type="number" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-2">
                  <si-newton-form-group label="Replacement Costs" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="swAddonsRepl{{x+1}}" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-2">

                  <si-newton-form-group label="3rd Party SUS Cost" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="swAddonsThird{{x+1}}" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-2">
                  <si-newton-form-group label="MLFB / Order ID" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsMLFB{{x+1}}"  siNewton>
                  </si-newton-form-group>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-2">
                  <si-newton-form-group label="Adaption effort" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsEffort{{x+1}}" type="number" step="any" [min]="0" siNewton>
                    <div class="input-group-prepend">
                      <span class="input-group-text">[min]</span>
                    </div>
                  </si-newton-form-group>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-2">
                  <si-newton-form-group label="Relevant Components" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsNoOfComponents{{x+1}}" type="number" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b class="labelAccent">Preventice Maintenance</b>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-md-5">
                  <si-newton-form-group label="Description of measure" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsDescMeasure{{x+1}}" siNewton>
                  </si-newton-form-group>
                </div>

                <div class="col-sm-6 col-md-3">
                  <si-newton-form-group label="Maintenance effort" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsMaintenance{{x+1}}" type="number" step="any" [min]="0" siNewton>
                    <div class="input-group-prepend">
                      <span class="input-group-text">[min]</span>
                    </div>
                  </si-newton-form-group>
                </div>
                <div class="col-sm-4 col-md-4">
                  <si-newton-form-group label="Comment" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="swAddonsComment{{x+1}}" siNewton>
                  </si-newton-form-group>
                </div>

              </div>
            </div>
          </tab>

      </tabset>
  

    </div>
  </form>
</div>
