import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-upgrade-packages',
  templateUrl: './upgrade-packages.component.html',
  styleUrls: ['./upgrade-packages.component.scss']
})
export class UpgradePackagesComponent {
  @Input()
  public parentForm!: FormGroup;
  
  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();

  constructor() { }

}
