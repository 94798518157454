<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="sivaasSystem">
    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">SIVaaS System</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('sivaas-system')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    <div *ngIf="isThisSectionVisible">
      <!-- <div class="row">
        <div class="col">
          <button type="button" class="btn btn-success" style="float: right;" (click)="openSivaasImportModal()">
            Import new Sivaas Configuration
          </button>
        </div>
      </div> -->

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">SIVaaS BOM Import</b>
        </div>

      </div>

      <div class="row" style="margin-bottom: 25px;">
        <div class="col-md-2">
          <button type="button"
            class="btn btn-secondary"
            style="width: 100%;"
            (click)="downloadFile('bomImportTemplate')">
            Download BOM Template
          </button>
        </div>
        <div class="col-md-2">
          <label class="btn btn-primary" style="margin: auto;">
            Select SIVaaS BOM
            <span class="newton-search"></span>
            <input #fileSelection type="file"
              accept="application/msexcel"
              (change)="storeSivaasBom()">
          </label>
        </div>

        <div class="col-md-2">
          <button type="button"
          class="btn btn-success"
          style="width: 100%;"
          (click)="uploadBOM('Sivaas')"
          [disabled]="bomFile === undefined">
          Upload BOM
        </button>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Add new SIVaaS Component</b>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <si-newton-form-group label="Component Type" [errorResolverMap]="{required: 'Please select an option'}">
            <si-newton-dropdown
              [dropdownOptions]="getDropdownOptions('sivaasComponentTypesDropdown')"
              [valueProvider]="getDropdownValue"
              formControlName="SivaasConfCategory"></si-newton-dropdown>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newComponentMlfb" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="Name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newComponentName" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="newComponentQuantity" siNewton type="number" [min]="0">
          </si-newton-form-group>
        </div>

        <!-- <div class="col">
          <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="newComponentListPrice" siNewton type="number" [min]="0" step="any">
          </si-newton-form-group>
        </div> -->
      </div>

      <div class="row">
        <div class="col-md-9">

        </div>

        <div class="col-md-3">
          <button type="button" class="btn btn-success" style="float: right;" (click)="updateSivaasComponents()"
          [disabled]="!isNewComponentValid()">Add Component</button>
        </div>
      </div>

      <div class="row" style="margin-bottom: 5px;">
        <div class="col-md-4">

        </div>
        <div class="col-md-4">
          <button type="button" class="btn btn-primary" (click)="toggleSivaasConfVisible()">Show / Hide SIVaaS Configuration</button>
        </div>
        <div class="col-md-4">

        </div>
      </div>

      <div [hidden]="!isSivaasConfigurationVisbible">
        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Your SIVaaS Configuration</b>
          </div>

        </div>

           <div class="row">
            <div *ngIf="areNullValuesInPricesPresent === true">
              <p class="info" style="background-color: red;">
                There are Components with 0 € as price. This could mean that we could'nt get the prices. Please adapt manually. </p>
            </div>
        </div>

        <tabset>
          <tab heading="Sivaas">
            <div *ngIf="getSivaasComponentsWithoutIADC().length === 0">
              <b>No Elements in this section present</b>
            </div>


            <si-newton-table [rows]="getSivaasComponentsWithoutIADC()"
              [loading]="allSivaasComponents.length === 0">

              <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="1.2">
                <ng-template siNewtonTableCell let-row="row">
                  <select formControlName="SivaasConfCategory{{row.componentNumber}}" style="margin-bottom: 20px; position: relative; height: 50px">
                    <ng-container *ngFor="let item of getDropdownOptions('sivaasComponentTypesDropdown')" >
                      <option [value]="item.value">
                        {{item.value}}
                      </option>
                    </ng-container>
  
                  </select>

                </ng-template>
              </siNewtonTableColumn>

                  <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
                    <ng-template siNewtonTableCell let-row="row">
                      <si-newton-form-group>
                        <input formControlName="SivaasConfMLFB{{row.componentNumber}}" siNewton>
                      </si-newton-form-group>
                    </ng-template>
                  </siNewtonTableColumn>

                  <siNewtonTableColumn key="name" name="Name" [widthFactor]="1">
                    <ng-template siNewtonTableCell let-row="row">
                      <si-newton-form-group>
                        <textarea formControlName="SivaasConfName{{row.componentNumber}}" siNewton></textarea>
                      </si-newton-form-group>
                    </ng-template>
                  </siNewtonTableColumn>

                  <siNewtonTableColumn key="quantity" name="Qty" [widthFactor]="0.5" [disableFilter]="true">
                    <ng-template siNewtonTableCell let-row="row">
                      <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                        <input formControlName="SivaasConfQuantity{{row.componentNumber}}" type="number" [min]="0" siNewton>
                      </si-newton-form-group>
                    </ng-template>
                  </siNewtonTableColumn>

                  <siNewtonTableColumn key="listPrice" name="List Price in €" [widthFactor]="0.8" [disableFilter]="true">
                    <ng-template siNewtonTableCell let-row="row">
                      <si-newton-form-group>
                        <div class="input-group-prepend">
                          <span class="input-group-text">€</span>
                        </div>
                        <input formControlName="SivaasConfListPrice{{row.componentNumber}}"
                          type="number" step="any" [min]="0" class="markChanges" siNewton>
                      </si-newton-form-group>
                    </ng-template>
                  </siNewtonTableColumn>

                  <siNewtonTableColumn key="totalListPrice" name="Total List Price in €" [widthFactor]="0.7" [disableFilter]="true">
                    <ng-template siNewtonTableCell let-row="row">
                      <si-newton-form-group>
                        <div class="input-group-prepend">
                          <span class="input-group-text">€</span>
                        </div>
                        <input formControlName="SivaasConfTotalListPrice{{row.componentNumber}}" siNewton highlight>
                      </si-newton-form-group>
                    </ng-template>
                  </siNewtonTableColumn>

                  <siNewtonTableColumn key="totalGaPrice" name="Total GA Price in €" [widthFactor]="0.7" [disableFilter]="true">
                    <ng-template siNewtonTableCell let-row="row">
                      <si-newton-form-group>
                        <div class="input-group-prepend">
                          <span class="input-group-text">€</span>
                        </div>
                        <input formControlName="SivaasConfTotalGAPrice{{row.componentNumber}}" siNewton highlight>
                      </si-newton-form-group>
                    </ng-template>
                  </siNewtonTableColumn>

                  <siNewtonTableColumn [widthFactor]="0.3" [disableFilter]="true" [disableSort]="true">
                    <ng-template siNewtonTableCell let-value='value' let-row="row">
                      <button title="Delete Component" class="btn-danger" (click)="deleteComponent(row.componentNumber)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </ng-template>
                  </siNewtonTableColumn>

            </si-newton-table>

          </tab>
          <tab heading="IADC">

            <div *ngIf="getSivaasComponentsForIADC().length === 0">
              <b>No Elements in this section present</b>
            </div>
            <si-newton-table [rows]="getSivaasComponentsForIADC()"
              [loading]="allSivaasComponents.length === 0">

              <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="1.2">
                <ng-template siNewtonTableCell let-row="row">
                  <select formControlName="SivaasConfCategory{{row.componentNumber}}" style="margin-bottom: 20px; position: relative; height: 50px">
                    <ng-container *ngFor="let item of getDropdownOptions('sivaasComponentTypesDropdown')" >
                      <option [value]="item.value">
                        {{item.value}}
                      </option>
                    </ng-container>
  
                  </select>

                </ng-template>
              </siNewtonTableColumn>
              <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="1.1">
                <ng-template siNewtonTableCell let-row="row">
                  <si-newton-form-group>
                    <input formControlName="SivaasConfMLFB{{row.componentNumber}}" siNewton>
                  </si-newton-form-group>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="name" name="Name">
                <ng-template siNewtonTableCell let-row="row">
                  <si-newton-form-group>
                    <input formControlName="SivaasConfName{{row.componentNumber}}" siNewton>
                  </si-newton-form-group>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="quantity" name="Qty" [widthFactor]="0.5" [disableFilter]="true">
                <ng-template siNewtonTableCell let-row="row">
                  <si-newton-form-group [errorResolverMap]="{pattern: 'Only integers are allowed'}">
                    <input formControlName="SivaasConfQuantity{{row.componentNumber}}" type="number" [min]="0" siNewton>
                  </si-newton-form-group>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="listPrice" name="List Price in €" [widthFactor]="0.8" [disableFilter]="true">
                <ng-template siNewtonTableCell let-row="row">
                  <si-newton-form-group>
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="SivaasConfListPrice{{row.componentNumber}}" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="totalListPrice" name="Total List Price in €" [widthFactor]="0.7" [disableFilter]="true">
                <ng-template siNewtonTableCell let-row="row">
                  <si-newton-form-group>
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="SivaasConfTotalListPrice{{row.componentNumber}}" siNewton highlight>
                  </si-newton-form-group>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="totalGaPrice" name="Total GA Price in €" [widthFactor]="0.7" [disableFilter]="true">
                <ng-template siNewtonTableCell let-row="row">
                  <si-newton-form-group>
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="SivaasConfTotalGAPrice{{row.componentNumber}}" siNewton highlight>
                  </si-newton-form-group>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn [widthFactor]="0.3" [disableFilter]="true" [disableSort]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  <button title="Delete Component" class="btn-danger" (click)="deleteComponent(row.componentNumber)">
                    <i class="bi bi-trash"></i>
                  </button>
                </ng-template>
              </siNewtonTableColumn>

            </si-newton-table>
          </tab>
        </tabset>

        <div class="row" style="margin-bottom: 10px;">
          <div class="col-md-9">

          </div>

          <div class="col-md-3">
            <button type="button" class="btn btn-success"
          (click)="getPricesForSivaasComponents()" [disabled]="allSivaasComponents.length === 0" style="float: right">Update List Prices</button>
          </div>
        </div>

      </div>
    </div>

  </div>
</form>
