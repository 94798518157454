/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OpenHelpDialogModalComponent } from 'src/app/modals/open-help-dialog-modal/open-help-dialog-modal.component';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-result-overview',
  templateUrl: './result-overview.component.html',
  styleUrls: ['./result-overview.component.scss']
})

export class ResultOverviewComponent implements OnInit {

  resultOverviewForm!: FormGroup;

  costOverviewData: {name: string; series: {name: string; value: number}[]}[] = [];
  priceOverviewData: {name: string; series: {name: string; value: number}[]}[] = [];


  // view: [number, number] = [800, 400];

  // // options
  // showXAxis = true;
  // showYAxis = true;
  // gradient = true;
  // showLegend = false;
  // showXAxisLabel = true;
  // xAxisLabel = 'Year of Contract';
  // showYAxisLabel = true;
  // yAxisLabel = 'Internal Costs in €';

  // colorScheme = {
  //   domain: ['#00646e', '#41aaaa', '#5AA454']
  // };


  constructor(private formBuilder: FormBuilder, private formDataService: FormDataService,
    private dataTransferService: DataTransferService, private modalService: BsModalService) {
    this.resultOverviewForm = this.formBuilder.group({
      yearOfStartFix: [null],
      costOverview: this.formBuilder.group({
        resultTotalCostEuro: [null],
        resultTotalRiskEuro: [null],
        resultTotalCostTargetCurrency: [null],
        resultTotalRiskTargetCurrency: [null],
        resultNonRecuringServices1: [null], resultNonRecuringServices2: [null], resultNonRecuringServices3: [null],
        resultNonRecuringServices4: [null], resultNonRecuringServices5: [null], resultNonRecuringServices6: [null],
        resultNonRecuringServices7: [null], resultNonRecuringServices8: [null], resultNonRecuringServices9: [null],
        resultNonRecuringServices10: [null], resultNonRecuringServices11: [null], resultNonRecuringServices12: [null],
        resultNonRecuringServices13: [null], resultNonRecuringServices14: [null], resultNonRecuringServices15: [null],
        resultNonRecuringServices16: [null], resultNonRecuringServices17: [null], resultNonRecuringServices18: [null],
        resultNonRecuringServices19: [null], resultNonRecuringServices20: [null], resultNonRecuringServices21: [null],
        resultNonRecuringServices22: [null], resultNonRecuringServices23: [null], resultNonRecuringServices24: [null],
        resultNonRecuringServices25: [null], resultlcsStandardServices1: [null], resultlcsStandardServices2: [null],
        resultlcsStandardServices3: [null], resultlcsStandardServices4: [null], resultlcsStandardServices5: [null],
        resultlcsStandardServices6: [null], resultlcsStandardServices7: [null], resultlcsStandardServices8: [null],
        resultlcsStandardServices9: [null], resultlcsStandardServices10: [null], resultlcsStandardServices11: [null],
        resultlcsStandardServices12: [null], resultlcsStandardServices13: [null], resultlcsStandardServices14: [null],
        resultlcsStandardServices15: [null], resultlcsStandardServices16: [null], resultlcsStandardServices17: [null],
        resultlcsStandardServices18: [null], resultlcsStandardServices19: [null], resultlcsStandardServices20: [null],
        resultlcsStandardServices21: [null], resultlcsStandardServices22: [null], resultlcsStandardServices23: [null],
        resultlcsStandardServices24: [null], resultlcsStandardServices25: [null], resultAdditionalServices1: [null],
        resultAdditionalServices2: [null], resultAdditionalServices3: [null], resultAdditionalServices4: [null],
        resultAdditionalServices5: [null], resultAdditionalServices6: [null], resultAdditionalServices7: [null],
        resultAdditionalServices8: [null], resultAdditionalServices9: [null], resultAdditionalServices10: [null],
        resultAdditionalServices11: [null], resultAdditionalServices12: [null], resultAdditionalServices13: [null],
        resultAdditionalServices14: [null], resultAdditionalServices15: [null], resultAdditionalServices16: [null],
        resultAdditionalServices17: [null], resultAdditionalServices18: [null], resultAdditionalServices19: [null],
        resultAdditionalServices20: [null], resultAdditionalServices21: [null], resultAdditionalServices22: [null],
        resultAdditionalServices23: [null], resultAdditionalServices24: [null], resultAdditionalServices25: [null],
        resultLcsSUS1: [null], resultLcsSUS2: [null], resultLcsSUS3: [null], resultLcsSUS4: [null], resultLcsSUS5: [null],
        resultLcsSUS6: [null], resultLcsSUS7: [null], resultLcsSUS8: [null], resultLcsSUS9: [null], resultLcsSUS10: [null],
        resultLcsSUS11: [null], resultLcsSUS12: [null], resultLcsSUS13: [null], resultLcsSUS14: [null], resultLcsSUS15: [null],
        resultLcsSUS16: [null], resultLcsSUS17: [null], resultLcsSUS18: [null], resultLcsSUS19: [null], resultLcsSUS20: [null],
        resultLcsSUS21: [null], resultLcsSUS22: [null], resultLcsSUS23: [null], resultLcsSUS24: [null], resultLcsSUS25: [null],
        resultPcs7HwSwUpgrade1: [null], resultPcs7HwSwUpgrade2: [null], resultPcs7HwSwUpgrade3: [null],
        resultPcs7HwSwUpgrade4: [null], resultPcs7HwSwUpgrade5: [null], resultPcs7HwSwUpgrade6: [null],
        resultPcs7HwSwUpgrade7: [null], resultPcs7HwSwUpgrade8: [null], resultPcs7HwSwUpgrade9: [null],
        resultPcs7HwSwUpgrade10: [null], resultPcs7HwSwUpgrade11: [null], resultPcs7HwSwUpgrade12: [null],
        resultPcs7HwSwUpgrade13: [null], resultPcs7HwSwUpgrade14: [null], resultPcs7HwSwUpgrade15: [null],
        resultPcs7HwSwUpgrade16: [null],
        resultPcs7HwSwUpgrade17: [null],
        resultPcs7HwSwUpgrade18: [null],
        resultPcs7HwSwUpgrade19: [null],
        resultPcs7HwSwUpgrade20: [null],
        resultPcs7HwSwUpgrade21: [null],
        resultPcs7HwSwUpgrade22: [null],
        resultPcs7HwSwUpgrade23: [null],
        resultPcs7HwSwUpgrade24: [null],
        resultPcs7HwSwUpgrade25: [null],
        resultPcsNeoLicenseProcurement1: [null],
        resultPcsNeoLicenseProcurement2: [null],
        resultPcsNeoLicenseProcurement3: [null],
        resultPcsNeoLicenseProcurement4: [null],
        resultPcsNeoLicenseProcurement5: [null],
        resultPcsNeoLicenseProcurement6: [null],
        resultPcsNeoLicenseProcurement7: [null],
        resultPcsNeoLicenseProcurement8: [null],
        resultPcsNeoLicenseProcurement9: [null],
        resultPcsNeoLicenseProcurement10: [null],
        resultPcsNeoLicenseProcurement11: [null],
        resultPcsNeoLicenseProcurement12: [null],
        resultPcsNeoLicenseProcurement13: [null],
        resultPcsNeoLicenseProcurement14: [null],
        resultPcsNeoLicenseProcurement15: [null],
        resultPcsNeoLicenseProcurement16: [null],
        resultPcsNeoLicenseProcurement17: [null],
        resultPcsNeoLicenseProcurement18: [null],
        resultPcsNeoLicenseProcurement19: [null],
        resultPcsNeoLicenseProcurement20: [null],
        resultPcsNeoLicenseProcurement21: [null],
        resultPcsNeoLicenseProcurement22: [null],
        resultPcsNeoLicenseProcurement23: [null],
        resultPcsNeoLicenseProcurement24: [null],
        resultPcsNeoLicenseProcurement25: [null],
        resultPcsNeoSwpInfinity1: [null],
        resultPcsNeoSwpInfinity2: [null],
        resultPcsNeoSwpInfinity3: [null],
        resultPcsNeoSwpInfinity4: [null],
        resultPcsNeoSwpInfinity5: [null],
        resultPcsNeoSwpInfinity6: [null],
        resultPcsNeoSwpInfinity7: [null],
        resultPcsNeoSwpInfinity8: [null],
        resultPcsNeoSwpInfinity9: [null],
        resultPcsNeoSwpInfinity10: [null],
        resultPcsNeoSwpInfinity11: [null],
        resultPcsNeoSwpInfinity12: [null],
        resultPcsNeoSwpInfinity13: [null],
        resultPcsNeoSwpInfinity14: [null],
        resultPcsNeoSwpInfinity15: [null],
        resultPcsNeoSwpInfinity16: [null],
        resultPcsNeoSwpInfinity17: [null],
        resultPcsNeoSwpInfinity18: [null],
        resultPcsNeoSwpInfinity19: [null],
        resultPcsNeoSwpInfinity20: [null],
        resultPcsNeoSwpInfinity21: [null],
        resultPcsNeoSwpInfinity22: [null],
        resultPcsNeoSwpInfinity23: [null],
        resultPcsNeoSwpInfinity24: [null],
        resultPcsNeoSwpInfinity25: [null],
        resultHmiPictureAdaption1: [null],
        resultHmiPictureAdaption2: [null],
        resultHmiPictureAdaption3: [null],
        resultHmiPictureAdaption4: [null],
        resultHmiPictureAdaption5: [null],
        resultHmiPictureAdaption6: [null],
        resultHmiPictureAdaption7: [null],
        resultHmiPictureAdaption8: [null],
        resultHmiPictureAdaption9: [null],
        resultHmiPictureAdaption10: [null],
        resultHmiPictureAdaption11: [null],
        resultHmiPictureAdaption12: [null],
        resultHmiPictureAdaption13: [null],
        resultHmiPictureAdaption14: [null],
        resultHmiPictureAdaption15: [null],
        resultHmiPictureAdaption16: [null],
        resultHmiPictureAdaption17: [null],
        resultHmiPictureAdaption18: [null],
        resultHmiPictureAdaption19: [null],
        resultHmiPictureAdaption20: [null],
        resultHmiPictureAdaption21: [null],
        resultHmiPictureAdaption22: [null],
        resultHmiPictureAdaption23: [null],
        resultHmiPictureAdaption24: [null],
        resultHmiPictureAdaption25: [null],
        resultPcsNeoEvolution1: [null],
        resultPcsNeoEvolution2: [null],
        resultPcsNeoEvolution3: [null],
        resultPcsNeoEvolution4: [null],
        resultPcsNeoEvolution5: [null],
        resultPcsNeoEvolution6: [null],
        resultPcsNeoEvolution7: [null],
        resultPcsNeoEvolution8: [null],
        resultPcsNeoEvolution9: [null],
        resultPcsNeoEvolution10: [null],
        resultPcsNeoEvolution11: [null],
        resultPcsNeoEvolution12: [null],
        resultPcsNeoEvolution13: [null],
        resultPcsNeoEvolution14: [null],
        resultPcsNeoEvolution15: [null],
        resultPcsNeoEvolution16: [null],
        resultPcsNeoEvolution17: [null],
        resultPcsNeoEvolution18: [null],
        resultPcsNeoEvolution19: [null],
        resultPcsNeoEvolution20: [null],
        resultPcsNeoEvolution21: [null],
        resultPcsNeoEvolution22: [null],
        resultPcsNeoEvolution23: [null],
        resultPcsNeoEvolution24: [null],
        resultPcsNeoEvolution25: [null],
        resultPcsNeoHwUpgrade1: [null],
        resultPcsNeoHwUpgrade2: [null],
        resultPcsNeoHwUpgrade3: [null],
        resultPcsNeoHwUpgrade4: [null],
        resultPcsNeoHwUpgrade5: [null],
        resultPcsNeoHwUpgrade6: [null],
        resultPcsNeoHwUpgrade7: [null],
        resultPcsNeoHwUpgrade8: [null],
        resultPcsNeoHwUpgrade9: [null],
        resultPcsNeoHwUpgrade10: [null],
        resultPcsNeoHwUpgrade11: [null],
        resultPcsNeoHwUpgrade12: [null],
        resultPcsNeoHwUpgrade13: [null],
        resultPcsNeoHwUpgrade14: [null],
        resultPcsNeoHwUpgrade15: [null],
        resultPcsNeoHwUpgrade16: [null],
        resultPcsNeoHwUpgrade17: [null],
        resultPcsNeoHwUpgrade18: [null],
        resultPcsNeoHwUpgrade19: [null],
        resultPcsNeoHwUpgrade20: [null],
        resultPcsNeoHwUpgrade21: [null],
        resultPcsNeoHwUpgrade22: [null],
        resultPcsNeoHwUpgrade23: [null],
        resultPcsNeoHwUpgrade24: [null],
        resultPcsNeoHwUpgrade25: [null],
        resultPcsNeoSwUpgrade1: [null],
        resultPcsNeoSwUpgrade2: [null],
        resultPcsNeoSwUpgrade3: [null],
        resultPcsNeoSwUpgrade4: [null],
        resultPcsNeoSwUpgrade5: [null],
        resultPcsNeoSwUpgrade6: [null],
        resultPcsNeoSwUpgrade7: [null],
        resultPcsNeoSwUpgrade8: [null],
        resultPcsNeoSwUpgrade9: [null],
        resultPcsNeoSwUpgrade10: [null],
        resultPcsNeoSwUpgrade11: [null],
        resultPcsNeoSwUpgrade12: [null],
        resultPcsNeoSwUpgrade13: [null],
        resultPcsNeoSwUpgrade14: [null],
        resultPcsNeoSwUpgrade15: [null],
        resultPcsNeoSwUpgrade16: [null],
        resultPcsNeoSwUpgrade17: [null],
        resultPcsNeoSwUpgrade18: [null],
        resultPcsNeoSwUpgrade19: [null],
        resultPcsNeoSwUpgrade20: [null],
        resultPcsNeoSwUpgrade21: [null],
        resultPcsNeoSwUpgrade22: [null],
        resultPcsNeoSwUpgrade23: [null],
        resultPcsNeoSwUpgrade24: [null],
        resultPcsNeoSwUpgrade25: [null],
        resultNetworkCompReplacement1: [null],
        resultNetworkCompReplacement2: [null],
        resultNetworkCompReplacement3: [null],
        resultNetworkCompReplacement4: [null],
        resultNetworkCompReplacement5: [null],
        resultNetworkCompReplacement6: [null],
        resultNetworkCompReplacement7: [null],
        resultNetworkCompReplacement8: [null],
        resultNetworkCompReplacement9: [null],
        resultNetworkCompReplacement10: [null],
        resultNetworkCompReplacement11: [null],
        resultNetworkCompReplacement12: [null],
        resultNetworkCompReplacement13: [null],
        resultNetworkCompReplacement14: [null],
        resultNetworkCompReplacement15: [null],
        resultNetworkCompReplacement16: [null],
        resultNetworkCompReplacement17: [null],
        resultNetworkCompReplacement18: [null],
        resultNetworkCompReplacement19: [null],
        resultNetworkCompReplacement20: [null],
        resultNetworkCompReplacement21: [null],
        resultNetworkCompReplacement22: [null],
        resultNetworkCompReplacement23: [null],
        resultNetworkCompReplacement24: [null],
        resultNetworkCompReplacement25: [null],
        resultIndividualServices1: [null],
        resultIndividualServices2: [null],
        resultIndividualServices3: [null],
        resultIndividualServices4: [null],
        resultIndividualServices5: [null],
        resultIndividualServices6: [null],
        resultIndividualServices7: [null],
        resultIndividualServices8: [null],
        resultIndividualServices9: [null],
        resultIndividualServices10: [null],
        resultIndividualServices11: [null],
        resultIndividualServices12: [null],
        resultIndividualServices13: [null],
        resultIndividualServices14: [null],
        resultIndividualServices15: [null],
        resultIndividualServices16: [null],
        resultIndividualServices17: [null],
        resultIndividualServices18: [null],
        resultIndividualServices19: [null],
        resultIndividualServices20: [null],
        resultIndividualServices21: [null],
        resultIndividualServices22: [null],
        resultIndividualServices23: [null],
        resultIndividualServices24: [null],
        resultIndividualServices25: [null],
        resultSwUpdate1: [null],
        resultSwUpdate2: [null],
        resultSwUpdate3: [null],
        resultSwUpdate4: [null],
        resultSwUpdate5: [null],
        resultSwUpdate6: [null],
        resultSwUpdate7: [null],
        resultSwUpdate8: [null],
        resultSwUpdate9: [null],
        resultSwUpdate10: [null],
        resultSwUpdate11: [null],
        resultSwUpdate12: [null],
        resultSwUpdate13: [null],
        resultSwUpdate14: [null],
        resultSwUpdate15: [null],
        resultSwUpdate16: [null],
        resultSwUpdate17: [null],
        resultSwUpdate18: [null],
        resultSwUpdate19: [null],
        resultSwUpdate20: [null],
        resultSwUpdate21: [null],
        resultSwUpdate22: [null],
        resultSwUpdate23: [null],
        resultSwUpdate24: [null],
        resultSwUpdate25: [null]
      }),
      priceOverview: this.formBuilder.group({
        resultTotalPriceEuro: [null],
        resultTotalMarginEuro: [null],
        resultTotalPriceTargetCurrency: [null],
        resultTotalMarginTargetCurrency: [null],
        resultPriceNonRecuringServices1: [null], resultPriceNonRecuringServices2: [null], resultPriceNonRecuringServices3: [null],
        resultPriceNonRecuringServices4: [null], resultPriceNonRecuringServices5: [null], resultPriceNonRecuringServices6: [null],
        resultPriceNonRecuringServices7: [null], resultPriceNonRecuringServices8: [null], resultPriceNonRecuringServices9: [null],
        resultPriceNonRecuringServices10: [null], resultPriceNonRecuringServices11: [null], resultPriceNonRecuringServices12: [null],
        resultPriceNonRecuringServices13: [null], resultPriceNonRecuringServices14: [null], resultPriceNonRecuringServices15: [null],
        resultPriceNonRecuringServices16: [null], resultPriceNonRecuringServices17: [null], resultPriceNonRecuringServices18: [null],
        resultPriceNonRecuringServices19: [null], resultPriceNonRecuringServices20: [null], resultPriceNonRecuringServices21: [null],
        resultPriceNonRecuringServices22: [null], resultPriceNonRecuringServices23: [null], resultPriceNonRecuringServices24: [null],
        resultPriceNonRecuringServices25: [null], resultPricelcsStandardServices1: [null], resultPricelcsStandardServices2: [null],
        resultPricelcsStandardServices3: [null], resultPricelcsStandardServices4: [null], resultPricelcsStandardServices5: [null],
        resultPricelcsStandardServices6: [null], resultPricelcsStandardServices7: [null], resultPricelcsStandardServices8: [null],
        resultPricelcsStandardServices9: [null], resultPricelcsStandardServices10: [null], resultPricelcsStandardServices11: [null],
        resultPricelcsStandardServices12: [null], resultPricelcsStandardServices13: [null], resultPricelcsStandardServices14: [null],
        resultPricelcsStandardServices15: [null], resultPricelcsStandardServices16: [null], resultPricelcsStandardServices17: [null],
        resultPricelcsStandardServices18: [null], resultPricelcsStandardServices19: [null], resultPricelcsStandardServices20: [null],
        resultPricelcsStandardServices21: [null], resultPricelcsStandardServices22: [null], resultPricelcsStandardServices23: [null],
        resultPricelcsStandardServices24: [null], resultPricelcsStandardServices25: [null], resultPriceAdditionalServices1: [null],
        resultPriceAdditionalServices2: [null], resultPriceAdditionalServices3: [null], resultPriceAdditionalServices4: [null],
        resultPriceAdditionalServices5: [null], resultPriceAdditionalServices6: [null], resultPriceAdditionalServices7: [null],
        resultPriceAdditionalServices8: [null], resultPriceAdditionalServices9: [null], resultPriceAdditionalServices10: [null],
        resultPriceAdditionalServices11: [null], resultPriceAdditionalServices12: [null], resultPriceAdditionalServices13: [null],
        resultPriceAdditionalServices14: [null], resultPriceAdditionalServices15: [null], resultPriceAdditionalServices16: [null],
        resultPriceAdditionalServices17: [null], resultPriceAdditionalServices18: [null], resultPriceAdditionalServices19: [null],
        resultPriceAdditionalServices20: [null], resultPriceAdditionalServices21: [null], resultPriceAdditionalServices22: [null],
        resultPriceAdditionalServices23: [null], resultPriceAdditionalServices24: [null], resultPriceAdditionalServices25: [null],
        resultPriceLcsSUS1: [null], resultPriceLcsSUS2: [null], resultPriceLcsSUS3: [null], resultPriceLcsSUS4: [null], resultPriceLcsSUS5: [null],
        resultPriceLcsSUS6: [null], resultPriceLcsSUS7: [null], resultPriceLcsSUS8: [null], resultPriceLcsSUS9: [null], resultPriceLcsSUS10: [null],
        resultPriceLcsSUS11: [null], resultPriceLcsSUS12: [null], resultPriceLcsSUS13: [null], resultPriceLcsSUS14: [null], resultPriceLcsSUS15: [null],
        resultPriceLcsSUS16: [null], resultPriceLcsSUS17: [null], resultPriceLcsSUS18: [null], resultPriceLcsSUS19: [null], resultPriceLcsSUS20: [null],
        resultPriceLcsSUS21: [null], resultPriceLcsSUS22: [null], resultPriceLcsSUS23: [null], resultPriceLcsSUS24: [null], resultPriceLcsSUS25: [null],
        resultPricePcs7HwSwUpgrade1: [null], resultPricePcs7HwSwUpgrade2: [null], resultPricePcs7HwSwUpgrade3: [null],
        resultPricePcs7HwSwUpgrade4: [null], resultPricePcs7HwSwUpgrade5: [null], resultPricePcs7HwSwUpgrade6: [null],
        resultPricePcs7HwSwUpgrade7: [null], resultPricePcs7HwSwUpgrade8: [null], resultPricePcs7HwSwUpgrade9: [null],
        resultPricePcs7HwSwUpgrade10: [null], resultPricePcs7HwSwUpgrade11: [null], resultPricePcs7HwSwUpgrade12: [null],
        resultPricePcs7HwSwUpgrade13: [null], resultPricePcs7HwSwUpgrade14: [null], resultPricePcs7HwSwUpgrade15: [null],
        resultPricePcs7HwSwUpgrade16: [null],
        resultPricePcs7HwSwUpgrade17: [null],
        resultPricePcs7HwSwUpgrade18: [null],
        resultPricePcs7HwSwUpgrade19: [null],
        resultPricePcs7HwSwUpgrade20: [null],
        resultPricePcs7HwSwUpgrade21: [null],
        resultPricePcs7HwSwUpgrade22: [null],
        resultPricePcs7HwSwUpgrade23: [null],
        resultPricePcs7HwSwUpgrade24: [null],
        resultPricePcs7HwSwUpgrade25: [null],
        resultPricePcsNeoLicenseProcurement1: [null],
        resultPricePcsNeoLicenseProcurement2: [null],
        resultPricePcsNeoLicenseProcurement3: [null],
        resultPricePcsNeoLicenseProcurement4: [null],
        resultPricePcsNeoLicenseProcurement5: [null],
        resultPricePcsNeoLicenseProcurement6: [null],
        resultPricePcsNeoLicenseProcurement7: [null],
        resultPricePcsNeoLicenseProcurement8: [null],
        resultPricePcsNeoLicenseProcurement9: [null],
        resultPricePcsNeoLicenseProcurement10: [null],
        resultPricePcsNeoLicenseProcurement11: [null],
        resultPricePcsNeoLicenseProcurement12: [null],
        resultPricePcsNeoLicenseProcurement13: [null],
        resultPricePcsNeoLicenseProcurement14: [null],
        resultPricePcsNeoLicenseProcurement15: [null],
        resultPricePcsNeoLicenseProcurement16: [null],
        resultPricePcsNeoLicenseProcurement17: [null],
        resultPricePcsNeoLicenseProcurement18: [null],
        resultPricePcsNeoLicenseProcurement19: [null],
        resultPricePcsNeoLicenseProcurement20: [null],
        resultPricePcsNeoLicenseProcurement21: [null],
        resultPricePcsNeoLicenseProcurement22: [null],
        resultPricePcsNeoLicenseProcurement23: [null],
        resultPricePcsNeoLicenseProcurement24: [null],
        resultPricePcsNeoLicenseProcurement25: [null],
        resultPricePcsNeoSwpInfinity1: [null],
        resultPricePcsNeoSwpInfinity2: [null],
        resultPricePcsNeoSwpInfinity3: [null],
        resultPricePcsNeoSwpInfinity4: [null],
        resultPricePcsNeoSwpInfinity5: [null],
        resultPricePcsNeoSwpInfinity6: [null],
        resultPricePcsNeoSwpInfinity7: [null],
        resultPricePcsNeoSwpInfinity8: [null],
        resultPricePcsNeoSwpInfinity9: [null],
        resultPricePcsNeoSwpInfinity10: [null],
        resultPricePcsNeoSwpInfinity11: [null],
        resultPricePcsNeoSwpInfinity12: [null],
        resultPricePcsNeoSwpInfinity13: [null],
        resultPricePcsNeoSwpInfinity14: [null],
        resultPricePcsNeoSwpInfinity15: [null],
        resultPricePcsNeoSwpInfinity16: [null],
        resultPricePcsNeoSwpInfinity17: [null],
        resultPricePcsNeoSwpInfinity18: [null],
        resultPricePcsNeoSwpInfinity19: [null],
        resultPricePcsNeoSwpInfinity20: [null],
        resultPricePcsNeoSwpInfinity21: [null],
        resultPricePcsNeoSwpInfinity22: [null],
        resultPricePcsNeoSwpInfinity23: [null],
        resultPricePcsNeoSwpInfinity24: [null],
        resultPricePcsNeoSwpInfinity25: [null],
        resultPriceHmiPictureAdaption1: [null],
        resultPriceHmiPictureAdaption2: [null],
        resultPriceHmiPictureAdaption3: [null],
        resultPriceHmiPictureAdaption4: [null],
        resultPriceHmiPictureAdaption5: [null],
        resultPriceHmiPictureAdaption6: [null],
        resultPriceHmiPictureAdaption7: [null],
        resultPriceHmiPictureAdaption8: [null],
        resultPriceHmiPictureAdaption9: [null],
        resultPriceHmiPictureAdaption10: [null],
        resultPriceHmiPictureAdaption11: [null],
        resultPriceHmiPictureAdaption12: [null],
        resultPriceHmiPictureAdaption13: [null],
        resultPriceHmiPictureAdaption14: [null],
        resultPriceHmiPictureAdaption15: [null],
        resultPriceHmiPictureAdaption16: [null],
        resultPriceHmiPictureAdaption17: [null],
        resultPriceHmiPictureAdaption18: [null],
        resultPriceHmiPictureAdaption19: [null],
        resultPriceHmiPictureAdaption20: [null],
        resultPriceHmiPictureAdaption21: [null],
        resultPriceHmiPictureAdaption22: [null],
        resultPriceHmiPictureAdaption23: [null],
        resultPriceHmiPictureAdaption24: [null],
        resultPriceHmiPictureAdaption25: [null],
        resultPricePcsNeoEvolution1: [null],
        resultPricePcsNeoEvolution2: [null],
        resultPricePcsNeoEvolution3: [null],
        resultPricePcsNeoEvolution4: [null],
        resultPricePcsNeoEvolution5: [null],
        resultPricePcsNeoEvolution6: [null],
        resultPricePcsNeoEvolution7: [null],
        resultPricePcsNeoEvolution8: [null],
        resultPricePcsNeoEvolution9: [null],
        resultPricePcsNeoEvolution10: [null],
        resultPricePcsNeoEvolution11: [null],
        resultPricePcsNeoEvolution12: [null],
        resultPricePcsNeoEvolution13: [null],
        resultPricePcsNeoEvolution14: [null],
        resultPricePcsNeoEvolution15: [null],
        resultPricePcsNeoEvolution16: [null],
        resultPricePcsNeoEvolution17: [null],
        resultPricePcsNeoEvolution18: [null],
        resultPricePcsNeoEvolution19: [null],
        resultPricePcsNeoEvolution20: [null],
        resultPricePcsNeoEvolution21: [null],
        resultPricePcsNeoEvolution22: [null],
        resultPricePcsNeoEvolution23: [null],
        resultPricePcsNeoEvolution24: [null],
        resultPricePcsNeoEvolution25: [null],
        resultPricePcsNeoHwUpgrade1: [null],
        resultPricePcsNeoHwUpgrade2: [null],
        resultPricePcsNeoHwUpgrade3: [null],
        resultPricePcsNeoHwUpgrade4: [null],
        resultPricePcsNeoHwUpgrade5: [null],
        resultPricePcsNeoHwUpgrade6: [null],
        resultPricePcsNeoHwUpgrade7: [null],
        resultPricePcsNeoHwUpgrade8: [null],
        resultPricePcsNeoHwUpgrade9: [null],
        resultPricePcsNeoHwUpgrade10: [null],
        resultPricePcsNeoHwUpgrade11: [null],
        resultPricePcsNeoHwUpgrade12: [null],
        resultPricePcsNeoHwUpgrade13: [null],
        resultPricePcsNeoHwUpgrade14: [null],
        resultPricePcsNeoHwUpgrade15: [null],
        resultPricePcsNeoHwUpgrade16: [null],
        resultPricePcsNeoHwUpgrade17: [null],
        resultPricePcsNeoHwUpgrade18: [null],
        resultPricePcsNeoHwUpgrade19: [null],
        resultPricePcsNeoHwUpgrade20: [null],
        resultPricePcsNeoHwUpgrade21: [null],
        resultPricePcsNeoHwUpgrade22: [null],
        resultPricePcsNeoHwUpgrade23: [null],
        resultPricePcsNeoHwUpgrade24: [null],
        resultPricePcsNeoHwUpgrade25: [null],
        resultPricePcsNeoSwUpgrade1: [null],
        resultPricePcsNeoSwUpgrade2: [null],
        resultPricePcsNeoSwUpgrade3: [null],
        resultPricePcsNeoSwUpgrade4: [null],
        resultPricePcsNeoSwUpgrade5: [null],
        resultPricePcsNeoSwUpgrade6: [null],
        resultPricePcsNeoSwUpgrade7: [null],
        resultPricePcsNeoSwUpgrade8: [null],
        resultPricePcsNeoSwUpgrade9: [null],
        resultPricePcsNeoSwUpgrade10: [null],
        resultPricePcsNeoSwUpgrade11: [null],
        resultPricePcsNeoSwUpgrade12: [null],
        resultPricePcsNeoSwUpgrade13: [null],
        resultPricePcsNeoSwUpgrade14: [null],
        resultPricePcsNeoSwUpgrade15: [null],
        resultPricePcsNeoSwUpgrade16: [null],
        resultPricePcsNeoSwUpgrade17: [null],
        resultPricePcsNeoSwUpgrade18: [null],
        resultPricePcsNeoSwUpgrade19: [null],
        resultPricePcsNeoSwUpgrade20: [null],
        resultPricePcsNeoSwUpgrade21: [null],
        resultPricePcsNeoSwUpgrade22: [null],
        resultPricePcsNeoSwUpgrade23: [null],
        resultPricePcsNeoSwUpgrade24: [null],
        resultPricePcsNeoSwUpgrade25: [null],
        resultPriceNetworkCompReplacement1: [null],
        resultPriceNetworkCompReplacement2: [null],
        resultPriceNetworkCompReplacement3: [null],
        resultPriceNetworkCompReplacement4: [null],
        resultPriceNetworkCompReplacement5: [null],
        resultPriceNetworkCompReplacement6: [null],
        resultPriceNetworkCompReplacement7: [null],
        resultPriceNetworkCompReplacement8: [null],
        resultPriceNetworkCompReplacement9: [null],
        resultPriceNetworkCompReplacement10: [null],
        resultPriceNetworkCompReplacement11: [null],
        resultPriceNetworkCompReplacement12: [null],
        resultPriceNetworkCompReplacement13: [null],
        resultPriceNetworkCompReplacement14: [null],
        resultPriceNetworkCompReplacement15: [null],
        resultPriceNetworkCompReplacement16: [null],
        resultPriceNetworkCompReplacement17: [null],
        resultPriceNetworkCompReplacement18: [null],
        resultPriceNetworkCompReplacement19: [null],
        resultPriceNetworkCompReplacement20: [null],
        resultPriceNetworkCompReplacement21: [null],
        resultPriceNetworkCompReplacement22: [null],
        resultPriceNetworkCompReplacement23: [null],
        resultPriceNetworkCompReplacement24: [null],
        resultPriceNetworkCompReplacement25: [null],
        resultPriceIndividualServices1: [null],
        resultPriceIndividualServices2: [null],
        resultPriceIndividualServices3: [null],
        resultPriceIndividualServices4: [null],
        resultPriceIndividualServices5: [null],
        resultPriceIndividualServices6: [null],
        resultPriceIndividualServices7: [null],
        resultPriceIndividualServices8: [null],
        resultPriceIndividualServices9: [null],
        resultPriceIndividualServices10: [null],
        resultPriceIndividualServices11: [null],
        resultPriceIndividualServices12: [null],
        resultPriceIndividualServices13: [null],
        resultPriceIndividualServices14: [null],
        resultPriceIndividualServices15: [null],
        resultPriceIndividualServices16: [null],
        resultPriceIndividualServices17: [null],
        resultPriceIndividualServices18: [null],
        resultPriceIndividualServices19: [null],
        resultPriceIndividualServices20: [null],
        resultPriceIndividualServices21: [null],
        resultPriceIndividualServices22: [null],
        resultPriceIndividualServices23: [null],
        resultPriceIndividualServices24: [null],
        resultPriceIndividualServices25: [null],
        resultPriceSwUpdate1: [null],
        resultPriceSwUpdate2: [null],
        resultPriceSwUpdate3: [null],
        resultPriceSwUpdate4: [null],
        resultPriceSwUpdate5: [null],
        resultPriceSwUpdate6: [null],
        resultPriceSwUpdate7: [null],
        resultPriceSwUpdate8: [null],
        resultPriceSwUpdate9: [null],
        resultPriceSwUpdate10: [null],
        resultPriceSwUpdate11: [null],
        resultPriceSwUpdate12: [null],
        resultPriceSwUpdate13: [null],
        resultPriceSwUpdate14: [null],
        resultPriceSwUpdate15: [null],
        resultPriceSwUpdate16: [null],
        resultPriceSwUpdate17: [null],
        resultPriceSwUpdate18: [null],
        resultPriceSwUpdate19: [null],
        resultPriceSwUpdate20: [null],
        resultPriceSwUpdate21: [null],
        resultPriceSwUpdate22: [null],
        resultPriceSwUpdate23: [null],
        resultPriceSwUpdate24: [null],
        resultPriceSwUpdate25: [null]

      })
    });
  }

  ngOnInit() {
    this.readData();
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.resultOverviewForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    console.log(formData);

    this.formDataService.fillDataIntoDialog(this.resultOverviewForm, formData);

    // for (let i = 1; i <= 15; i++) {
    //   this.costOverviewData.push({
    //     'name': 'Year ' + i,
    //     'value': Number(this.resultOverviewForm.get('costOverview')?.get('jahresWert' + i)?.value) || 0
    //   });
    // }

    for (let i = 1; i <= 15; i++) {
      this.costOverviewData.push({
        'name': 'Year ' + i,
        'series': [
          {
            'name': 'Non recuring services',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultNonRecuringServices' + i)?.value, 10) || 0
          },
          {
            'name': 'LCS Standard Services',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultlcsStandardServices' + i)?.value, 10) || 0
          },
          {
            'name': 'Additional Services',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultAdditionalServices' + i)?.value, 10) || 0
          },
          {
            'name': 'LCS SUS',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultLcsSUS' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS 7 HW & SW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcs7HwSwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo License Procurement',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoLicenseProcurement' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo SWP Infinity',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoSwpInfinity' + i)?.value, 10) || 0
          },
          {
            'name': 'HMI Picture Adaption',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultHmiPictureAdaption' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo Evolution',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoEvolution' + i)?.value, 10) || 0
          },
          {
            'name': 'SW Update',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultSwUpdate' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo HW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoHwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo SW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoSwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'Network Component Replacement',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultNetworkCompReplacement' + i)?.value, 10) || 0
          },
          {
            'name': 'Individual Services',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultIndividualServices' + i)?.value, 10) || 0
          }
        ]
      });
      this.costOverviewData = [...this.costOverviewData];

      this.priceOverviewData.push({
        'name': 'Year ' + i,
        'series': [
          {
            'name': 'Non recuring services',
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceNonRecuringServices' + i)?.value, 10) || 0
          },
          {
            'name': 'LCS Standard Services',
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricelcsStandardServices' + i)?.value, 10) || 0
          },
          {
            'name': 'Additional Services',
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceAdditionalServices' + i)?.value, 10) || 0
          },
          {
            'name': 'LCS SUS',
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceLcsSUS' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS 7 HW & SW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricePcs7HwSwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo License Procurement',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricePcsNeoLicenseProcurement' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo SWP Infinity',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricePcsNeoSwpInfinity' + i)?.value, 10) || 0
          },
          {
            'name': 'HMI Picture Adaption',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceHmiPictureAdaption' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo Evolution',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricePcsNeoEvolution' + i)?.value, 10) || 0
          },
          {
            'name': 'SW Update',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceSwUpdate' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo HW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricePcsNeoHwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo SW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPricePcsNeoSwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'Network Component Replacement',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceNetworkCompReplacement' + i)?.value, 10) || 0
          },
          {
            'name': 'Individual Services',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('priceOverview')?.get('resultPriceIndividualServices' + i)?.value, 10) || 0
          }
        ]
      });
    }

    this.priceOverviewData = [...this.priceOverviewData];

  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.resultOverviewForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  openHelpModal(section: string) {
    this.modalService.show(OpenHelpDialogModalComponent, { initialState: { sectionName: section } });
  }


}
