import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-price-overview',
  templateUrl: './price-overview.component.html',
  styleUrls: ['./price-overview.component.scss']
})
export class PriceOverviewComponent implements OnInit {

  @Input()
  public parentForm!: FormGroup;

  @Input()
  priceOverviewData: {name: string; series: {name: string; value: number}[]}[] = [];
  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();



  view: [number, number] = [800, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Year of Contract';
  showYAxisLabel = true;
  yAxisLabel = 'Price in €';

  colorScheme = {
    domain: ['#00646e', '#879baa', '#50bed7', '#eb780a', '#647d2d', '#00646e', '#879baa', '#50bed7', '#eb780a', '#647d2d',
      '#00646e', '#879baa', '#50bed7', '#eb780a', '#647d2d', '#00646e']
  };


  constructor() {}

  ngOnInit(){
    console.log('OnInit');
  }

  onSelect(event: any) {
    console.log(event);
  }

}
