<si-newton-section>
    <app-navigation [currentSectionForm]="swMaintenanceForm"></app-navigation>

    <app-sw-maintenance-contract-timeline [parentForm]="swMaintenanceForm" (openHelpModal)="openHelpModal($event)"></app-sw-maintenance-contract-timeline>
    <app-sw-maintenance-cost-overview  [parentForm]="swMaintenanceForm" (openHelpModal)="openHelpModal($event)"></app-sw-maintenance-cost-overview>

    <tabset>
        <tab heading="PCS 7 SUS">
            <app-software-update-service></app-software-update-service>
        </tab>
        <tab heading="PCS 7 LSS">
            <app-lss-legacy-system-service [parentForm]="swMaintenanceForm" (openHelpModal)="openHelpModal($event)"></app-lss-legacy-system-service>
        </tab>
        <tab heading="PCS 7 SW Maint. Contract">
            <app-pcs7-sw-maintenance-contract [parentForm]="swMaintenanceForm" (openHelpModal)="openHelpModal($event)"></app-pcs7-sw-maintenance-contract>
        </tab>
        <tab heading="PCS neo">
            <app-neo-sw-proc-maint [parentForm]="swMaintenanceForm" (openHelpModal)="openHelpModal($event)"></app-neo-sw-proc-maint>
        </tab>
    </tabset>

    <div>
        <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
        [disabled]="!(swMaintenanceForm.valid)">Submit</button>
        <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
      (click)="scrollToTop()">Go to top</button>
      <button *ngIf="!(swMaintenanceForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
      (click)="logInvalidControls(swMaintenanceForm)">
        Log Invalid Values
      </button>
      </div>
</si-newton-section>