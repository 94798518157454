<form [formGroup]="parentForm">
  <div formGroupName="swUpdate">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">SW-Update</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('sw-update')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <div class="col">
          <p class="info">Software Updates are typically in year 3 / 8 / 13 and without replacement of HW</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="checkBoxSoftwareUpdate" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              Software Updates
            </label>
          </div>
        </div>
        <div class="col-md-2">

        </div>
      </div>

      <div class="row">
        <div class="evolutionOverview">

          <!-- Checkbox Sections -->
          <div class="checkbox-sections">

              <div class="checkbox-row">
                <div class="checkbox-label"></div>
                <ul style="margin-bottom: 0px;">

                  <li class="years" *ngFor="let year of yearsArray; let i = index"
                  [ngClass]=
                  "pcsNeoEvolution && year === pcsNeoEvolution ? 'pcsNeoEvolution' :
                    year >= pcsNeoEvolution && pcsNeoEvolution !== 0 ? 'pcsNeo' : 'pcs7'">
                    {{ i+1 }}
                  </li>
                </ul>
              </div>

              <div class="checkbox-row">
                <div class="checkbox-label"></div>
                <ul style="margin-bottom: 0px;">
                  <li class="year" *ngFor="let year of yearsArray">
                    {{ year }}
                  </li>
                </ul>
              </div>

              <!-- PCS 7 SW Updates -->
              <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                  <div class="checkbox-label">PCS 7 SW Updates</div>
                  <ul >
                      <li *ngFor="let year of yearsArray; let i = index">
                          <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                 *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"
                                [checked]="selectedPCS7SwUpdates[i]"
                                >
                      </li>
                  </ul>
              </div>

              <!-- PCS neo SW Update -->
              <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
                <div class="checkbox-label">PCS Neo SW Update</div>
                <ul>
                    <li *ngFor="let year of yearsArray; let i = index">
                        <input class="form-check form-check-inline custom-disabled" type="checkbox"
                              *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                              [checked]="selectedNeoSwUpdates[i]">
                    </li>
                </ul>
              </div>

          </div>
      </div>
      </div>



      <div class="row">
        <div class="col"></div>
        <div class="col" style="text-align: center; margin-bottom: 10px;">
          <button type="button" class="btn btn-success"
            (click)="editBasicUpdateParameters()">Basic Update Parameters</button>
        </div>

        <div class="col"></div>
      </div>

    </div>


    </div>

</form>

