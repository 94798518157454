<div class="container">
  <form [formGroup]="parentForm">
    <div formGroupName="hwAddons">
      <div class="subSectionHeaderWrapper">
        <div class="d-flex justify-content-between align-items-center subSectionHeader">
          <h5 class="m-0">Identified 3rd Party HW Adddons</h5>
          
          <div class="ms-auto d-flex align-items-center">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none" 
              (click)="openHelpModal.emit('identified-3rd-party-hw-addons')" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
            >
              <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
            </button>
            
  
          </div>
        </div>
      </div>


      <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Identified 3rd Party HW Addons for PCS 7</b>
          </div>
        </div>

        <div *ngIf="thirdPartyHwAddonsFromPCS7Bom.length === 0">
          <b>No 3rd Party HW Addons for PCS 7 identified</b>
        </div>

        <si-newton-table *ngIf="thirdPartyHwAddonsFromPCS7Bom && thirdPartyHwAddonsFromPCS7Bom.length > 0" [rows]="thirdPartyHwAddonsFromPCS7Bom"
              [rowsPerPage]="25">

              <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="description" name="Description" [widthFactor]="1.4" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  <textarea style="height: 100%; width: 100%">
                    {{row.description}}
                  </textarea>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.4" [disableFilter]="true">

              </siNewtonTableColumn>

              <siNewtonTableColumn key="listPrice" name="L Price" [widthFactor]="0.7" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  {{row.listPrice}} €
              </ng-template>
              </siNewtonTableColumn>

        </si-newton-table>

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Identified 3rd Party HW Addons for PCS Neo</b>
          </div>
        </div>

        <div *ngIf="thirdPartyHwAddonsFromPcsNeoBom.length === 0">
          <b>No 3rd Party HW Addons for PCS neo identified</b>
        </div>

        <si-newton-table  *ngIf="thirdPartyHwAddonsFromPcsNeoBom && thirdPartyHwAddonsFromPcsNeoBom.length > 0" [rows]="thirdPartyHwAddonsFromPcsNeoBom"
            [rowsPerPage]="25">

              <siNewtonTableColumn key="componentType" name="Component Type" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="0.9">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="description" name="Description" [widthFactor]="1.4" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  <textarea style="height: 100%; width: 100%">
                    {{row.description}}
                  </textarea>
                </ng-template>
              </siNewtonTableColumn>

              <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.4" [disableFilter]="true">
              </siNewtonTableColumn>

              <siNewtonTableColumn key="listPrice" name="L Price" [widthFactor]="0.7" [disableFilter]="true">
                <ng-template siNewtonTableCell let-value='value' let-row="row">
                  {{row.listPrice}} €
              </ng-template>
              </siNewtonTableColumn>

        </si-newton-table>

      </div>

      <div class="subSectionHeaderWrapper">
        <div class="d-flex justify-content-between align-items-center subSectionHeader">
          <h5 class="m-0">Calculation of 3rd Party HW Addons</h5>
          
          <div class="ms-auto d-flex align-items-center">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none" 
              (click)="openHelpModal.emit('calculation-of-3rd-party-hw-addons')" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
            >
              <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
            </button>
            
  
          </div>
        </div>
      </div>

      <tabset>
     
          <tab heading="{{ getHwAddonTabName(x) }}" *ngFor="let _ of [].constructor(10), let x = index">
            <div class="customCard">
              <div class="row">
    
                <div class="col-sm-3 col-md-4 col-lg-2">
                  <b class="subSubSectionHeader">HW Addon {{x+1}}</b>
                </div>
    
                <div class="col-sm-9 col-md-6 col-lg-4">
                  <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="hwAddonsDesc{{x+1}}" siNewton>
                  </si-newton-form-group>
                </div>
    
                <div class="col-md-2">
                  <div class="form-check">
                    <input id="cb-enabled" class="form-check-input" formControlName="hwAddonsPCS7Related{{x+1}}" type="checkbox">
                    <label for="cb-enabled" class="form-check-label">
                      Use in PCS 7
                    </label>
                  </div>
                </div>
    
                <div class="col-md-2">
                  <div class="form-check">
                    <input id="cb-enabled" class="form-check-input" formControlName="hwAddonsPCSneoRelated{{x+1}}" type="checkbox">
                    <label for="cb-enabled" class="form-check-label">
                      Use in PCS Neo
                    </label>
                  </div>
                </div>
              </div>
    
    
              <div class="row">
    
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                    <input formControlName="hwAddonsQty{{x+1}}" type="number" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
    
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <si-newton-form-group label="Replacement Costs" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="hwAddonsRepl{{x+1}}" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
    
    
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <si-newton-form-group label="MLFB / Order ID" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="hwAddonsMLFB{{x+1}}"  siNewton>
                  </si-newton-form-group>
                </div>
    
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <si-newton-form-group label="Adaption effort" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="hwAddonsEffort{{x+1}}" type="number" step="any" [min]="0" siNewton>
                    <div class="input-group-prepend">
                      <span class="input-group-text">[min]</span>
                    </div>
                  </si-newton-form-group>
                </div>
    
              </div>
    
              <div class="row">
                <div class="col">
                  <b class="labelAccent">Preventice Maintenance</b>
                </div>
              </div>
    
              <div class="row">
                <div class="col-sm-6 col-md-5">
                  <si-newton-form-group label="Description of measure" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="hwAddonsDescMeasure{{x+1}}" siNewton>
                  </si-newton-form-group>
                </div>
    
                <div class="col-sm-6 col-md-3">
                  <si-newton-form-group label="Maintenance effort" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="hwAddonsMaintenance{{x+1}}" type="number" step="any" [min]="0" siNewton>
                    <div class="input-group-prepend">
                      <span class="input-group-text">[min]</span>
                    </div>
                  </si-newton-form-group>
                </div>
                <div class="col-sm-4 col-md-4">
                  <si-newton-form-group label="Comment" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="hwAddonsComment{{x+1}}" siNewton>
                  </si-newton-form-group>
                </div>
    
              </div>
            </div>
          </tab>
 

      </tabset>


    


    </div>
  </form>
</div>
