<form [formGroup]="parentForm">
  <div formGroupName="contractualParameters">
    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Contractual Parameters</h5>
        <button 
          type="button" 
          class="btn btn-link p-0 text-decoration-none" 
          (click)="openHelpDialog()" 
          data-bs-toggle="modal" 
          data-bs-target="#helpModal" 
          aria-label="Help"
        >
          <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Start with PCS 7 / neo" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('startWithPcs7OrPcsNeo')"
            [valueProvider]="getDropdownValue"
            formControlName="startWithPcs7OrPcsNeo"
            (optionSelected)="checkContractTimelinePlausibility()"
            ></si-newton-dropdown>
        </si-newton-form-group>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="LCS Contract Duration" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('lcsContractDuration')"
            [valueProvider]="getDropdownValue"
            formControlName="lcsContractDuration"></si-newton-dropdown>
        </si-newton-form-group>
      </div>


      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Contract Start" [errorResolverMap]="{required: 'Value is required'}">
          <input readOnly formControlName="yearOfStartFix" siNewton #lcsStart>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row" [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'block'">
      <div class="col">
        <p class="info">Please select year of PCS Neo Evolution if needed</p>
      </div>
    </div>

    <div class="row">
      <div class="evolutionOverview">

          <!-- Checkbox Sections -->
          <div class="checkbox-sections">

            <!---->
            <div class="checkbox-row" [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
              <div class="checkbox-label"></div>
              <!-- Radio buttons section -->
              <ul style="margin-bottom: 0px;">
                <li style="width: 100%;  margin-left: 0.9em; line-height: 2em; display: flex">
                    <input class="form-check form-check-inline" formControlName="pcsNeoEvolution" [(ngModel)]="pcsNeoEvolution"
                          type="radio" name="pcsNeoEvolution"
                          [value]="0" (change)="yearOfEvolutionChanged($event)">
                    <label>No Evolution required</label>
                </li>
              </ul>
            </div>

            <!---->
            <div class="checkbox-row" [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
              <div class="checkbox-label"></div>
              <!-- Years -->
              <ul style="margin-bottom: 0px; margin-left: 0.2em">
                <li *ngFor="let year of yearsArray; let i = index">
                    <input class="form-check form-check-inline"  formControlName="pcsNeoEvolution" [(ngModel)]="pcsNeoEvolution"
                          name="pcsNeoEvolution"
                          type="radio" (change)="yearOfEvolutionChanged($event)"
                          [value]="year">
                </li>
              </ul>
            </div>

            <!---->
            <div class="checkbox-row">
              <div class="checkbox-label"></div>
              <ul style="margin-bottom: 0px;">

                <li class="years" *ngFor="let year of yearsArray; let i = index"
                [ngClass]=
                "pcsNeoEvolution && year === pcsNeoEvolution ? 'pcsNeoEvolution' :
                  year >= pcsNeoEvolution && pcsNeoEvolution !== 0 ? 'pcsNeo' : 'pcs7'">
                  {{ i+1 }}
                </li>
              </ul>
            </div>
          
            <!---->
            <div class="checkbox-row">
              <div class="checkbox-label"></div>
              <ul style="margin-bottom: 0px;">
                <li class="year" *ngFor="let year of yearsArray">
                  {{ year }}
                </li>
              </ul>
            </div>

            <!-- PCS 7 Upgrades -->
            <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
              [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                <div class="checkbox-label">PCS 7 Upgrades</div>
                <ul>
                    <li *ngFor="let year of yearsArray; let i = index">
                        <input class="form-check form-check-inline" type="checkbox" style="margin-top: 5px"
                                *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"
                              [checked]="selectedPcs7Upgrades[i]"
                              (change)="onCheckboxChange($event, 'pcs7Upgrade', i)">
                    </li>
                </ul>
            </div>

            <!-- PCS 7 SW Updates -->
            <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
              [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                <div class="checkbox-label">PCS 7 SW Updates</div>
                <ul >
                    <li *ngFor="let year of yearsArray; let i = index">
                        <input class="form-check form-check-inline" type="checkbox"
                                *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"
                              [checked]="selectedPCS7SwUpdates[i]"
                              (change)="onCheckboxChange($event, 'pcs7SwUpdate', i)">
                    </li>
                </ul>
            </div>

            <!-- PCS neo Evolution -->
            <div class="checkbox-row" style="background-color: rgba(235, 120, 10, 0.7); color: white"
              [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
              <div class="checkbox-label">PCS neo Evolution</div>
              <ul>
                  <li *ngFor="let year of yearsArray; let i = index">
                      <input class="form-check form-check-inline" type="checkbox"
                        *ngIf="pcsNeoEvolution !== 0 && year === pcsNeoEvolution"
                        [checked]="true" disabled>
                  </li>
              </ul>
            </div>

            <!-- PCS neo HW Upgrade -->
            <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
              <div class="checkbox-label">PCS Neo HW Upgrades</div>
              <ul>
                  <li *ngFor="let year of yearsArray; let i = index">
                      <input class="form-check form-check-inline" type="checkbox"
                            *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                            [checked]="selectedNeoHWUpgrades[i]"
                            (change)="onCheckboxChange($event, 'pcsNeoHwUpgrade', i)">
                  </li>
              </ul>
            </div>

            <!-- PCS neo SW Upgrade -->
            <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
              <div class="checkbox-label">PCS Neo SW Upgrades</div>
              <ul>
                  <li *ngFor="let year of yearsArray; let i = index">
                      <input class="form-check form-check-inline" type="checkbox"
                            *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                            [checked]="selectedNeoSWUpgrades[i]"
                            (change)="onCheckboxChange($event, 'pcsNeoSwUpgrade', i)">
                  </li>
              </ul>
            </div>

            <!-- PCS neo SW Update -->
            <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
              <div class="checkbox-label">PCS Neo SW Update</div>
              <ul>
                  <li *ngFor="let year of yearsArray; let i = index">
                      <input class="form-check form-check-inline" type="checkbox"
                            *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                            [checked]="selectedNeoSwUpdates[i]"
                            (change)="onCheckboxChange($event, 'pcsNeoSwUpdate', i)">
                  </li>
              </ul>
            </div>

          </div>
      </div>
    </div>


  </div>
</form>

