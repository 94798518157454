<form [formGroup]="parentForm">
  <div formGroupName="individualServices">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Individual Services</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('individual-services')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Total Cost Overview of Individual Services</b>
        </div>
      </div>

      <div class="row" *ngFor="let _ of [].constructor(20), let x = index">
        <div class="col-md-2">
          <si-newton-form-group label="Service Name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="disServiceService{{('00'+(x+1)).slice(-2)}}" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-md-2">
          <si-newton-form-group label="Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="materialsYearTotalService{{('00'+(x+1)).slice(-2)}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>

        </div>

        <div class="col-md-2">
          <si-newton-form-group label="Service Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="servicesYearTotalService{{('00'+(x+1)).slice(-2)}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>

        </div>

       

        <div class="col-md-2">
          <si-newton-form-group label="Total Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="totalCostYearTotalService{{('00'+(x+1)).slice(-2)}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-md-2">
          <button
          title="Edit Individual Service"
          class="btn btn-success"
          style="float: left; margin: 1px; border: 0px;"
          (click)="editSelectedIndividualService(('00'+(x+1)).slice(-2))"
          >
          <i class="bi bi-calculator-fill"></i>
        </button>
        </div>
      </div>
    </div>

  </div>
</form>
