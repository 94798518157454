<form [formGroup]="parentForm">
  <div formGroupName="managedSystemServices">

    <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Managed System Services</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('managed-system-services')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    <div [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <!-- 4 Standard Offering Services-->
      <div class="row">
        <b class="subSubSectionHeader">Standard Offering</b>
      </div>

      <div *ngFor="let _ of [].constructor(4); let i = index">

        <input formControlName="managedSystemService{{i+1}}Name" hidden #mssName>
        <input formControlName="managedSystemService{{i+1}}Mlfb" hidden #mssMlfb>
        <input formControlName="managedSystemService{{i+1}}Description" hidden #mssDescription>


        <div class="customCard">
          <div class="row">
            <div class="col-12">
              <p><b>{{mssName.value}}</b></p>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-9">
              <p><b>MLFB</b></p>
              {{mssMlfb.value}}
            </div>
            <div class="col-xs-12 col-md-3">
              <b>Selected</b>
              <div class="form-check">
                <input id="cb-enabled" formControlName="managedSystemService{{i+1}}IsSelected" type="checkbox">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-9">
              <p><b>Description</b></p>
              {{mssDescription.value}}
            </div>
            <div class="col-xs-12 col-md-3">
              <si-newton-form-group label="Price" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="managedSystemService{{i+1}}CostInEuro" type="number" [min]="0" step="any"
                  siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
              </si-newton-form-group>
            </div>

          </div>

          <div
            *ngIf="+parentForm.get('managedSystemServices')?.get('managedSystemService' + (i+1) + 'IsSelected')?.value === 1"
            style="margin-bottom: 50px;">
            <div *ngIf="i === 0 || i === 1">
              <div class="row">
                <p class="info">Please select / deselect the Years where services are required</p>
              </div>
              <div class="row" style="margin-left: 15px;">
                <div class="col" *ngFor="let _ of [].constructor(25); let j = index">

                  <div *ngIf="(j+1) <= stringToNumber(getLcsContractDuration())">
                    <div class="row year">{{stringToNumber(yearOfStartFix.value) + j}}</div>
                    <div class="row checkBox">
                      <div class="form-check">
                        <input id="cb-enabled" formControlName="managedSystemService{{i+1}}Year{{j+1}}Selected"
                          type="checkbox">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div *ngIf="i === 2 || i === 3">
              <div class="row">
                <p class="info">This service is a one time service</p>
              </div>
            </div>
          </div>


        </div>
      </div>


      <!-- Individual Offering Services-->
      <div class="row">
        <b class="subSubSectionHeader">Individual Offering</b>
      </div>

      <div class="row">
        <p class="info">You can add up to 2 individual offerings if needed</p>
      </div>

      <div class="row">
        <div class="col-3">
          <si-newton-form-group label="Risk Addition"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input formControlName="managedSystemServiceRiskAddition" siNewton type="number" step="any" [min]="0">
          </si-newton-form-group>
        </div>
        <div class="col">
          <p class="info">Risk Addition does impact for all services without mlfb</p>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3 col-sm-4 col-xs-6">
          <si-newton-form-group label="Name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newMssName" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-md-3 col-sm-4 col-xs-6">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newMssDescription" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-md-2 col-sm-4 col-xs-6">
          <si-newton-form-group label="Cost"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="newMssCost" siNewton type="number" [min]="0">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-md-2 col-sm-4 col-xs-6">
          <si-newton-form-group label="Cost Inc. Rate"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="newMssCostIncreaseRate" siNewton type="number" [min]="0">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>

        </div>


      </div>

      <div class="row" style="margin-bottom: 10px;">
        <div class="col-md-9">

        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-success" style="float: right;" [disabled]="!isNewOfferingValid()"
            (click)="updateRemoteServiceList()">Add new Offering</button>
        </div>
      </div>

      <div *ngFor="let indService of allIndividualMss">

        <div class="customCard">
          <div class="row">
            <div class="col-12">
              <p><b>{{indService.name}}</b></p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p><b>Description</b></p>
              {{indService.description}}
            </div>
          </div>
          <div class="row justify-content-end">

            <div class="col-md-2">
              <si-newton-form-group label="Cost Inc. Rate" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="managedSystemIndService{{indService.serviceNumber}}CostIncReate" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
                </div>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Price" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="managedSystemIndService{{indService.serviceNumber}}CostInEuro" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <button title="Delete Service" class="btn-danger" (click)="deleteService(indService.serviceNumber)">
                <i class="bi bi-trash"></i>
              </button>
            </div>

          </div>

          <div class="row">
            <p class="info">Please select / deselect the Years where services are required</p>
          </div>

          <div class="row" style="margin-left: 15px;">
            <div class="col" *ngFor="let _ of [].constructor(25); let j = index">

              <div *ngIf="(j+1) <= stringToNumber(getLcsContractDuration())">
                <div class="row year">{{stringToNumber(yearOfStartFix.value) + j}}</div>
                <div class="row checkBox">
                  <div class="form-check">
                    <input id="cb-enabled"
                      formControlName="managedSystemIndService{{indService.serviceNumber}}Year{{j+1}}Selected"
                      type="checkbox">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

      <div class="row justify-content-end" style="margin-bottom: 10px;">

        <div class="col">
          <button type="button" class="btn btn-success"
        (click)="getPricesForAllMlfbs()" style="float: right">Update List Prices</button>
        </div>
      </div>
    
    </div>
  </div>
</form>