import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pcs7-sw-maintenance-contract',
  templateUrl: './pcs7-sw-maintenance-contract.component.html',
  styleUrls: ['./pcs7-sw-maintenance-contract.component.scss']
})
export class Pcs7SwMaintenanceContractComponent implements OnInit {
  @Input()
  public parentForm!: FormGroup;
  
  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

}
