import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-open-help-dialog-modal',
  templateUrl: './open-help-dialog-modal.component.html',
  styleUrls: ['./open-help-dialog-modal.component.scss']
})
export class OpenHelpDialogModalComponent implements OnInit {
  sectionName!: string;

  helpText!: string;
  isEditing: boolean = false; 
  editedText: string = ''; 

  constructor(public modalRef: BsModalRef, private httpService: HttpClient) { 
    
  }

  ngOnInit(): void {
    this.getHelpText();
  }

  async getHelpText() {

    const endpoint = `${environment.baseUrl}/help-texts/get-help-text/${this.sectionName}`;

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    const result = await this.httpService.get<{ text: string }>(endpoint, options).toPromise();

    this.helpText = result.text;
  }

  async saveHelpText() {

    const endpoint = `${environment.baseUrl}/help-texts/upsert-help-text`;
    const body = {
      section: this.sectionName,
      text: this.editedText
    };

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    const result = await this.httpService.post(endpoint, body, options).toPromise();

    console.log('Help text saved:', result);
  }

   // Bearbeitungsmodus aktivieren
   enableEdit(): void {
    this.isEditing = true;
    this.editedText = this.helpText; // Aktuellen Text in die Zwischenvariable laden
  }

  // Bearbeitungsmodus speichern
  async saveEdit() {
      await this.saveHelpText();
      this.isEditing = false; 
      await this.getHelpText();
  }

  // Bearbeitung abbrechen
  cancelEdit(): void {
      this.isEditing = false; // Änderungen verwerfen
      this.editedText = ''; // Zwischenvariable zurücksetzen
  }

}
