<form [formGroup]="parentForm">
  <div formGroupName="ivmForm">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">IVM</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('ivm')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>
    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="IVM Platform" [errorResolverMap]="{required: 'Please select the platform'}">
            <si-newton-dropdown
              [dropdownOptions]="getDropdownOptions('ivmPlatform')"
              [valueProvider]="getDropdownValue"
              formControlName="ivmPlatform"></si-newton-dropdown>
          </si-newton-form-group>

        </div>

      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <si-newton-form-group label="Avg. Price increase rate" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmPriceIncreaseRate" type="number" step="any" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">% / year</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmMargin" type="number" step="any" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <si-newton-form-group label="LCS Discount" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmLcsDiscount" type="number" step="any" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

      </div>

      <div class="row">
        <div class="col-md-4 col-lg-3 col-sm-6 col-xs-12">
          <si-newton-form-group label="No. of Packages"
            [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="ivmAmountOfPackages" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-md-8 col-lg-6 col-sm-6 col-xs-12">
            <p class="info">Please select amount of packages. One package includes 100 components</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="IVM based on" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmSelectedPlatform" siNewton readonly highlight>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmSelectedPlatformMlfb" siNewton readonly highlight>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="G Price" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmSelectedPlatformGPrice" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€ *</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Calculated Price" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="ivmCalculatedGPrice" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="info">* G-Price for IVM platform is on demand</p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">IVM Costs</b>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="info">Please select in which years IVM is requested</p>
        </div>
      </div>

      <div class="row" style="width: 95%; margin: auto; padding-bottom: 10px;">
        <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>
        <input type="number" formControlName="ivmCostYear1" hidden #ivmCostYear1>
        <input type="number" formControlName="ivmCostYear2" hidden #ivmCostYear2>
        <input type="number" formControlName="ivmCostYear3" hidden #ivmCostYear3>
        <input type="number" formControlName="ivmCostYear4" hidden #ivmCostYear4>
        <input type="number" formControlName="ivmCostYear5" hidden #ivmCostYear5>
        <input type="number" formControlName="ivmCostYear6" hidden #ivmCostYear6>
        <input type="number" formControlName="ivmCostYear7" hidden #ivmCostYear7>
        <input type="number" formControlName="ivmCostYear8" hidden #ivmCostYear8>
        <input type="number" formControlName="ivmCostYear9" hidden #ivmCostYear9>
        <input type="number" formControlName="ivmCostYear10" hidden #ivmCostYear10>
        <input type="number" formControlName="ivmCostYear11" hidden #ivmCostYear11>
        <input type="number" formControlName="ivmCostYear12" hidden #ivmCostYear12>
        <input type="number" formControlName="ivmCostYear13" hidden #ivmCostYear13>
        <input type="number" formControlName="ivmCostYear14" hidden #ivmCostYear14>
        <input type="number" formControlName="ivmCostYear15" hidden #ivmCostYear15>

        <input type="number" formControlName="lcsContractDuration" hidden #lcsContractDuration>

        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value)}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear1" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear1.value)}} €</div>
        </div>

        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 1}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear2" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear2.value)}} €</div>
        </div>

        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 2}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear3" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear3.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 3}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear4" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear4.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 4}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear5" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear5.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 5}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear6" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear6.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 6}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear7" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear7.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 7}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear8" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear8.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 8}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear9" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear9.value)}} €</div>
        </div>

        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 9}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear10" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear10.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 10}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear11" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear11.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 11}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear12" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear12.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 12}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear13" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear13.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 13}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear14" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear14.value)}} €</div>
        </div>
        <div class="col">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + 14}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="ivmSelectedYear15" type="checkbox">
            </div>
          </div>
          <div class="row ivmCost">{{stringToNumber(ivmCostYear15.value)}} €</div>
        </div>

      </div>

    </div>

  </div>
</form>
