<form [formGroup]="parentForm">
  <div formGroupName="costFactorsInternal">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Cost Factors</h5>
        <button 
          type="button" 
          class="btn btn-link p-0 text-decoration-none" 
          (click)="openHelpDialog()" 
          data-bs-toggle="modal" 
          data-bs-target="#helpModal" 
          aria-label="Help"
        >
          <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Service Cost for plannable measures onsite" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€ / h</span>
          </div>
          <input formControlName="serviceCostFactor" type="number" step="any" [min]="0"siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Engineering Cost (inhouse)" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€ / h</span>
          </div>
          <input formControlName="engineeringCostInhouse" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

  </div>
</form>
