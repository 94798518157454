import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BasicUpdateParametersComponent } from '../../modals/basic-update-parameters/basic-update-parameters.component';

@Component({
  selector: 'app-sw-update',
  templateUrl: './sw-update.component.html',
  styleUrls: ['./sw-update.component.scss']
})
export class SwUpdateComponent implements AfterViewInit {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();



  modalRef!: BsModalRef;
  yearsArray!: number[];
  pcsNeoEvolution!: number;

  startWithPcs7OrPcsneo!: string;
  lcsStartYear!: number;
  lcsContractDuration!: number;

  selectedPCS7SwUpdates: boolean[] = [];
  selectedNeoSwUpdates: boolean[] = [];


  constructor(private modalService: BsModalService) { }

  public editBasicUpdateParameters(){

    const initialState = {};

    this.modalRef = this.modalService.show(BasicUpdateParametersComponent, { initialState, backdrop: 'static' });
    this.modalRef.content.closeBtnName = 'Close';
  }

  async ngAfterViewInit(){
    this.pcsNeoEvolution = this.parentForm.get('swUpdate')?.get('pcsNeoEvolution')?.value;

    await this.updateContractTime();

    this.fillSelectedYears('pcs7SwUpdate', this.lcsContractDuration);
    this.fillSelectedYears('pcsNeoSwUpdate', this.lcsContractDuration);

  }

  async updateContractTime(){
    this.lcsStartYear = Number(this.parentForm.get('swUpdate')?.get('yearOfStartFix')?.value);
    this.lcsContractDuration = Number(this.parentForm.get('swUpdate')?.get('lcsContractDuration')?.value.value);
    this.yearsArray = Array.from({
      length: this.lcsContractDuration
    }, (_, i) => Number(this.lcsStartYear) + i);

    this.pcsNeoEvolution = Number(this.parentForm.get('swUpdate')?.get('pcsNeoEvolution')?.value);
    this.startWithPcs7OrPcsneo = this.parentForm?.get('swUpdate')?.get('startWithPcs7OrPcsNeo')?.value?.value;


  }

  fillSelectedYears(varBaseName: string, amountOfPossibleSelectedYears: number){

    const tempIndices = [];

    for (let i = 0; i <= amountOfPossibleSelectedYears; i++){
      const yearValue = Number(this.parentForm.get('swUpdate')?.get(varBaseName + 'Year' + (i + 1))?.value);

      if (!isNaN(yearValue)) {
        const index = this.yearsArray.indexOf(yearValue);
        tempIndices.push(index);
      }
    }


    // Check if years are valid regarding PCS neo Evolution year. Only then checkboxes are selected
    switch (varBaseName) {
      case 'pcs7SwUpdate':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution == 0){
            this.selectedPCS7SwUpdates[index] = true;
          } else {
            this.selectedPCS7SwUpdates[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpdate':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution){
            this.selectedNeoSwUpdates[index] = true;
          } else {
            this.selectedNeoSwUpdates[index] = false;
          }
        });
        break;
    }


  }

}
