import { environment as env } from '../environments/environment';

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { SiNewtonBreadcrumbModule } from '@simpl/newton-ng/breadcrumb';
import { SiNewtonFooterModule } from '@simpl/newton-ng/footer';
import { SiNewtonHeader2Module } from '@simpl/newton-ng/header2';
import { SiNewtonHeaderImageModule } from '@simpl/newton-ng/header-image';
import { SiNewtonLocalizationNgModule } from '@simpl/newton-localize-with-angular';
import { SiNewtonSectionModule } from '@simpl/newton-ng/section';
import { SiNewtonTextModule } from '@simpl/newton-ng/text';
import { SiNewtonTableModule } from '@simpl/newton-ng/table';
import { SiNewtonFormGroupModule } from '@simpl/newton-ng/form-group';
import { SiNewtonDropdownModule } from '@simpl/newton-ng/dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SiNewtonToastService } from '@simpl/newton-ng/toast';
import { NavigationComponent } from './navigation/navigation.component';
import { RegionalFactorsComponent } from './sections/regional-factors/regional-factors.component';
import { GeneralInformationComponent } from './sections/general-information/general-information.component';
import { GeneralProjectParameterComponent }
  from './sections/general-information/sub-sections/general-project-parameter/general-project-parameter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractualParametersComponent } from
  './sections/general-information/sub-sections/contractual-parameters/contractual-parameters.component';
import { CostFactorsComponent } from './sections/regional-factors/sub-sections/cost-factors/cost-factors.component';
import { RegionalDiscountsComponent } from './sections/regional-factors/sub-sections/regional-discounts/regional-discounts.component';
import { RiskFactorsComponent } from './sections/regional-factors/sub-sections/risk-factors/risk-factors.component';
import { ServiceParametersComponent } from './sections/regional-factors/sub-sections/service-parameters/service-parameters.component';
import { CostIncreaseRatesComponent } from './sections/regional-factors/sub-sections/cost-increase-rates/cost-increase-rates.component';
import { ExchangeFactorsComponent } from './sections/regional-factors/sub-sections/exchange-factors/exchange-factors.component';
import { PricingFactorsComponent } from './sections/regional-factors/sub-sections/pricing-factors/pricing-factors.component';
import { DefineServicesComponent } from './sections/define-services/define-services.component';
import { OneTimeServicesComponent } from './sections/define-services/sub-sections/one-time-services/one-time-services.component';
import { InformationServiceComponent } from './sections/define-services/sub-sections/information-service/information-service.component';
import { ContractManagementComponent } from './sections/define-services/sub-sections/contract-management/contract-management.component';
import { ProductServiceAndSupportComponent } from
  './sections/define-services/sub-sections/product-service-and-support/product-service-and-support.component';
import { OnCallServiceControlCenterComponent } from
  './sections/define-services/sub-sections/on-call-service-control-center/on-call-service-control-center.component';
import { PreventiveMaintenanceComponent } from
  './sections/define-services/sub-sections/preventive-maintenance/preventive-maintenance.component';
import { CorrectiveMaintenanceComponent } from
  './sections/define-services/sub-sections/corrective-maintenance/corrective-maintenance.component';
import { ExtendedSupportComponent } from
  './sections/define-services/sub-sections/extended-support/extended-support.component';
import { MaintenanceParametersComponent } from
  './sections/define-services/modals/maintenance-parameters/maintenance-parameters.component';
import { SivaasSystemServicesComponent }
  from './sections/define-services/sub-sections/sivaas-system-services/sivaas-system-services.component';
import { AdaptionHmiRuntimeComponent } from
  './sections/define-services/sub-sections/adaption-hmi-runtime/adaption-hmi-runtime.component';
import { SwUpdateComponent } from
  './sections/define-services/sub-sections/sw-update/sw-update.component';
import { BasicUpdateParametersComponent } from
  './sections/define-services/modals/basic-update-parameters/basic-update-parameters.component';
import { DefineIndividualServicesComponent } from
  './sections/define-services/sub-sections/define-individual-services/define-individual-services.component';
import { ExtentedAvailablityComponent } from
  './sections/define-services/sub-sections/extented-availablity/extented-availablity.component';
import { IndivdualServiceComponent } from './sections/define-services/modals/indivdual-service/indivdual-service.component';
import { DefineConfigurationComponent } from './sections/define-configuration/define-configuration.component';
import { GeneralConfigurationComponent } from
  './sections/define-configuration/sub-sections/general-configuration/general-configuration.component';
import { PlantTopologyComponent } from './sections/define-configuration/sub-sections/plant-topology/plant-topology.component';
import { SivaasSystemConfigurationComponent } from
  './sections/define-configuration/sub-sections/sivaas-system-configuration/sivaas-system-configuration.component';
import { SwEngineeringComponent } from './sections/define-configuration/sub-sections/sw-engineering/sw-engineering.component';
import { PhysicalSystemComponent } from './sections/define-configuration/sub-sections/physical-system/physical-system.component';
import { ResultOverviewComponent } from './sections/result-overview/result-overview.component';
import { CostOverviewComponent } from './sections/result-overview/sub-sections/cost-overview/cost-overview.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HwSwAddonsComponent } from './sections/hw-sw-addons/hw-sw-addons.component';
import { SwAddonsComponent } from './sections/hw-sw-addons/sub-sections/sw-addons/sw-addons.component';
import { HwAddonsComponent } from './sections/hw-sw-addons/sub-sections/hw-addons/hw-addons.component';
import { ResultFileComponent } from './sections/result-overview/sub-sections/result-file/result-file.component';
import { ProjectAssignmentComponent } from './project-assignment/project-assignment.component';
import { ProjectDeletionModalComponent } from './project-management/project-deletion-modal/project-deletion-modal.component';
import { ShowDisclaimerModalComponent } from './project-management/show-disclaimer-modal/show-disclaimer-modal.component';
import { SivaasSystemComponent } from './sections/define-configuration/sub-sections/sivaas-system/sivaas-system.component';
import { UploadProjectModalComponent } from './project-management/upload-project-modal/upload-project-modal.component';
import { ImportSivaasConfigModalComponent } from
  './sections/define-configuration/modals/import-sivaas-config-modal/import-sivaas-config-modal.component';
import { IvmComponent } from './sections/define-services/sub-sections/ivm/ivm.component';
import { MvdiComponent } from './sections/define-services/sub-sections/mvdi/mvdi.component';
import { ProjectUnassignmentComponent } from './project-unassignment/project-unassignment.component';
import { RejectProjectAssignmentComponent } from './reject-project-assignment/reject-project-assignment.component';
import { LeaveWithoutSaveModalComponent } from './leave-without-save-modal/leave-without-save-modal.component';
import { IadcComponent } from './sections/iadc/iadc.component';
import { IadcUsvComponent } from './sections/iadc/sub-sections/iadc-usv/iadc-usv.component';
import { OtSwitchComponent } from './sections/iadc/sub-sections/ot-switch/ot-switch.component';
import { ItSwitchComponent } from './sections/iadc/sub-sections/it-switch/it-switch.component';
import { PhServerComponent } from './sections/iadc/sub-sections/ph-server/ph-server.component';
import { BackupRestoreComponent } from './sections/iadc/sub-sections/backup-restore/backup-restore.component';
import { ExchangeComponentModalComponent } from
  './sections/define-services/modals/exchange-component-modal/exchange-component-modal.component';
import { SystemOverviewComponent } from
  './sections/define-configuration/sub-sections/system-overview/system-overview.component';
import { RemoteServiceModulesComponent } from
  './sections/define-services/sub-sections/remote-service-modules/remote-service-modules.component';
import { ManagedSystemServicesComponent } from
  './sections/define-services/sub-sections/managed-system-services/managed-system-services.component';
import { HomeComponent } from './home/home.component';
import { UserManagementService } from './services/user-management.service';
import { SwMaintenanceComponent } from './sections/sw-maintenance/sw-maintenance.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { SoftwareUpdateServiceComponent } from './sections/sw-maintenance/sub-sections/software-update-service/software-update-service.component';
import { PcsSoftwareUpdateServiceComponent } from './sections/sw-maintenance/sub-sections/software-update-service/sub-sections/pcs-software-update-service/pcs-software-update-service.component';
import { SelectedLicensesComponent } from './sections/sw-maintenance/sub-sections/software-update-service/sub-sections/selected-licenses/selected-licenses.component';
import { SusTypeComponent } from './sections/sw-maintenance/sub-sections/software-update-service/sub-sections/sus-type/sus-type.component';
import { UpgradePackagesComponent } from './sections/sw-maintenance/sub-sections/software-update-service/sub-sections/upgrade-packages/upgrade-packages.component';
import { SystemUpgradesComponent } from './sections/define-services/sub-sections/system-upgrades/system-upgrades.component';
import { NeoSwProcMaintComponent } from './sections/sw-maintenance/sub-sections/neo-sw-proc-maint/neo-sw-proc-maint.component';
import { Pcs7SwMaintenanceContractComponent } from './sections/sw-maintenance/sub-sections/pcs7-sw-maintenance-contract/pcs7-sw-maintenance-contract.component';
import { ServiceTimelineComponent } from './shared-sections/service-timeline/service-timeline.component';
import { SwMaintenanceContractTimelineComponent } from './sections/sw-maintenance/sub-sections/sw-maintenance-contract-timeline/sw-maintenance-contract-timeline.component';
import { IadcFirewallsComponent } from './sections/iadc/sub-sections/iadc-firewalls/iadc-firewalls.component';
import { SwMaintenanceCostOverviewComponent } from './sections/sw-maintenance/sub-sections/sw-maintenance-cost-overview/sw-maintenance-cost-overview.component';
import { LssLegacySystemServiceComponent } from './sections/sw-maintenance/sub-sections/lss-legacy-system-service/lss-legacy-system-service.component';
import { PriceOverviewComponent } from './sections/result-overview/sub-sections/price-overview/price-overview.component';
import { SaveRegionalFactorsComponent } from './sections/regional-factors/sub-sections/save-regional-factors/save-regional-factors.component';
import { ImportCalculationDataModalComponent } from './modals/import-calculation-data-modal/import-calculation-data-modal.component';
import { OpenHelpDialogModalComponent } from './modals/open-help-dialog-modal/open-help-dialog-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    ProjectListComponent,
    NavigationComponent,
    RegionalFactorsComponent,
    GeneralInformationComponent,
    GeneralProjectParameterComponent,
    ContractualParametersComponent,
    CostFactorsComponent,
    RegionalDiscountsComponent,
    RiskFactorsComponent,
    ServiceParametersComponent,
    CostIncreaseRatesComponent,
    ExchangeFactorsComponent,
    PricingFactorsComponent,
    DefineServicesComponent,
    SystemUpgradesComponent,
    OneTimeServicesComponent,
    InformationServiceComponent,
    ContractManagementComponent,
    ProductServiceAndSupportComponent,
    OnCallServiceControlCenterComponent,
    PreventiveMaintenanceComponent,
    CorrectiveMaintenanceComponent,
    ExtendedSupportComponent,
    MaintenanceParametersComponent,
    SivaasSystemServicesComponent,
    AdaptionHmiRuntimeComponent,
    SwUpdateComponent,
    BasicUpdateParametersComponent,
    DefineIndividualServicesComponent,
    ExtentedAvailablityComponent,
    IndivdualServiceComponent,
    DefineConfigurationComponent,
    GeneralConfigurationComponent,
    PlantTopologyComponent,
    SivaasSystemConfigurationComponent,
    SwEngineeringComponent,
    PhysicalSystemComponent,
    SoftwareUpdateServiceComponent,
    SusTypeComponent,
    PcsSoftwareUpdateServiceComponent,
    SelectedLicensesComponent,
    UpgradePackagesComponent,
    ResultOverviewComponent,
    CostOverviewComponent,
    HwSwAddonsComponent,
    SwAddonsComponent,
    HwAddonsComponent,
    ResultFileComponent,
    ProjectAssignmentComponent,
    ProjectDeletionModalComponent,
    ShowDisclaimerModalComponent,
    SivaasSystemComponent,
    UploadProjectModalComponent,
    ImportSivaasConfigModalComponent,
    IvmComponent,
    MvdiComponent,
    ProjectUnassignmentComponent,
    RejectProjectAssignmentComponent,
    LeaveWithoutSaveModalComponent,
    IadcComponent,
    IadcUsvComponent,
    OtSwitchComponent,
    ItSwitchComponent,
    PhServerComponent,
    BackupRestoreComponent,
    ExchangeComponentModalComponent,
    AdminViewComponent,
    SystemOverviewComponent,
    RemoteServiceModulesComponent,
    ManagedSystemServicesComponent,
    HomeComponent,
    SwMaintenanceComponent,
    NeoSwProcMaintComponent,
    Pcs7SwMaintenanceContractComponent,
    ServiceTimelineComponent,
    SwMaintenanceContractTimelineComponent,
    IadcFirewallsComponent,
    SwMaintenanceCostOverviewComponent,
    LssLegacySystemServiceComponent,
    PriceOverviewComponent,
    SaveRegionalFactorsComponent,
    ImportCalculationDataModalComponent,
    OpenHelpDialogModalComponent
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    HttpClientModule,
    //Auth0
    AuthModule.forRoot({
    ...env.auth,
    httpInterceptor: {
      ...env.httpInterceptor,
    }}),
    // SiMPL Peers
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    // SiMPL
    SiNewtonBreadcrumbModule,
    SiNewtonFooterModule,
    SiNewtonHeaderImageModule,
    SiNewtonHeader2Module,
    SiNewtonLocalizationNgModule,
    SiNewtonSectionModule,
    SiNewtonTextModule,
    SiNewtonTableModule,
    SiNewtonFormGroupModule,
    SiNewtonDropdownModule,
    // Application local
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxChartsModule
  ],
  providers: [HttpClientModule, SiNewtonToastService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  }, UserManagementService, BsModalRef],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
