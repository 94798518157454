<form [formGroup]="parentForm">
    <div formGroupName="iadcFirewalls">

      <input formControlName="lcsContractLastYear" hidden #lcsContractLastYear>
      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>
      <input type="number" formControlName="lcsContractDuration" hidden #lcsContractDuration>

        <div class="row">
            <div class="col">
              <h5 class="subSectionHeader">Identified Products & Service Contracts
                <i style="float: right; cursor: pointer" [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
                (click)="toggleVisibility()"></i>
              </h5>
            </div>
          </div>

        <p class="info">
          You can find all identified Products and Service Contracts for IADC Firewall in this section. Please expand the section if you want to 
          doublecheck which products are really relevant for your IADC Firewalls.
        </p>
        <div *ngIf="isThisSectionVisible">
          <div  *ngFor="let _ of [].constructor(15), let x = index">
            <div class="customCard" *ngIf="parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductName' + (x+1))?.value">
              <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 ">
                  <p><b>Product</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductName' + (x+1))?.value}}
                  
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>MLFB</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductMlfb' + (x+1))?.value}}
                </div>
            
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>List Price</b></p>
                    {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductListPrice' + (x+1))?.value}}
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>Quantity</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductQuantity' + (x+1))?.value}}
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>Relevant for IADC</b></p>
                  
                  <div class="form-check">
                    <input id="cb-enabled" formControlName="iadcFirewallsProductRelevantForIADC{{x+1}}" type="checkbox">
                  </div>
                </div>
    
    
              </div>
              <div class="row">
                <div class="col">
                  <p><b>Description</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductDescription' + (x+1))?.value}}
                </div>
            
              </div>
            </div>
          </div>
          <div  *ngFor="let _ of [].constructor(15), let x = index">
            <div class="customCard" *ngIf="parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractName' + (x+1))?.value">
              <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 ">
                  <p><b>Service Contract</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractName' + (x+1))?.value}}
                  
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>MLFB</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractMLFB' + (x+1))?.value}}
                </div>
            
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>List Price</b></p>
                    {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractListPrice' + (x+1))?.value}}
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>Quantity</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractQuantity' + (x+1))?.value}}
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                  <p><b>Relevant for IADC</b></p>
                  
                  <div class="form-check">
                    <input id="cb-enabled" formControlName="iadcFirewallsServiceContractRelevantForIADC{{x+1}}" type="checkbox">
                  </div>
                </div>
    
    
              </div>
              <div class="row">
                <div class="col">
                  <p><b>Description</b></p>
                  {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductDescription' + (x+1))?.value}}
                </div>
            
              </div>
            </div>
          </div>
        </div>
        

          <div class="row">
            <div class="col">
              <h5 class="subSectionHeader">Calculative Parameters</h5>
            </div>
          </div>
      
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="Service Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsServiceCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">% / year</span>
                </div>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="Product Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsProductCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">% / year</span>
                </div>
              </si-newton-form-group>
            </div>
      
            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="Risk addition" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsRiskAddition" type="number" [min]="0" [max]="100" step="any" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
                </div>
              </si-newton-form-group>
            </div>
      
            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsMargin" type="number" [min]="0" [max]="100" step="any" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
                </div>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="Regional Discount" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsRegionalDiscount" type="number" [min]="0" [max]="100" step="any" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
                </div>
              </si-newton-form-group>
            </div>
      



          </div>

          <div class="row">
            <div class="col">
              <h5 class="subSectionHeader">Firewall Upgrade</h5>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Selected Upgrade years</b>
            </div>
          </div>

          <div class="row">

            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="1st Upgrade">
                <input formControlName="firstUpgradeOverall" readOnly siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="2nd Upgrade">
                <input formControlName="secondUpgradeOverall" readOnly siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
              <si-newton-form-group label="3rd Upgrade">
                <input formControlName="thirdUpgradeOverall" readOnly siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Services</b>
            </div>
          </div>

          <div *ngFor="let _ of [].constructor(1), let x = index">


            <input formControlName="iadcFirewallsHwSwUpgrServiceTask{{x+1}}Name" hidden #serviceTaskName>

            <div class="row">
              <div class="col">
                <b class="labelAccent">{{serviceTaskName.value}}</b>
              </div>
            </div>

            <div class="row">

              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
                  <input formControlName="iadcFirewallsHwSwUpgrServiceTask{{x+1}}Description" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="Effort onSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                  <input formControlName="iadcFirewallsHwSwUpgrServiceTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
                  <div class="input-group-prepend">
                    <span class="input-group-text">min</span>
                  </div>
                </si-newton-form-group>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="Effort offSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                  <input formControlName="iadcFirewallsHwSwUpgrServiceTask{{x+1}}EffortOffSite" type="number" [min]="0" siNewton>
                  <div class="input-group-prepend">
                    <span class="input-group-text">min</span>
                  </div>
                </si-newton-form-group>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="Total Service Cost" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                  <input formControlName="iadcFirewallsHwSwUpgrServiceTask{{x+1}}ServiceCost" type="number" [min]="0" highlight readOnly siNewton>
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                </si-newton-form-group>
              </div>
            </div>

          </div>
     
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Selected Products</b>
            </div>
          </div>
      

          <div *ngFor="let _ of [].constructor(15), let x = index">
            <div *ngIf="parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductName' + (x+1))?.value && parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductRelevantForIADC' + (x+1))?.value == 1">
      
              <div class="row">
                <div class="col">
                  <b class="labelAccent">{{parentForm.get('iadcFirewalls')?.get('iadcFirewallsProductName' + (x+1))?.value}}</b>
                </div>
              </div>
        
              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-3">
                  <si-newton-form-group label="MLFB / Description" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="iadcFirewallsProductMlfb{{x+1}}" readOnly siNewton>
                  </si-newton-form-group>
                </div>
        
                <div class="col-xs-12 col-sm-4 col-md-3">
                  <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="iadcFirewallsProductQuantity{{x+1}}" type="number" [min]="0" step="any" readOnly highlight siNewton>
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                  </si-newton-form-group>
                </div>
  
        
                <div class="col-xs-12 col-sm-4 col-md-3">
                  <si-newton-form-group label="List Price" [errorResolverMap]="{required: 'Value is required'}">
                    <input formControlName="iadcFirewallsProductListPrice{{x+1}}" type="number" [min]="0" step="any" readOnly highlight siNewton>
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                  </si-newton-form-group>
                </div>
              </div>
        
            </div>

          </div>
       
          <div class="row">
            <div class="col">
              <h5 class="subSectionHeader">Inspection and Maintenance</h5>
            </div>
          </div>
      
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <si-newton-form-group label="Times per year" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                <input formControlName="iadcFirewallsIuWTimesPerYear" type="number" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>
      
            
        <!--IuW Tasks-->
        <div *ngFor="let _ of [].constructor(2), let x = index">
          <div class="row">
            <input formControlName="iadcFirewallsIuWTask{{x+1}}Name" hidden #iuwTaskName>

            <div class="col">
              <b class="labelAccent">{{parentForm.get('iadcFirewalls')?.get('iadcFirewallsIuWTask' + (x+1) + 'Name')?.value}}</b>
            </div>
          </div>


          <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
              <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsIuWTask{{x+1}}Description" readOnly siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
              <si-newton-form-group label="Effort onSite" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                <input formControlName="iadcFirewallsIuWTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">min</span>
                </div>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
              <si-newton-form-group label="Material Cost" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsIuWTask{{x+1}}MaterialCost" type="number" [min]="0" step="any" siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
              <si-newton-form-group label="Service Cost" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsIuWTask{{x+1}}ServiceCost" type="number" [min]="0" readonly highlight siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
              </si-newton-form-group>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
              <si-newton-form-group label="Total Cost" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="iadcFirewallsIuWTask{{x+1}}TotalCost" type="number" [min]="0" readonly highlight siNewton>
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
              </si-newton-form-group>
            </div>
        </div>

        </div>
        <!--Selection of Service Years-->

        <div class="row">
          <div class="col">
            <p class="info">Please select the years, where IuW is needed</p>
          </div>
        </div>

        <div class="row" style="width: 95%; margin: auto; padding-bottom: 15px;">

          <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

          <div class="col">
            <div class="row year">{{stringToNumber(yearOfStartFix.value)}}</div>
            <div class="row checkBox">
              <div class="form-check">
                <input id="cb-enabled" formControlName="iadcFirewallsIuWSelectedYear1" type="checkbox">
              </div>
            </div>
          </div>


          <div class="col" *ngFor="let _ of [].constructor(14), let x = index">
            <div *ngIf="(stringToNumber(yearOfStartFix.value) + (x+1)) <= stringToNumber(lcsContractLastYear.value)">
              <div class="row year">{{stringToNumber(yearOfStartFix.value) + (x+1)}}</div>
              <div class="row checkBox">
                <div class="form-check">
                  <input id="cb-enabled" formControlName="iadcFirewallsIuWSelectedYear{{x+2}}" type="checkbox">
                </div>
              </div>
            </div>
          </div>

        </div>


            <!--Service Contracts-->

        <div class="row">
          <div class="col">
            <h5 class="subSectionHeader">Service Contracts</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-3">
            <si-newton-form-group label="Cost increase rate of service contracts" [errorResolverMap]="{required: 'Value is required'}">
              <input formControlName="iadcFirewallsServiceContractCostIncreaseRate" type="number" [min]="0" [max]="100" siNewton>
              <div class="input-group-prepend">
                <span class="input-group-text">%</span>
              </div>
            </si-newton-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Selected Service Contracts</b>
          </div>
        </div>

        <div *ngFor="let _ of [].constructor(15), let x = index">
          
          <div *ngIf="parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractName' + (x+1))?.value && parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractRelevantForIADC' + (x+1))?.value == 1">
            <div class="row">

              <input formControlName="iadcFirewallsServiceContractName{{x+1}}" hidden>
        
              <div class="col">
                <b class="labelAccent"><u>Service Contract {{x+1}}</u></b>
              </div>
            </div>
            
            <div class="row">
              <div class="col">
                {{parentForm.get('iadcFirewalls')?.get('iadcFirewallsServiceContractName' + (x+1))?.value}}
              </div>
            </div>
    
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                  <input formControlName="iadcFirewallsServiceContractMLFB{{x+1}}" readOnly siNewton>
                </si-newton-form-group>
              </div>
    
              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="Initial Year" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                  <input formControlName="iadcFirewallsServiceContractInitialYear{{x+1}}" type="number" [min]="0" #iadcFirewallsServiceContractInitialYear siNewton>
                </si-newton-form-group>
              </div>
            
              <div class="col-xs-12 col-sm-6 col-md-3">
                <si-newton-form-group label="Service Contract Costs" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                  <input formControlName="iadcFirewallsServiceContractListPrice{{x+1}}" type="number" readOnly highlight siNewton>
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                </si-newton-form-group>
              </div>
    
            </div>
        
            <div>
              <div class="row" >
                <div class="col">
                  <b class="labelAccent">Procurement / Renewal of service contract in year</b>
                </div>
              </div>
        
              <div class="row">
                <p class="info">You can deselect the calculated years if service contract is not needed in the specific year</p>
              </div>
        
              <div class="row" style="width: 50%; margin: auto; padding-bottom: 15px;">
        
        
                <div class="col"
                  *ngIf="stringToNumber(iadcFirewallsServiceContractInitialYear.value) < stringToNumber(yearOfStartFix.value)">
                  <div class="row year">{{stringToNumber(iadcFirewallsServiceContractInitialYear.value)}}</div>
                  <div class="row checkBox">
                    <div class="form-check">
                      <input id="cb-enabled" type="checkbox" [disabled]="true" [checked]="true">
                    </div>
                  </div>
                </div>
        
                <div class="col"
                  *ngIf="(stringToNumber(yearOfStartFix.value)-stringToNumber(iadcFirewallsServiceContractInitialYear.value))
                    %5===0 && stringToNumber(yearOfStartFix.value) >= stringToNumber(iadcFirewallsServiceContractInitialYear.value)">
                  <div class="row year">{{stringToNumber(yearOfStartFix.value)}}</div>
                  <div class="row checkBox">
                    <div class="form-check">
                      <input id="cb-enabled" formControlName="iadcFirewallsServiceContract{{x+1}}SelectedYear1" type="checkbox">
                    </div>
                  </div>
                </div>
 
                <ng-container *ngFor="let _ of [].constructor(14), let y = index">
                  <div class="col" *ngIf="(stringToNumber(yearOfStartFix.value)+(y+1)-stringToNumber(iadcFirewallsServiceContractInitialYear.value))
                  %5===0 && stringToNumber(yearOfStartFix.value) + (y+1) >=
                  stringToNumber(iadcFirewallsServiceContractInitialYear.value) && (stringToNumber(yearOfStartFix.value) + (y+1)) <= stringToNumber(lcsContractLastYear.value)">
                    <div class="row year">{{stringToNumber(yearOfStartFix.value) + (y+1)}}</div>
                    <div class="row checkBox">
                      <div class="form-check">
                        <input id="cb-enabled" formControlName="iadcFirewallsServiceContract{{x+1}}SelectedYear{{y+2}}" type="checkbox">
                      </div>
                    </div>
                  </div>
      
                </ng-container>
              </div>
            </div>
        
          </div>

        </div>


         <!--Cost Overview of IADC Component-->

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Cost Overview of Back Firewall</h5>
      </div>
    </div>


      <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

        <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

        <div class="col-xs-12 col-md-2">
            <div class="row">&nbsp;</div>
            <div class="row" style="background-color: #9bafbe;">Firewall Upgrade Services</div>
            <div class="row" style="background-color: #a5e1e1;">Firewall Upgrade Products</div>
            <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
            <div class="row" style="background-color: #a5e1e1;">Service Contracts</div>
            <div class="row" style="background-color: #d2d741;">Total Cost</div>
            <div class="row" style="background-color: #faa50a;">Included Risk</div>
        </div>

        <ng-container *ngFor="let _ of [].constructor(5), let x = index">
          <input formControlName="iadcFirewallsCostOverviewHwSwUpgServicesYear{{x+1}}" hidden #upgrServiceCost>
          <input formControlName="iadcFirewallsCostOverviewHwSwUpgProductsYear{{x+1}}" hidden #upgrProductCost>
          <input formControlName="iadcFirewallsCostOverviewIuWYear{{x+1}}" hidden #iuwCost>
          <input formControlName="iadcFirewallsCostOverviewServiceContractsYear{{x+1}}" hidden #serviceContractCost>
          <input formControlName="iadcFirewallsCostOverviewTotalYear{{x+1}}" hidden #totalCost>
          <input formControlName="iadcFirewallsCostOverviewIncludedRiskYear{{x+1}}" hidden #includedRiskCost>

          <div class="col-xs-12 col-md-2">
            <div class="row year">{{stringToNumber(yearOfStartFix.value) + x}}</div>
            <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
            <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
            <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
            <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{serviceContractCost.value}} €</div>
            <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
            <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
          </div>

        </ng-container>

      </div>
      <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

        <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

        <div class="col-xs-12 col-md-2">
            <div class="row">&nbsp;</div>
            <div class="row" style="background-color: #9bafbe;">Firewall Upgrade Services</div>
            <div class="row" style="background-color: #a5e1e1;">Firewall Upgrade Products</div>
            <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
            <div class="row" style="background-color: #a5e1e1;">Service Contracts</div>
            <div class="row" style="background-color: #d2d741;">Total Cost</div>
            <div class="row" style="background-color: #faa50a;">Included Risk</div>
        </div>

        <ng-container *ngFor="let _ of [].constructor(5), let x = index">
          <input formControlName="iadcFirewallsCostOverviewHwSwUpgServicesYear{{x+6}}" hidden #upgrServiceCost>
          <input formControlName="iadcFirewallsCostOverviewHwSwUpgProductsYear{{x+6}}" hidden #upgrProductCost>
          <input formControlName="iadcFirewallsCostOverviewIuWYear{{x+6}}" hidden #iuwCost>
          <input formControlName="iadcFirewallsCostOverviewServiceContractsYear{{x+6}}" hidden #serviceContractCost>
          <input formControlName="iadcFirewallsCostOverviewTotalYear{{x+6}}" hidden #totalCost>
          <input formControlName="iadcFirewallsCostOverviewIncludedRiskYear{{x+6}}" hidden #includedRiskCost>

          <div class="col-xs-12 col-md-2">
            <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+5}}</div>
            <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
            <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
            <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
            <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{serviceContractCost.value}} €</div>
            <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
            <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
          </div>

        </ng-container>

      </div>
      <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

        <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

        <div class="col-xs-12 col-md-2">
            <div class="row">&nbsp;</div>
            <div class="row" style="background-color: #9bafbe;">Firewall Upgrade Services</div>
            <div class="row" style="background-color: #a5e1e1;">Firewall Upgrade Products</div>
            <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
            <div class="row" style="background-color: #a5e1e1;">Service Contracts</div>
            <div class="row" style="background-color: #d2d741;">Total Cost</div>
            <div class="row" style="background-color: #faa50a;">Included Risk</div>
        </div>

        <ng-container *ngFor="let _ of [].constructor(5), let x = index">
          <input formControlName="iadcFirewallsCostOverviewHwSwUpgServicesYear{{x+11}}" hidden #upgrServiceCost>
          <input formControlName="iadcFirewallsCostOverviewHwSwUpgProductsYear{{x+11}}" hidden #upgrProductCost>
          <input formControlName="iadcFirewallsCostOverviewIuWYear{{x+11}}" hidden #iuwCost>
          <input formControlName="iadcFirewallsCostOverviewServiceContractsYear{{x+11}}" hidden #serviceContractCost>
          <input formControlName="iadcFirewallsCostOverviewTotalYear{{x+11}}" hidden #totalCost>
          <input formControlName="iadcFirewallsCostOverviewIncludedRiskYear{{x+11}}" hidden #includedRiskCost>

          <div class="col-xs-12 col-md-2">
            <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+10}}</div>
            <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
            <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
            <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
            <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{serviceContractCost.value}} €</div>
            <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
            <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
          </div>

        </ng-container>

      </div>

    </div>
</form>