
<div class="row" style="margin-bottom: 10px; margin-top: -40px;">
  <div class="col-md-12 d-flex justify-content-end">
    <button 
      type="button" 
      class="btn btn-link p-0 text-decoration-none question-mark-btn" 
      data-bs-toggle="modal" 
      data-bs-target="#helpModal" 
      aria-label="Help"
      (click)="openHelpModal.emit('sw-maintenance-pcs-neo')" 
    >
      <i class="bi bi-question-circle help-text-button"></i>
    </button>
  </div>
</div>

<form [formGroup]="parentForm">
    <div formGroupName="neoSwProcMaintForm">
  
        <div id="pcsNeoSwProcurementAndMaintenance">
            <div class="row">
              <div class="col">
                <b class="subSubSectionHeader">PCS neo SW-Procurement & SW-Maintenance</b>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b><u>PCS neo License Procurement for LCS w/o PO</u></b>
              </div>
            </div>
        
        
            <div class="row">
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Procurement Cost (LCS w/o PO)" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="pcsNeoLicenseProcurementwithoutPO" type="number" step="any" [min]="0" siNewton>
                </si-newton-form-group>
              </div>
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Cost Increase Rate" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input formControlName="pcsNeoLicenseProcurementwithoutPOCostIncreaseRate" type="number" step="any" [min]="0" [max]="100" siNewton>
                </si-newton-form-group>
              </div>
            </div>
        
            <div class="row">
              <div class="col">
                <b><u>PCS neo SMP Infinity</u></b>
              </div>
            </div>
        
            <div class="row">
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Procurement Cost inc PO" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="pcsNeoLicenseProcurementIncPO" type="number" step="any" [min]="0" siNewton>
                </si-newton-form-group>
              </div>
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Share of Total" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input formControlName="pcsNeoLicenseProcurementIncPOShareOfTotal" type="number" step="any" [min]="0" [max]="100" siNewton>
                </si-newton-form-group>
              </div>
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Cost Increase Rate" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">%</span>
                  </div>
                  <input formControlName="pcsNeoiLicenseProcurementCostIncreaseRate"  type="number" step="any" [min]="0" [max]="100" siNewton>
                </si-newton-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="PCS neo SW Infinity Cost" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="pcsNeoSWPInfinity" readonly siNewton>
                </si-newton-form-group>
              </div>
            </div>
        
          </div>
    </div>
</form>
  