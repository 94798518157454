import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}
@Component({
  selector: 'app-ivm',
  templateUrl: './ivm.component.html',
  styleUrls: ['./ivm.component.scss']
})
export class IvmComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  public parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();



  counter(i: number){
    return new Array(i);
  }

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  constructor(private formDataService: FormDataService, private dataTransferService: DataTransferService) { }


  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  async submit(){
    const jsonData = await this.formDataService.getDataFromDialog(this.parentForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.parentForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.parentForm, formData);
  }

}
