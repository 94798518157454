<form [formGroup]="parentForm">
  <div formGroupName="correctiveMaintenance">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Corrective Mainteannce</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('corrective-maintenance')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Time & Material</b>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <div class="form-check">
            <input class="form-check form-check-inline" formControlName="correctiveMainServiceTimesRb"
            type="radio" [(ngModel)]="correctiveMainServiceTimesRb" value="TuM - 08:00 am - 05:00 pm (Mo.-Fr.)">
            <label class="form-check-label">
              TuM - 08:00 am - 05:00 pm (Mo.-Fr.)
            </label>
          </div>
          <div class="form-check">
            <input class="form-check form-check-inline" formControlName="correctiveMainServiceTimesRb"
            type="radio" [(ngModel)]="correctiveMainServiceTimesRb" value="TuM - 00:00 am - 12:00 pm (Mo.-Sa.)">
            <label class="form-check-label">
              TuM - 00:00 am - 12:00 pm (Mo.-Sa.)
            </label>
          </div>
          <div class="form-check">
            <input class="form-check form-check-inline" formControlName="correctiveMainServiceTimesRb"
            type="radio" [(ngModel)]="correctiveMainServiceTimesRb" value="TuM - 00:00 am - 12:00 pm (Mo.-So.)">
            <label class="form-check-label">
              TuM - 00:00 am - 12:00 pm (Mo.-So.)
            </label>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="checkBoxalternativeLumpSum" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              Use lump sum value instead of T&M
            </label>
          </div>
        </div>
        <div class="col">
          <si-newton-form-group label="Alternative value" [errorResolverMap]="{required: 'Value is required'}"
          [hidden]="+parentForm.get('correctiveMaintenance')!.get('checkBoxalternativeLumpSum')!.value !== 1">
            <div class="input-group-prepend">
              <span class="input-group-text">€ / h</span>
            </div>
            <input formControlName="alternativeValuePerYear" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col">
          <si-newton-form-group label="Current Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="curCostServiceLevel" highlight readonly type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>

      <div *ngIf="(getUserRole() | async)=== 'admin'">
        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Admin Setting</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="cbIsRepairTimeSelectable" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                Repair Time requested for this calculation?
              </label>
            </div>
          </div>
          <div class="col">
            <p class="info">Please reset the values and submit before you disable this Setting</p>
          </div>
        </div>
      </div>

      <div *ngIf="+(parentForm.get('correctiveMaintenance')?.get('cbIsRepairTimeSelectable')?.value) === 1">
        <div class="row">
          <div class="col">
            <b class="subSubSectionHeader">Repair Time</b>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-check">
              <input id="cb-enabled" class="form-check-input" formControlName="checkBoxRepairTime" type="checkbox">
              <label for="cb-enabled" class="form-check-label">
                Repair Time
              </label>
            </div>
          </div>
          <div class="col-md-9">
            <p class="info">For replaceable HW Components. Only in conjuction with Service Time: 00:00 am - 12:00 pm
              (every day including Sunday and public holiday)
            </p>
          </div>
        </div>
      </div>


    </div>


  </div>
</form>
