<form [formGroup]="parentForm">
  <div formGroupName="costIncreaseRates">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Cost increase rates per year</h5>
        <button 
          type="button" 
          class="btn btn-link p-0 text-decoration-none" 
          (click)="openHelpDialog()" 
          data-bs-toggle="modal" 
          data-bs-target="#helpModal" 
          aria-label="Help"
        >
          <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
        </button>
      </div>
    </div>
    
    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">PCS 7 Components</b>
      </div>
    </div>

    <!--PCS 7 Components-->
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS 7 SUS" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7SUSCostIncrease" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS7 - IPC / 3rd party PCs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7ThirdParty" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS 7 LSS" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7LssCostIncreaseRate" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS 7 SW Maintenance Contract" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7SwMainCostIncreaseRate" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">

      <div class="col">
        <b class="subSubSectionHeader">HW- / SW Addons</b>
      </div>

    </div>

    <!--HW- / SW Addons-->
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SW AddOns" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="swAddonsCostIncrease" type="number" step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="HW AddOns" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="hwAddonsCostIncrease" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

    </div>


    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Network Components</b>
      </div>
    </div>

    <!--Network Components-->
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Network Component Scalances" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="networkCompScal" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">IT Infrastructure</b>
      </div>

    </div>

    <!--IT Infrastructure-->
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="IT Infrastructure" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="itInfraStructurIncreasePerYear" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">License-Procurement & SW Maintenance</b>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS neo SW Cost Increase Rate" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcsNeoSwCostIncreaseRate" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>
    </div>




    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">SIVaaS - Virtual Systems</b>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SIVaaS VMs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="sivaasVMs" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="HP Hosts / Thin Clients" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="hpHostThinClients" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Service Contracts (HP/VmWare...)" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="serviceContractsIncreaseCost" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="vCenter Server" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="vCenterServer" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>


    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Service & Other Costs</b>
      </div>
    </div>



    <div class="row">

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Service Costs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="serviceCost" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Other Costs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="otherCosts" type="number"  step="any" [min]="0" [max]="100" siNewton>
        </si-newton-form-group>
      </div>
    </div>

  </div>
</form>
