import { Component, ViewChild } from '@angular/core';
import { CustomToastService } from '../services/custom-toast.service';
import { ProjectManagementService } from '../services/project-management.service';
import { Project } from 'src/app/data-models/Project';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeService } from '../services/time.service';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { ProjectDeletionModalComponent } from '../project-management/project-deletion-modal/project-deletion-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShowDisclaimerModalComponent } from '../project-management/show-disclaimer-modal/show-disclaimer-modal.component';
import { UploadProjectModalComponent } from '../project-management/upload-project-modal/upload-project-modal.component';
import { UserManagementService } from '../services/user-management.service';
import { ProjectAssignmentComponent } from '../project-assignment/project-assignment.component';
import { ProjectUnassignmentComponent } from '../project-unassignment/project-unassignment.component';
import { RejectProjectAssignmentComponent } from '../reject-project-assignment/reject-project-assignment.component';
import { AdminService } from '../services/admin.service';
import { OpenHelpDialogModalComponent } from '../modals/open-help-dialog-modal/open-help-dialog-modal.component';


@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent {
  @ViewChild('tabset') tabSet: any;

  projects: Project[] = [];
  projectCreationForm: FormGroup;
  modalRef!: BsModalRef;

  dateSort(project1: Project, project2: Project): number {

    const year1 = parseFloat(project1.created_time.toString().split(', ')[0].split('.')[2]);
    const monthIndex1 = parseFloat(project1.created_time.toString().split(', ')[0].split('.')[1]) - 1;
    const day1 = parseFloat(project1.created_time.toString().split(', ')[0].split('.')[0]);

    const hours1 = parseFloat(project1.created_time.toString().split(', ')[1].split(':')[0]);
    const minutes1 = parseFloat(project1.created_time.toString().split(', ')[1].split(':')[1]);
    const seconds1 = parseFloat(project1.created_time.toString().split(', ')[1].split(':')[2]);

    const year2 = parseFloat(project2.created_time.toString().split(', ')[0].split('.')[2]);
    const monthIndex2 = parseFloat(project2.created_time.toString().split(', ')[0].split('.')[1]) - 1;
    const day2 = parseFloat(project2.created_time.toString().split(', ')[0].split('.')[0]);

    const hours2 = parseFloat(project2.created_time.toString().split(', ')[1].split(':')[0]);
    const minutes2 = parseFloat(project2.created_time.toString().split(', ')[1].split(':')[1]);
    const seconds2 = parseFloat(project2.created_time.toString().split(', ')[1].split(':')[2]);

    const date1 = new Date(year1, monthIndex1, day1, hours1, minutes1, seconds1);

    const date2 = new Date(year2, monthIndex2, day2, hours2, minutes2, seconds2);

    return (date1.valueOf() - date2.valueOf());
  }

  lastEditSort(project1: Project, project2: Project): number {

    let project1NoLastEdit = false;
    let project2NoLastEdit = false;


    if (!project1.lastedit_time) {
      project1.lastedit_time = project1.created_time;
      project1NoLastEdit = true;
    }

    if (!project2.lastedit_time) {
      project2.lastedit_time = project2.created_time;
      project2NoLastEdit = true;
    }

    const year1 = parseFloat(project1.lastedit_time?.toString().split(', ')[0].split('.')[2]);
    const monthIndex1 = parseFloat(project1.lastedit_time?.toString().split(', ')[0].split('.')[1]) - 1;
    const day1 = parseFloat(project1.lastedit_time?.toString().split(', ')[0].split('.')[0]);

    const hours1 = parseFloat(project1.lastedit_time?.toString().split(', ')[1].split(':')[0]);
    const minutes1 = parseFloat(project1.lastedit_time?.toString().split(', ')[1].split(':')[1]);
    const seconds1 = parseFloat(project1.lastedit_time?.toString().split(', ')[1].split(':')[2]);

    const year2 = parseFloat(project2.lastedit_time?.toString().split(', ')[0].split('.')[2]);
    const monthIndex2 = parseFloat(project2.lastedit_time?.toString().split(', ')[0].split('.')[1]) - 1;
    const day2 = parseFloat(project2.lastedit_time?.toString().split(', ')[0].split('.')[0]);

    const hours2 = parseFloat(project2.lastedit_time?.toString().split(', ')[1].split(':')[0]);
    const minutes2 = parseFloat(project2.lastedit_time?.toString().split(', ')[1].split(':')[1]);
    const seconds2 = parseFloat(project2.lastedit_time?.toString().split(', ')[1].split(':')[2]);

    const date1 = new Date(year1, monthIndex1, day1, hours1, minutes1, seconds1);

    const date2 = new Date(year2, monthIndex2, day2, hours2, minutes2, seconds2);

    if (project1NoLastEdit) {
      project1.lastedit_time = null;
    }

    if (project2NoLastEdit) {
      project2.lastedit_time = null;
    }

    return (date1.valueOf() - date2.valueOf());
  }

  constructor(public projectManagementService: ProjectManagementService, private toastService: CustomToastService,
    private formBuilder: FormBuilder, private timeService: TimeService, private loadingSpinner: SiNewtonLoadingService,
    private modalService: BsModalService, private userManagementService: UserManagementService, private adminService: AdminService) {

    this.projectCreationForm = this.formBuilder.group(
      {
        projectName: [null, Validators.required],
        customerName: [null, Validators.required]
      }
    );

    this.projectManagementService.getProjects().then(projects => {
      this.projects = projects;
    });
  }

  getUserRole(){
    return this.userManagementService.getUserRole();
  }

  async refreshProjectList(){
    this.projects = await (this.projectManagementService.getProjects() as any);
  }

  async openProject(projectName: string, projectId: string, acceptedDisclaimer: boolean, customer: string, version: number | string){

    if (!acceptedDisclaimer) {
      this.modalRef = this.modalService.show(ShowDisclaimerModalComponent, {});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.content.confirm = false;

      this.modalRef.onHidden.subscribe(async (result: any) => {

        if (this.modalRef.content.confirm) {
          await this.projectManagementService.updateAcceptStatusOfDisclaimer(projectId);
        }
      });

    }

    await this.projectManagementService.openProject(projectName, projectId, customer, version, true);
  }

  async createProject(){

    const newProjectName = this.projectCreationForm.get('projectName')?.value;
    const newProjectCustomer = this.projectCreationForm.get('customerName')?.value;

    await this.projectManagementService.createProject(newProjectName, newProjectCustomer);

    await this.refreshProjectList();

  }

  async copyProject(originalProjectId: string, projectName: string, customerName: string){

    this.loadingSpinner.startLoading();

    try {

      const projectNameOfCopy = projectName + '-Copy_' + this.timeService.getCurrentTime();
      await this.projectManagementService.copyCalculationFile(originalProjectId, projectNameOfCopy, customerName);

      this.loadingSpinner.stopLoading();

      this.refreshProjectList();
    } catch (err){

      this.toastService.showErrorToast((err as any).message);
      this.loadingSpinner.stopLoading();
    }
  }

  async closeProject(projectId: string){
    this.loadingSpinner.startLoading();

    await this.projectManagementService.closeCalculationFile(projectId, true);
    await this.refreshProjectList();

    this.loadingSpinner.stopLoading();
  }

  async downloadProject(projectId: string, projectName: string){

    this.loadingSpinner.startLoading();
    await this.adminService.downloadCalculationFile(projectId, projectName);
    this.loadingSpinner.stopLoading();
  }

  async deleteProject(projectId: string, projectName: string){

    const initialState = { projectName };
    this.modalRef = this.modalService.show(ProjectDeletionModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.confirm = false;

    this.modalRef.onHidden.subscribe(async (result: any) => {

      this.loadingSpinner.startLoading();

      if (this.modalRef.content.confirm) {
        await this.projectManagementService.closeCalculationFile(projectId, false);
        await this.projectManagementService.deleteProjectOrRelation(projectId);
        await this.refreshProjectList();
      }

      this.loadingSpinner.stopLoading();
    });
  }

  async uploadProject(projectId: string){

    const initialState = { projectId };
    this.modalRef = this.modalService.show(UploadProjectModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }

  async restoreProject(projectId: string){
    this.loadingSpinner.startLoading();
    await this.projectManagementService.restoreProject(projectId);
    this.refreshProjectList();

    this.loadingSpinner.stopLoading();
  }

  async assignProject(projectId: string, projectName: string){

    const initialState = { projectId, projectName };
    this.modalRef = this.modalService.show(ProjectAssignmentComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(() => {
      this.refreshProjectList();
    });
  }

  async unassignProject(projectId: string, projectName: string, assignmentPartner: string){
    const initialState = { projectId, projectName, assignmentPartner };
    this.modalRef = this.modalService.show(ProjectUnassignmentComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(() => {
      this.refreshProjectList();
    });
  }

  async rejectAssignment(projectId: string, projectName: string){
    const initialState = { projectId, projectName };
    this.modalRef = this.modalService.show(RejectProjectAssignmentComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(() => {
      this.refreshProjectList();
    });
  }

  async updateProject(projectName: string, projectId: string, acceptedDisclaimer: boolean, customerName: string, version: number | string){

    await this.projectManagementService.closeCalculationFileForAllUsers(projectId, true);
    await this.projectManagementService.updateCalculationFile(projectName, projectId, acceptedDisclaimer, customerName, version);
  }


  openHelpModal(sectionName: string) {
    this.modalService.show(OpenHelpDialogModalComponent, { initialState: { sectionName } });
  }


}
