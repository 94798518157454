<form [formGroup]="parentForm">
  <div formGroupName="upgradePackages">
    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Upgrade packages</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('sw-maintenance-upgrade-packages')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
          <b class="labelAccent">Current Year</b>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="L Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="upgradeLpriceTotalcurrentYear" type="number" [min]="0" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="G / GA Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="gGaPriceTotalCurrentYear" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>


        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
          <b class="labelAccent">Year of LCS Start</b>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="L Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="lPriceTotalNextYear" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="G / GA Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="gGaPriceTotalNextYear" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>
