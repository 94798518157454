<div class="modal-header">
  <h4 class="modal-title pull-left">Maintenance Parameters</h4>

</div>
<div #modalBody class="modal-body">

    <tabset>
      <tab heading="Standard Tasks PCS 7 / PCS neo">
        <div class="row" style="margin-top: -40px; margin-bottom: 5px;">
          <div class="col-md-12 d-flex justify-content-end">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none question-mark-btn" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
              (click)="openHelpModal('maintenance-parameters-standard')" 
            >
              <i class="bi bi-question-circle help-text-button"></i>
            </button>
          </div>
        </div>

        
        <form [formGroup]="maintenanceParametersFormMain" class="col-md-12" style="align-items: center;">

          <si-newton-table [rows]="allMaintenanceParameters"
          [loading]="allMaintenanceParameters.length < numberOfMaintenanceParameters" [rowsPerPage]="10">

          <siNewtonTableColumn key="nr" [widthFactor]="0.3" [disableFilter]="true" [disableSort]="true">
          </siNewtonTableColumn>

          <siNewtonTableColumn key="component" name="Component">
          </siNewtonTableColumn>

          <siNewtonTableColumn key="subComponent" name="Sub-Component">
            <ng-template siNewtonTableCell let-row="row">
              {{row.subComponent}}
              <label title="{{row.tooltip}}">
                <i class="bi bi-question-circle-fill" style="color: #eb780a" matTooltip="Info about the action"></i>
              </label>

              <si-newton-form-group *ngIf="row.idForExchangeMaterialCost === 'materialExchangeCost1' || row.idForExchangeMaterialCost === 'materialExchangeCost2'
                || row.idForExchangeMaterialCost === 'materialExchangeCost3'"
                label="Material Cost" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">€ / component</span>
                </div>
                <input formControlName="{{row.idForExchangeMaterialCost}}" type="number" [min]="0" siNewton>
              </si-newton-form-group>

            </ng-template>
          </siNewtonTableColumn>

          <siNewtonTableColumn key="hq" name="PCS 7 Effort">
            <ng-template siNewtonTableCell let-row="row">
              <si-newton-form-group label="Effort estimation" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">[min]</span>
                </div>
                <input formControlName="{{row.idForPCS7Effort}}" type="number" [min]="0" siNewton>
              </si-newton-form-group>
            </ng-template>
          </siNewtonTableColumn>

          <siNewtonTableColumn name="PCS neo Effort" [disableFilter]="true" [disableSort]="true">
            <ng-template siNewtonTableCell let-row="row">
              <si-newton-form-group label="Effort estimation" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">[min]</span>
                </div>
                <input formControlName="{{row.idForPCSNeoEffort}}" type="number" [min]="0" siNewton>
              </si-newton-form-group>
            </ng-template>
            </siNewtonTableColumn>

        </si-newton-table>
        </form>

      </tab>

      <tab heading="Individual Tasks">
        <div class="row" style="margin-top: -40px; margin-bottom: 5px;">
          <div class="col-md-12 d-flex justify-content-end">
            <button 
              type="button" 
              class="btn btn-link p-0 text-decoration-none question-mark-btn" 
              data-bs-toggle="modal" 
              data-bs-target="#helpModal" 
              aria-label="Help"
              (click)="openHelpModal('maintenance-parameters-individual')" 
            >
              <i class="bi bi-question-circle help-text-button"></i>
            </button>
          </div>
        </div>

        <form [formGroup]="maintenanceParametersFormIndividual" class="col-md-12" style="align-items: center;">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Add new Individual Task</b>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
    
              <si-newton-form-group label="Component Type(s)">
                <input formControlName="newIndividualIuWTaskComponent" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <si-newton-form-group label="Task">
                <input formControlName="newIndividualIuWTask" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <si-newton-form-group label="Comment">
                <input formControlName="newIndividualIuWTaskComment" siNewton>
              </si-newton-form-group>
            </div>

            
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
    
              <si-newton-form-group label="Quant. Components">
                <input formControlName="newIndidivualTaskQuantity" siNewton type="number" [min]="0">
              </si-newton-form-group>
            </div>


            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <si-newton-form-group label="Effort" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                <input formControlName="newIndividualIuWTaskEffort" siNewton type="number" [min]="0">
                <div class="input-group-prepend">
                  <span class="input-group-text">/ min</span>
                </div>
              </si-newton-form-group>
            </div>


            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <si-newton-form-group label="Material Cost" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
                <input formControlName="newIndividualIuWTaskMaterialCost" siNewton type="number" [min]="0">
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <si-newton-form-group label="Times Per Year">
                <input formControlName="newIndividualIuWTaskTimesPerYear" siNewton type="number" [min]="0">
                <div class="input-group-prepend">
                  <span class="input-group-text">/ min</span>
                </div>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col" >
              <button type="button" class="btn btn-success" style="float: right; margin: 10px;"(click)="updateIndividualMaintenanceTasks()"
              [disabled]="!isNewTaskValid()">Add new Task</button>
            </div>
          </div>

        </form>

          <div *ngIf="allIndividualMaintenanceParameters.length === 0">
            <b>No individual IuW Tasks created yet</b>
          </div>

          <si-newton-table [rows]="allIndividualMaintenanceParameters"
          [rowsPerPage]="10" [loading]="allIndividualMaintenanceParameters.length === 0">
            <siNewtonTableColumn key="component" name="Component" [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="task" name="Task" [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="comment" name="Comment" [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="quantity" name="Qty." [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="effort" name="Effort [min]" [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="materialCost" name="Material Cost" [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="timesPerYear" name="Times per Year" [widthFactor]="0.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn [widthFactor]="0.3" [disableFilter]="true" [disableSort]="true">
              <ng-template siNewtonTableCell let-value='value' let-row="row">
                <button title="Delete Task" class="btn-danger" (click)="deleteTask(row)">
                  <i class="bi bi-trash"></i>
                </button>
              </ng-template>
            </siNewtonTableColumn>

          </si-newton-table>
      </tab>
    </tabset>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-secondary" (click)="update()" [disabled]="!maintenanceParametersForm.valid">Update</button>
  <button type="button" class="btn btn-success" [disabled]="!(maintenanceParametersForm.valid)"
  (click)="submitAndClose()">Submit & Close</button>

</div>
