import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-on-call-service-control-center',
  templateUrl: './on-call-service-control-center.component.html',
  styleUrls: ['./on-call-service-control-center.component.scss']
})
export class OnCallServiceControlCenterComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  formName = 'onCallServiceControlCenter';

  @Input()
  parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();



  constructor() { }


}
