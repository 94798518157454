import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-general-project-parameter',
  templateUrl: './general-project-parameter.component.html',
  styleUrls: ['./general-project-parameter.component.scss']
})
export class GeneralProjectParameterComponent {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  constructor(private formDataService: FormDataService) {}

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  openHelpDialog() {
    this.openHelpModal.emit('general-project-parameter');
  }

}
