import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-neo-sw-proc-maint',
  templateUrl: './neo-sw-proc-maint.component.html',
  styleUrls: ['./neo-sw-proc-maint.component.scss']
})
export class NeoSwProcMaintComponent implements OnInit {
  @Input()
  public parentForm!: FormGroup;

  
  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

}
