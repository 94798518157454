import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-regional-discounts',
  templateUrl: './regional-discounts.component.html',
  styleUrls: ['./regional-discounts.component.scss']
})
export class RegionalDiscountsComponent {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  constructor() { }

  
  openHelpDialog() {
    this.openHelpModal.emit('regional-discounts');
  }
}
