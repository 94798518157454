<form [formGroup]="parentForm">
  <div formGroupName="exchangeFactors">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Target Currency - Exchange Factors</h5>
        <button 
          type="button" 
          class="btn btn-link p-0 text-decoration-none" 
          (click)="openHelpDialog()" 
          data-bs-toggle="modal" 
          data-bs-target="#helpModal" 
          aria-label="Help"
        >
          <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
        </button>
      </div>
    </div>
    <div class="row" style="margin-bottom: 5px;">
      <div class="col">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="cBCurrencyConversion" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            Currency conversion required
          </label>
        </div>
      </div>

    </div>

    <div class="row"
      *ngIf="+parentForm.get('exchangeFactors')!.get('cBCurrencyConversion')!.value === 1" >
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Target currency" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">1</span>
          </div>
          <input formControlName="targetCurrency" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Exchange rate" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
          <input formControlName="currencyExchangeFactor" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

  </div>
</form>
