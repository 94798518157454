<form [formGroup]="parentForm">
  <div formGroupName="costOfOneTimeServices">

    <div class="subSectionHeaderWrapper">
      <div class="d-flex justify-content-between align-items-center subSectionHeader">
        <h5 class="m-0">Costs of one time services</h5>
        
        <div class="ms-auto d-flex align-items-center">
          <button 
            type="button" 
            class="btn btn-link p-0 text-decoration-none" 
            (click)="openHelpModal.emit('cost-of-one-time-services')" 
            data-bs-toggle="modal" 
            data-bs-target="#helpModal" 
            aria-label="Help"
          >
            <i class="bi bi-question-circle" style="font-size: 1.5rem; color: white"></i>
          </button>
          
          <i 
            style="cursor: pointer; margin-left: 0.5rem;" 
            [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
            (click)="toggleVisibility()"
          ></i>
        </div>
      </div>
    </div>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Set-up and Init. Mobilization" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="setUpInitMobilization" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Inventory Baseline Service" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="inventoryBaselineService" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Life Cycle Information Service" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="lifeCycleInformationService" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Asset Optimization Service Analysis" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="assetOptServiceAnalysis" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="System Audit LCS" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="systemAuditLCS" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>
