<div class="modal-header">
    <h4 class="modal-title pull-left">Help Dialog</h4>
</div>
<div #modalBody class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div *ngIf="!isEditing; else editMode" class="help-text-view">
                <pre>{{ helpText }}</pre>
            </div>
            <ng-template #editMode>
                <textarea
                    [(ngModel)]="editedText"
                    class="form-control"
                    rows="5"
                    style="resize: vertical;"
                ></textarea>
            </ng-template>
        </div>
    </div>
</div>
<div class="modal-footer">
    <!-- Buttons -->
    <button *ngIf="!isEditing" type="button" class="btn btn-warning" (click)="modalRef.hide()">Close</button>
    <button *ngIf="!isEditing" type="button" class="btn" style="background-color: #005f87; color: white" (click)="enableEdit()">Edit Text</button>
    <button *ngIf="isEditing" type="button" class="btn btn-success" (click)="saveEdit()">Save</button>
    <button *ngIf="isEditing" type="button" class="btn btn-warning" (click)="cancelEdit()">Cancel</button>
</div>


