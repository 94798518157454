import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pcs-software-update-service',
  templateUrl: './pcs-software-update-service.component.html',
  styleUrls: ['./pcs-software-update-service.component.scss']
})
export class PcsSoftwareUpdateServiceComponent {

  @Input()
  public parentForm!: FormGroup;

  @Input()
  selectedSusType!: string;

  @Output()
  openHelpModal: EventEmitter<any> = new EventEmitter();


  numberOfSusPackages = 0;
  isThisSectionVisible = false;


  toggleVisibility(){
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  constructor() {
    if (parseInt(sessionStorage.getItem('calculationVersion')!.toString(), 10) >= 2){
      this.numberOfSusPackages = 17;
    } else {
      this.numberOfSusPackages = 14;
    }
  }

}
